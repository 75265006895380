import { gql } from '@apollo/client/core';

export const addCategoryMutation = gql`
  mutation addCategoryMutation($object: calender_category_insert_input!) {
    insert_calender_category_one(object: $object) {
      updatedAt
      title
      id
      createdAt
      companyId
    }
  }
`;

export const addPhaseMutation = gql`
  mutation addPhaseMutation($object: phase_insert_input!) {
    insert_phase_one(object: $object) {
      companyId
      createdAt
      description
      from
      id
      title
      to
      updatedAt
      calender_category {
        title
      }
    }
  }
`;

export const updatePhasesMutation = gql`
  mutation updatePhaseMutation($id: Int!, $set: phase_set_input) {
    update_phase_by_pk(pk_columns: { id: $id }, _set: $set) {
      categoryId
      description
      from
      id
      title
      to
    }
  }
`;

export const addScenarioMutation = gql`
  mutation addScenarioMutation($object: scenario_insert_input!) {
    insert_scenario_one(object: $object) {
      color
      companyId
      id
      title
      isDeleted
      updatedAt
      createdAt
    }
  }
`;

export const updateScenarioMutation = gql`
  mutation updateScenarioMutation($id: Int!, $set: scenario_set_input) {
    update_scenario_by_pk(pk_columns: { id: $id }, _set: $set) {
      color
      companyId
      id
      title
      isDeleted
      updatedAt
      createdAt
    }
  }
`;

export const addMilestoneMutation = gql`
  mutation addMilestoneMutation($object: milestone_insert_input!) {
    insert_milestone_one(object: $object) {
      categoryId
      companyId
      createdAt
      date
      description
      id
      isDeleted
      scenarioId
      status
      title
      updatedAt
      calender_category {
        title
      }
      scenario {
        color
        title
      }
      metricType
      metricName
      showOnMarketPlan
      milestoneValue
      relatedGoalId
      color
      dateAchieved
      related_goal {
        categoryId
        companyId
        createdAt
        date
        dateAchieved
        description
        id
        metricType
        milestoneValue
        status
        title
        type
      }
    }
  }
`;

export const updateMilestoneMutation = gql`
  mutation updateMilestoneMutation($id: Int!, $set: milestone_set_input) {
    update_milestone_by_pk(pk_columns: { id: $id }, _set: $set) {
      categoryId
      companyId
      createdAt
      date
      description
      id
      isDeleted
      scenarioId
      status
      title
      updatedAt
      calender_category {
        title
      }
      scenario {
        color
        title
      }
      metricType
      metricName
      showOnMarketPlan
      milestoneValue
      relatedGoalId
      color
      dateAchieved
      related_goal {
        categoryId
        companyId
        createdAt
        date
        dateAchieved
        description
        isDeleted
        id
        metricType
        milestoneValue
        status
        title
        type
      }
    }
  }
`;

export const updateMultipleMilestoneMutation = gql`
  mutation updateMilestoneMutation($ids: [Int!], $set: milestone_set_input) {
    update_milestone(where: { id: { _in: $ids } }, _set: $set) {
      returning {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
        related_goal {
          categoryId
          companyId
          createdAt
          date
          dateAchieved
          description
          isDeleted
          id
          metricType
          milestoneValue
          status
          title
          type
        }
      }
    }
  }
`;
