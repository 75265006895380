import { render, staticRenderFns } from "./AssignMembers.vue?vue&type=template&id=0f67efd1&scoped=true"
import script from "./AssignMembers.vue?vue&type=script&lang=js"
export * from "./AssignMembers.vue?vue&type=script&lang=js"
import style0 from "./AssignMembers.vue?vue&type=style&index=0&id=0f67efd1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f67efd1",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAvatar: require('@chakra-ui/vue').CAvatar, CAvatarGroup: require('@chakra-ui/vue').CAvatarGroup, CBox: require('@chakra-ui/vue').CBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverHeader: require('@chakra-ui/vue').CPopoverHeader, CText: require('@chakra-ui/vue').CText, CIcon: require('@chakra-ui/vue').CIcon, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover})
