import { gql } from '@apollo/client/core';

export const addCardMutation = gql`
  mutation addCardMutation($object: cards_insert_input!) {
    insert_cards_one(object: $object) {
      updatedAt
      createdAt
      expiredDate
      name
      last4
      bin
      token
      userId
      isExpired
      id
    }
  }
`;

export const deleteCardMutation = gql`
  mutation deleteCardMutation($id: Int!) {
    delete_cards_by_pk(id: $id) {
      id
      createdAt
      expiredDate
      name
      last4
      bin
      token
      userId
      isExpired
    }
  }
`;
