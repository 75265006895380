import { gql } from '@apollo/client/core';

export const createMarketPotentialComponentMutation = gql`
  mutation createMarketPotentialComponentMutation(
    $object: market_potential_insert_input!
  ) {
    insert_market_potential_one(object: $object) {
      assumption
      assumptionData
      companyId
      createdAt
      description
      id
      otherDescription
      priority
      progress
      type
      updatedAt
      market_potential_assignments {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      market_potential_visibilities {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const updateMarketPotentialComponentMutation = gql`
  mutation updateMarketPotentialComponentMutation(
    $id: Int!
    $set: market_potential_set_input
  ) {
    update_market_potential_by_pk(pk_columns: { id: $id }, _set: $set) {
      assumption
      assumptionData
      companyId
      createdAt
      description
      id
      otherDescription
      priority
      progress
      type
      updatedAt
      market_potential_assignments {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      market_potential_visibilities {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const addMarketPotentialAccessMutation = gql`
  mutation addMarketPotentialAccessMutation(
    $object: market_potential_assignment_insert_input!
  ) {
    insert_market_potential_assignment_one(object: $object) {
      id
      userId
      marketPotentialId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeMarketPotentialAccessMutation = gql`
  mutation removeMarketPotentialAccessMutation($id: Int!) {
    delete_market_potential_assignment_by_pk(id: $id) {
      id
      userId
      marketPotentialId
    }
  }
`;

export const updateMarketPotentialVisibilityMutation = gql`
  mutation updateMarketPotentialVisibilityMutation(
    $addVisibility: [market_potential_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_market_potential_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        marketPotentialId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_market_potential_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        marketPotentialId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const addMarketPotentialCustomerMutation = gql`
  mutation addMarketPotentialCustomer(
    $object: market_potential_customer_insert_input!
  ) {
    insert_market_potential_customer_one(object: $object) {
      createdAt
      currency
      customerId
      description
      id
      links
      customer {
        type
        name
        id
        category
      }
      number
      price
      projectedGrowth
      updatedAt
      marketPotentialId
    }
  }
`;

export const updateMarketPotentialCustomerMutation = gql`
  mutation updateMarketPotentialCustomerMutation(
    $id: Int!
    $set: market_potential_customer_set_input
  ) {
    update_market_potential_customer_by_pk(
      pk_columns: { id: $id }
      _set: $set
    ) {
      createdAt
      currency
      customerId
      description
      id
      links
      customer {
        type
        name
        id
        category
      }
      number
      price
      projectedGrowth
      updatedAt
      marketPotentialId
    }
  }
`;

export const deleteMarketPotentialCustomerMutation = gql`
  mutation deleteMarketPotentialCustomerMutation($id: Int!) {
    delete_market_potential_customer_by_pk(id: $id) {
      id
    }
  }
`;
