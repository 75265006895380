var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"bg":"#fff","overflow":"hidden","borderRadius":"8px","w":_vm.isExpanded ? 'calc(100vw - 10em)' : { lg: '40em', xs: '100%' },"h":_vm.isExpanded ? 'calc(100vh - 9em)' : '35em',"transition":"width 200ms ease, height 200ms ease;","boxShadow":"1px 1px 4px rgba(75, 102, 171, 0.20)"}},[_c('c-flex',{attrs:{"h":"70px","bg":"vc-orange.300","align":"center","px":"4","justify":"space-between"}},[_c('c-link',{attrs:{"display":"flex"},on:{"click":_vm.onBackClick}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '25px',
          h: '25px',
          fill: '#fff',
        }),expression:"{\n          w: '25px',\n          h: '25px',\n          fill: '#fff',\n        }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/back-arrow.svg") + "#back-arrow"}})])]),_c('c-heading',{attrs:{"color":"#fff","fontSize":"xl","as":"h4"}},[_c('c-input',{attrs:{"borderWidth":"0px","fontWeight":"inherit","textAlign":"center","fontSize":"inherit","bg":"transparent","color":"#fff"},on:{"blur":_vm.onSaveClick},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('c-link',{attrs:{"display":"flex"},on:{"click":_vm.onClose}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '20px',
          h: '20px',
          fill: '#fff',
        }),expression:"{\n          w: '20px',\n          h: '20px',\n          fill: '#fff',\n        }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close.svg") + "#close"}})])])],1),_c('c-flex',{attrs:{"direction":"column","flexGrow":"0","h":"calc(100% - 75px)"}},[_c('c-box',{attrs:{"flexGrow":"1","maxHeight":_vm.attachments.length ? 'calc(100% - 150px)' : 'calc(100% - 90px)',"overflow":"hidden"}},[_c('quill-editor',{ref:"textEditor",staticClass:"editor",class:{ 'editor--expanded': _vm.isExpanded },attrs:{"options":_vm.editorOptions},on:{"input":_vm.debounceSave},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('c-flex',{attrs:{"px":"2","overflowX":"scroll"}},_vm._l((_vm.attachments),function(attachment){return _c('c-box',{key:attachment.id,attrs:{"borderWidth":"1px","borderRadius":"4px","mr":"2","mb":"2","position":"relative"},on:{"click":function($event){return _vm.openAttachment(attachment)}}},[_c('c-flex',[_c('c-flex',{attrs:{"align":"center","justify":"center","w":"60px","h":"60px","bg":"#ddd"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '15px',
                h: '15px',
                fill: '#000',
              }),expression:"{\n                w: '15px',\n                h: '15px',\n                fill: '#000',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-attach.svg") + "#attach"}})])]),_c('c-flex',{attrs:{"p":"2","direction":"column","justify":"center","w":"150px"}},[_c('c-text',{attrs:{"whiteSpace":"nowrap","overflow":"hidden","mb":"1","fontSize":"sm","textOverflow":"ellipsis"}},[_vm._v(" "+_vm._s(attachment.name)+" ")]),(attachment.type !== 'application')?_c('c-text',{attrs:{"fontSize":"xs"}},[_vm._v(" "+_vm._s(attachment.type)+" ")]):_c('c-text',{attrs:{"fontSize":"xs"}},[_vm._v(" Document ")]),(attachment.failed)?_c('c-link',{attrs:{"color":"blue.300","display":"flex","fontSize":"xs"},on:{"click":function($event){return _vm.doUpload(attachment)}}},[_c('c-text',{attrs:{"mr":"1","color":"red.300"}},[_vm._v("Error")]),_vm._v(" Try Again ")],1):_vm._e()],1)],1),(attachment.isUploading)?_c('c-progress',{attrs:{"size":"sm","color":"vue","value":100,"has-stripe":"","is-animated":""}}):_vm._e()],1)}),1),_c('c-flex',{attrs:{"h":"80px","p":"4","justify":"space-between","mt":"auto"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-link',{attrs:{"p":"2","borderRadius":"4px","display":"flex","bg":_vm.isExpanded ? 'blue.500' : 'transparent',"mr":"4"},on:{"click":_vm.expand}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '19px',
              h: '19px',
              fill: '#aaa',
            }),expression:"{\n              w: '19px',\n              h: '19px',\n              fill: '#aaa',\n            }"}],class:{ expanded: _vm.isExpanded }},[_c('use',{attrs:{"href":require("@/assets/icons/icon-maximize.svg") + "#maximize"}})])]),_c('c-link',{attrs:{"mr":"4","title":"attach file"},on:{"click":_vm.onAttachClick}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              w: '25px',
              h: '25px',
              fill: '#aaa',
            }),expression:"{\n              w: '25px',\n              h: '25px',\n              fill: '#aaa',\n            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-attach.svg") + "#attach"}})]),_c('input',{ref:"fileInput",attrs:{"type":"file","hidden":""},on:{"change":_vm.onFileInputChange}})]),_c('AssignMembers',{attrs:{"size":"small","members":_vm.assignedMembers,"showSelf":false},on:{"memberSelect":_vm.onMemberSelect}})],1),_c('c-flex',{attrs:{"justify":"flex-end"}},[_c('c-button',{attrs:{"variant-color":"vc-orange","variant":"outline","disabled":!_vm.content || !_vm.content.trim() || _vm.isUpdatingNote},on:{"click":_vm.onSaveClick}},[_vm._v(" Save "),(_vm.isUpdatingNote)?_c('c-spinner',{attrs:{"ml":"2","thickness":"2px","color":"vc-orange.400"}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }