import cloneDeep from 'lodash.clonedeep';

const state = () => ({
  activePresentation: null,
  presentationData: null,
  fontsList: [],
});

const mutations = {
  setPresentation(state, presentation) {
    state.activePresentation = cloneDeep(presentation);
  },
  updatePresentation(state, data) {
    state.activePresentation = { ...state.activePresentation, ...data };
  },
  setPresentationData(state, data) {
    state.presentationData = cloneDeep(data);
  },
  updatePresentationData(state, data) {
    state.presentationData = { ...state.presentationData, ...data };
  },
  setFonts(state, fonts) {
    state.fontsList = cloneDeep(fonts);
  },
};

const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
