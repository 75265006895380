import { gql } from '@apollo/client/core';

export const getTemplatesQuery = gql`
  query getTemplatesQuery($type: String!) {
    theme(where: { type: { _eq: $type } }) {
      createdAt
      hash
      id
      image
      name
      slug
      type
      updatedAt
    }
  }
`;

export const getPresentationsQuery = gql`
  query getPresentationsQuery($companyId: Int!) {
    presentation(
      where: { companyId: { _eq: $companyId } }
      order_by: { createdAt: desc }
    ) {
      id
      type
      data
      title
      themeId
      updatedAt
      createdAt
      companyId
      theme {
        createdAt
        hash
        id
        image
        name
        slug
        type
        updatedAt
      }
    }
  }
`;

export const getPresentationQuery = gql`
  query getPresentationQuery($id: Int!) {
    presentation_by_pk(id: $id) {
      id
      type
      data
      title
      themeId
      updatedAt
      createdAt
      companyId
      theme {
        id
        hash
        name
        slug
        type
        image
        createdAt
        updatedAt
      }
    }
  }
`;

export const getPitchDeckDataQuery = gql`
  query getPitchDeckDataQuery($companyId: Int!) {
    company_by_pk(id: $companyId) {
      elevatorPitch
      business_models {
        id
        type
        description
        customers {
          id
          name
          type
          category
          createdAt
          description
          businessModelId
        }
        revenues {
          businessModelId
          currency
          description
          essence
          frequency
          id
          type
          value
          revenue_customers {
            createdAt
            customerId
            id
            revenueId
            customer {
              name
              id
            }
          }
        }
      }
    }
    competition(
      where: {
        type: { _eq: point_of_differentiation }
        companyId: { _eq: $companyId }
      }
    ) {
      id
      companyId
      hiddenFields
      assumption
      createdAt
      description
      priority
      progress
      type
    }
    comparison: competition(
      where: { type: { _eq: comparison }, companyId: { _eq: $companyId } }
    ) {
      id
      companyId
      hiddenFields
      assumption
      createdAt
      description
      priority
      progress
      type
    }
    market_potential(
      where: {
        type: { _eq: go_to_market_strategy }
        companyId: { _eq: $companyId }
      }
    ) {
      assumption
      companyId
      createdAt
      description
      id
      otherDescription
      priority
      progress
      type
      updatedAt
    }
    milestones: milestone(
      where: { type: { _eq: "GOAL" }, companyId: { _eq: $companyId } }
    ) {
      categoryId
      calender_category {
        companyId
        createdAt
        id
        title
        updatedAt
      }
      companyId
      date
      createdAt
      dateAchieved
      title
      description
      id
      status
      metricType
      milestoneValue
      showOnMarketPlan
      relatedGoalId
      color
      metricName
      related_goal {
        categoryId
        companyId
        createdAt
        date
        dateAchieved
        description
        id
        metricType
        milestoneValue
        status
        title
        type
      }
    }
    team_members: team(
      where: { companyId: { _eq: $companyId } }
      order_by: { createdAt: asc }
    ) {
      user {
        id
        profilePhoto
        lastname
        isVerified
        isInviteFirstTime
        firstname
        email
      }
      position
      id
      role
      department
      isDeclined
      isAccepted
      isAdvisory
      accessType
      team_skills {
        id
        teamId
        companySKVId
        company_skill_value {
          id
          skillBySkill {
            id
            name
          }
        }
      }
    }
    vision: foundation(
      where: {
        companyId: { _eq: $companyId }
        foundation_type: { value: { _eq: vision } }
      }
    ) {
      assumption
      createdAt
      description
      id
      type
      progress
      priority
      updatedAt
      foundation_type {
        value
        description
      }
      foundation_items {
        id
        label
        description
      }
    }
    belief: foundation(
      where: {
        companyId: { _eq: $companyId }
        foundation_type: { value: { _eq: belief } }
      }
    ) {
      assumption
      createdAt
      description
      id
      type
      progress
      priority
      updatedAt
      foundation_type {
        value
        description
      }
      foundation_items {
        id
        label
        description
      }
    }
    culture: foundation(
      where: {
        companyId: { _eq: $companyId }
        foundation_type: { value: { _eq: culture } }
      }
    ) {
      assumption
      createdAt
      description
      id
      type
      progress
      priority
      updatedAt
      foundation_type {
        value
        description
      }
      foundation_items {
        id
        label
        description
      }
    }
    company_sdg(where: { companyId: { _eq: $companyId } }) {
      assumption
      companyId
      company_sdg_values {
        SDGId
        companySDGId
        id
        sustainable_development_goal {
          name
          image
          id
        }
      }
      contribution
      createdAt
      evaluation
      id
      priority
      progress
      updatedAt
    }
  }
`;
