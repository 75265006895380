const state = () => ({
  isTourActive: false,
  activeTour: null,
  activeStepIndex: null,
  activeTourGroup: null,
  lastTour: null,
  lastTourGroup: null,
  tourStages: [],
});
const mutations = {
  setTourState(state, value) {
    state.isTourActive = value;
  },
  setActiveTour(state, tour) {
    state.activeTour = tour;
  },
  setActiveStepIndex(state, index) {
    state.activeStepIndex = index;
  },
  setActiveTourGroup(state, group) {
    state.activeTourGroup = group;
  },
  setLastTour(state, tour) {
    state.lastTour = tour;
  },
  setLastTourGroup(state, tour) {
    state.lastTourGroup = tour;
  },
  setTourStages(state, stages) {
    state.tourStages = stages;
  },
};

const actions = {
  startTour({ commit }, { tour, startIndex = 0, tourGroup = null }) {
    commit('setActiveStepIndex', startIndex);
    commit('setActiveTour', tour);
    commit('setLastTour', tour);
    commit('setTourState', true);
    if (tourGroup) {
      commit('setActiveTourGroup', tourGroup);
      commit('setLastTourGroup', tourGroup);
    } else {
      commit('setActiveTourGroup', null);
      commit('setLastTourGroup', null);
    }
  },
  setActiveStepIndex({ commit }, index) {
    commit('setActiveStepIndex', index);
  },
  stopTour({ commit }) {
    commit('setTourState', false);
    commit('setActiveTour', null);
    commit('setActiveStepIndex', null);
    commit('setActiveTourGroup', null);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
