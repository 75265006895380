import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:
    process.env.VUE_APP_SERVER_URL ||
    'https://server.vibrantcreator.com/v1/api',
  // baseURL: 'https://api-dev.vibrantcreator.com/v1/api',
});

const noAuthRoute = [
  'login',
  'verify_email',
  'resendVerifyEmail',
  'users',
  'set_password',
];

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (noAuthRoute.some((url) => !config.url.endsWith(url))) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
      };
    }
    return config;
  }
  // function (error) {
  //   // Do something with request error
  //   return Promise.reject(error);
  // }
);

export default axiosInstance;
