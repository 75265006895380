import { cloneDeep } from 'lodash';

const state = () => ({
  milestones: [],
  phases: [],
  categories: [],
  scenarios: [],
});

const mutations = {
  setMilestones(state, milestones) {
    state.milestones = milestones;
  },
  setPhases(state, phases) {
    state.phases = phases;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setScenarios(state, scenarios) {
    state.scenarios = scenarios;
  },
  addScenario(state, scenario) {
    state.scenarios.push({ ...scenario });
  },
  replaceScenario(state, { scenario, index, deleteCount = 1 }) {
    state.scenarios.splice(index, deleteCount, scenario);
  },
  deleteScenario(state, index) {
    state.scenarios.splice(index, 1);
  },
  addCategory(state, category) {
    state.categories.push({ ...category });
  },
  addPhase(state, phase) {
    state.phases.push({ ...phase });
  },
  addMilestone(state, milestone) {
    state.milestones.push({ ...milestone });
  },
  updateMilestone(state, { index, milestone }) {
    state.milestones.splice(index, 1, milestone);
  },
  deleteMilestone(state, index) {
    state.milestones.splice(index, 1);
  },
};

const actions = {
  resetTimeline({ commit }) {
    commit('setMilestones', []);
    commit('setPhases', []);
    commit('setCategories', []);
    commit('setScenarios', []);
  },
  updateMilestone({ commit, state }, milestone) {
    const index = state.milestones.findIndex(
      (_milestone) => _milestone.id === milestone.id
    );
    commit('updateMilestone', { index, milestone: cloneDeep(milestone) });
  },
  deleteMilestone({ commit, state }, milestone) {
    const index = state.milestones.findIndex(
      (_milestone) => _milestone.id === milestone.id
    );
    commit('deleteMilestone', index);
  },
};

const getters = {
  calendarEvents(state) {
    const events = [...state.milestones, ...state.phases];
    return events.map((event) => {
      const type = event.date ? 'milestone' : 'phase';
      let eventData = {
        id: event.id,
        title: event.title,
        type,
      };
      if (type === 'phase') {
        eventData = {
          ...eventData,
          start: event.from,
          end: event.to,
        };
      }
      if (type === 'milestone') {
        eventData = {
          ...eventData,
          date: event.date,
          milestoneData: {
            scenario: event.scenario,
            category: event.calender_category,
            isAcheived: false,
          },
        };
      }
      return eventData;
    });
  },
  timelineData(state) {
    const data = [...state.milestones, ...state.phases];
    return data;
  },
  phasesCount(state) {
    return state.phases.length;
  },
  milestonesCount(state) {
    return state.milestones.length;
  },
  acheivedMilestonesCount(state) {
    return state.milestones.filter((milestone) =>
      milestone ? milestone.status !== 'IN_PROGRESS' : false
    ).length;
  },
  inProgressMilestonesCount(state) {
    return state.milestones.filter((milestone) => {
      return milestone ? milestone.status === 'IN_PROGRESS' : false;
    }).length;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
