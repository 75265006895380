import { gql } from '@apollo/client/core';

export const getEventsQuery = gql`
  query getEventsQuery($limit: Int, $offset: Int) {
    event(limit: $limit, offset: $offset, order_by: { created_at: desc }) {
      id
      name
      start_date
      start_time
      end_date
      end_time  
      thumb_nail
      type
      time_zone
      description
      companyProfileId
      event_speakers {
        firstName
        lastName
        position
        companyName
      }
      attendees_aggregate {
        aggregate {
          count
        }
      }
    }
    event_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getEventByIdQuery = gql`
  query getEventByIdQuery($id: Int!) {
    event_by_pk(id: $id) {
      posts {
          content
          companyId
          created_at
          updated_at
          id
          eventId
          isCompanyPost
          attachments
          post_feeling
          mentions {
              companyId
              company {
                  id
                  name
                  country
                  createdAt
                  createdBy
              }
              userId
              user{
                  id
                  firstname
                  lastname
              }
          }
          postType
          userId
          company {
              id
              name
              logo
              customerSegments
              country
              createdAt
              createdBy
          }
          post_comments(where: { replyTo: { _is_null: true } }) {
              postId
              content
              createdAt
              id
              userId
              isEdited
              isDeleted
              user {
                  firstname
                  id
                  email
                  profilePhoto
                  lastname
              }
              replies {
                  content
                  createdAt
                  id
                  postId
                  user {
                      firstname
                      email
                      id
                      lastname
                      profilePhoto
                  }
                  userId
              }
          }
          post_likes {
              id
              userId
              user {
                  firstname
                  id
                  email
                  lastname
                  profilePhoto
              }
          }
          userId
          user {
              firstname
              email
              lastname
              id
              createdAt
              profilePhoto
          }
      }
      creator{
          id
          firstname
          lastname
      }  
      companyProfileId
      description
      end_date
      end_time
      name
      id
      start_date
      start_time
      thumb_nail
      time_zone
      type
      meeting_link
        event_speakers {
            position
            companyName
            companyId
            id
            firstName
            lastName
            position
            user {
                id
                lastname
                firstname
                profilePhoto
                email
                __typename
                companies {
                    city
                    country
                    id
                    name
                    logo
                    teams {
                        position
                    }
                }
            }
        }
    }
  }
`;

export const retrieveEventAttendees = gql`
  query retrieveEventAttendees($eventId: Int!, $limit: Int!) {
    event_attendee(limit: $limit, where: { eventId: { _eq: $eventId } }) {
      attendee {
        id
        firstname
        lastname
        profilePhoto
        email
        companies{
            id
            name
            country
            city
            __typename
        }  
      }
    }
  }
`;

export const aggregateEventAttendees = gql`
  query aggregateEventAttendees($eventId: Int!) {
    event_attendee_aggregate(where: { eventId: { _eq: $eventId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const getEventsAttendedByUserQuery = gql`
  query getEventsAttendedByUserQuery($loggedInUserId: Int!) {
    event(
      where: {
        attendees_aggregate: {
          count: {
            predicate: { _gte: 1 }
            filter: { attendeeId: { _eq: $loggedInUserId } }
          }
        }
      }
    ) {
      id
      name
      meeting_link
      end_date
      end_time
      start_date
      thumb_nail
      description
    }
  }
`;
