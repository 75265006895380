import { gql } from '@apollo/client/core';

export const createChannelMutation = gql`
  mutation createChannelMutation($objects: [chat_channel_insert_input!]!) {
    insert_chat_channel(objects: $objects) {
      returning {
        id
        name
      }
    }
  }
`;

// delete channel
export const deleteMessageChannelMutation = gql`
  mutation deleteMessageChannelMutation($channel_id: Int!) {
    update_chat_channel_by_pk(
      pk_columns: { id: $channel_id }
      _set: { IsDeleted: true }
    ) {
      id
    }
  }
`;

export const joinChannelMutation = gql`
  mutation joinChannelMutation(
    $object: chat_channel_participant_insert_input!
  ) {
    insert_chat_channel_participant_one(object: $object) {
      id
      channel_id
      participant_id
    }
  }
`;

export const leaveChannelMutation = gql`
  mutation leaveChannelMutation($loggedInUserId: Int!, $channelId: Int!) {
    delete_chat_channel_participant(
      where: {
        channel_id: { _eq: $channelId }
        participant_id: { _eq: $loggedInUserId }
      }
    ) {
      affected_rows
    }
  }
`;

export const writeChatMutation = gql`
  mutation writeChatMutation($object: personal_chat_message_insert_input!) {
    insert_personal_chat_message_one(object: $object) {
      id
      content
      IsRead
      created_at
      creatorId
      relationshipId
      recipientId
    }
  }
`;

export const sendChannelMessageMutation = gql`
  mutation sendChannelMessageMutation(
    $object: chat_channel_message_insert_input!
  ) {
    insert_chat_channel_message_one(object: $object) {
      id
      creatorId
      created_at
    }
  }
`;

export const markMessageAsReadMutation = gql`
  mutation markMessageAsReadMutation(
    $lastMessageId: Int!
    $senderId: Int!
    $recieverId: Int!
  ) {
    update_personal_chat_message(
      where: {
        id: { _lte: $lastMessageId }
        IsRead: { _eq: false }
        recipientId: { _eq: $recieverId }
        creatorId: { _eq: $senderId }
      }
      _set: { IsRead: true }
    ) {
      affected_rows
      returning {
        IsRead
      }
    }
  }
`;

export const markMessageAsReadInChannelMutation = gql`
  mutation markMessageAsReadInChannel(
    $channelId: Int!
    $loggedInUserId: Int!
    $lastMessageReadId: Int!
  ) {
    update_chat_channel_participant(
      where: {
        _and: [
          { channel_id: { _eq: $channelId } }
          { participant_id: { _eq: $loggedInUserId } }
        ]
      }
      _set: { lastMessageReadId: $lastMessageReadId }
    ) {
      affected_rows
    }
  }
`;
