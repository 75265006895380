import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=70ff900f&scoped=true"
import script from "./Note.vue?vue&type=script&lang=js"
export * from "./Note.vue?vue&type=script&lang=js"
import style0 from "./Note.vue?vue&type=style&index=0&id=70ff900f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ff900f",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CInput: require('@chakra-ui/vue').CInput, CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CProgress: require('@chakra-ui/vue').CProgress, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton})
