import { gql } from '@apollo/client/core';

export const getCategoriesQuery = gql`
  query getCategoriesQuery($companyId: Int!) {
    calender_category(
      order_by: { createdAt: asc }
      where: { companyId: { _eq: $companyId } }
    ) {
      updatedAt
      title
      id
      createdAt
      companyId
    }
  }
`;

export const getTimelineDataQuery = gql`
  query getTimelineDataQuery($companyId: Int!) {
    calender_category(
      order_by: { createdAt: asc }
      where: { companyId: { _eq: $companyId } }
    ) {
      updatedAt
      title
      id
      createdAt
      companyId
    }
    scenario(
      order_by: { createdAt: asc }
      where: { companyId: { _eq: $companyId } }
    ) {
      companyId
      id
      color
      title
    }
    phase(
      order_by: { createdAt: asc }
      where: { companyId: { _eq: $companyId } }
    ) {
      to
      from
      id
      title
      updatedAt
      description
      createdAt
      companyId
      categoryId
      calender_category {
        title
      }
    }
    milestone(
      order_by: { createdAt: asc }
      where: { companyId: { _eq: $companyId } }
    ) {
      companyId
      createdAt
      date
      categoryId
      scenarioId
      description
      id
      isDeleted
      dateAchieved
      status
      title
      updatedAt
      calender_category {
        title
      }
      scenario {
        id
        color
        title
      }
    }
  }
`;

export const getMilestoneDataQuery = gql`
  query getCompanyQuery($companyId: Int!) {
    company(order_by: { createdAt: asc }, where: { id: { _eq: $companyId } }) {
      id
      milestones {
        companyId
        createdAt
        date
        categoryId
        scenarioId
        description
        id
        isDeleted
        dateAchieved
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          id
          color
          title
        }
      }
      scenarios {
        companyId
        id
        color
        title
      }
      phases {
        to
        from
        id
        title
        updatedAt
        description
        createdAt
        companyId
        categoryId
        calender_category {
          title
        }
      }
      calender_categories {
        updatedAt
        title
        id
        createdAt
        companyId
      }
    }
  }
`;

export const subscribeToMilestoneDataQuery = gql`
  subscription subscribeToCompanyQueryData($companyId: Int!) {
    company(order_by: { createdAt: asc }, where: { id: { _eq: $companyId } }) {
      id
      milestones {
        companyId
        createdAt
        date
        categoryId
        scenarioId
        description
        id
        isDeleted
        dateAchieved
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          id
          color
          title
        }
      }
      scenarios {
        companyId
        id
        color
        title
      }
      phases {
        to
        from
        id
        title
        updatedAt
        description
        createdAt
        companyId
        categoryId
        calender_category {
          title
        }
      }
      calender_categories {
        updatedAt
        title
        id
        createdAt
        companyId
      }
    }
  }
`;
