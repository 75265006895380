<template>
<div style="width: 100%; height: 100%; padding-left: 10px; padding-right: 10px">
  <div style="display: inline-block; width: 50%">
    <c-flex @click="selectFeeling(emoji)" h="30px" justify="start" align="center" class="px-3" :class="{'selected': selectedFeeling && (emoji.text === selectedFeeling.text) }" :key="index" v-for="(emoji, index) in feelingsFirstColumn">
      <c-text>{{ emoji.code }}</c-text>
      <c-text ml="10px" fontWeight="400" fontSize="md">{{ emoji.text }}</c-text>
    </c-flex>
  </div>

  <div style="display: inline-block; width: 50%">
    <c-flex @click="selectFeeling(emoji)" h="30px" justify="start" align="center" class="px-3" :class="{'selected': selectedFeeling && (emoji.text === selectedFeeling.text) }" :key="index" v-for="(emoji, index) in feelingsSecondColumn">
      <c-text>{{ emoji.code }}</c-text>
      <c-text ml="10px" fontWeight="400" fontSize="md">{{ emoji.text }}</c-text>
    </c-flex>
  </div>
</div>
</template>a

<script>
export default {
  name: "FeelingEmojiView",
  computed: {
    feelingsFirstColumn() {
      return this.emojis.slice(0, Math.ceil(this.emojis.length / 2));
    },
    feelingsSecondColumn() {
      return this.emojis.slice(Math.ceil(this.emojis.length / 2));
    },
    selectedFeeling(){
      return this.$store.getters["explore/getSelectedFeeling"]
    }
  },
  data(){
    return {
      selectedEmoji: {},
      emojis: [
        { "text": "accomplished", "code": "🏆" },
        { "text": "aggravated", "code": "😠" },
        { "text": "alive", "code": "🌟" },
        { "text": "alone", "code": "😔" },
        { "text": "amazed", "code": "😲" },
        { "text": "amazing", "code": "😍" },
        { "text": "amused", "code": "😄" },
        { "text": "angry", "code": "😡" },
        { "text": "annoyed", "code": "😒" },
        { "text": "anxious", "code": "😰" },
        { "text": "awesome", "code": "👍" },
        { "text": "awful", "code": "😩" },
        { "text": "bad", "code": "👎" },
        { "text": "beautiful", "code": "😊" },
        { "text": "better", "code": "🙂" },
        { "text": "blah", "code": "😑" },
        { "text": "blessed", "code": "🙏" },
        { "text": "bored", "code": "😐" },
        { "text": "broken", "code": "💔" },
        { "text": "chill", "code": "😎" },
        { "text": "cold", "code": "🥶" },
        { "text": "comfortable", "code": "😌" },
        { "text": "confident", "code": "😏" },
        { "text": "confused", "code": "😕" },
        { "text": "content", "code": "😊" },
        { "text": "cool", "code": "😎" },
        { "text": "crappy", "code": "😒" },
        { "text": "crazy", "code": "🤪" },
        { "text": "curious", "code": "🤔" },
        { "text": "depressed", "code": "😞" },
        { "text": "determined", "code": "💪" },
        { "text": "disappointed", "code": "😞" },
        { "text": "down", "code": "😔" },
        { "text": "drained", "code": "😩" },
        { "text": "drunk", "code": "🥴" },
        { "text": "ecstatic", "code": "😁" },
        { "text": "emotional", "code": "😭" },
        { "text": "energized", "code": "⚡️" },
        { "text": "excited", "code": "😃" },
        { "text": "exhausted", "code": "😫" },
        { "text": "fantastic", "code": "👌" },
        { "text": "fat", "code": "😔" },
        { "text": "free", "code": "🆓" },
        { "text": "fresh", "code": "🌱" },
        { "text": "frustrated", "code": "😤" },
        { "text": "full", "code": "😋" },
        { "text": "funny", "code": "😄" },
        { "text": "good", "code": "👍" },
        { "text": "grateful", "code": "🙏" },
        { "text": "great", "code": "👌" },
        { "text": "guilty", "code": "😳" },
        { "text": "happy", "code": "😊" },
        { "text": "heartbroken", "code": "💔" },
        { "text": "helpless", "code": "😞" },
        { "text": "hopeful", "code": "🤞" },
        { "text": "hopeless", "code": "😞" },
        { "text": "horrible", "code": "😖" },
        { "text": "hot", "code": "🔥" },
        { "text": "hungry", "code": "😋" },
        { "text": "hurt", "code": "😢" },
        { "text": "impatient", "code": "😤" },
        { "text": "in love", "code": "❤️‍🔥" },
        { "text": "incomplete", "code": "🤷‍♂️" },
        { "text": "inspired", "code": "💡" },
        { "text": "irritated", "code": "😠" },
        { "text": "lazy", "code": "😴" },
        { "text": "lonely", "code": "😔" },
        { "text": "lost", "code": "😕" },
        { "text": "loved", "code": "😍" },
        { "text": "lovely", "code": "😊" },
        { "text": "lucky", "code": "🍀" },
        { "text": "mad", "code": "😡" },
        { "text": "meh", "code": "😑" },
        { "text": "miserable", "code": "😖" },
        { "text": "motivated", "code": "💪" },
        { "text": "nervous", "code": "😬" },
        { "text": "nostalgic", "code": "📜" },
        { "text": "OK", "code": "👌" },
        { "text": "old", "code": "👴" },
        { "text": "optimistic", "code": "😊" },
        { "text": "overwhelmed", "code": "😓" },
        { "text": "pained", "code": "😣" },
        { "text": "pissed", "code": "😡" },
        { "text": "pissed off", "code": "😡" },
        { "text": "positive", "code": "😊" },
        { "text": "pretty", "code": "😊" },
        { "text": "proud", "code": "🥲" },
        { "text": "pumped", "code": "💪" },
        { "text": "ready", "code": "🤩" },
        { "text": "refreshed", "code": "😌" },
        { "text": "relaxed", "code": "😌" },
        { "text": "relieved", "code": "😌" },
        { "text": "rough", "code": "😖" },
        { "text": "sad", "code": "😢" },
        { "text": "safe", "code": "🛡️" },
        { "text": "satisfied", "code": "😊" },
        { "text": "scared", "code": "😱" },
        { "text": "sexy", "code": "😏" },
        { "text": "shocked", "code": "😳" },
        { "text": "sick", "code": "🤒" },
        { "text": "silly", "code": "😜" },
        { "text": "sleepy", "code": "😴" },
        { "text": "sore", "code": "😣" },
        { "text": "sorry", "code": "😔" },
        { "text": "special", "code": "🎁" },
        { "text": "stressed", "code": "😩" },
        { "text": "strong", "code": "💪" },
        { "text": "stupid", "code": "🤦‍♂️" },
        { "text": "super", "code": "🦸‍♂️" },
        { "text": "surprised", "code": "😯" },
        { "text": "terrible", "code": "😞" },
        { "text": "thankful", "code": "🙏" },
        { "text": "tired", "code": "😴" },
        { "text": "uncomfortable", "code": "😣" },
        { "text": "upset", "code": "😞" },
        { "text": "weak", "code": "😔" },
        { "text": "weird", "code": "🤪" },
        { "text": "well", "code": "😊" },
        { "text": "wonderful", "code": "😊" },
        { "text": "worried", "code": "😟" }
      ]
    }
  },

  methods: {
    selectFeeling(selected){
      if( selected === this.$store.getters["explore/getSelectedFeeling"] ){
        this.$store.dispatch('explore/selectFeeling', null)
        this.$emit('feelingSelected', null)
      }
      else{
        this.$store.dispatch('explore/selectFeeling', selected)
        this.$emit('feelingSelected', selected)
      }
    }
  },

  mounted() {

  }
}
</script>

<style scoped>
.selected {
  border-radius: 5px;
  background-color: #FBCDBE;
}
</style>