import { mapActions } from 'vuex';
import store from '@/store';
import { getOneOnOneMessages } from '@/services/messages.js';

export default {
  methods: {
    ...mapActions({
      addQuickChat: 'messaging/addQuickChat',
    }),
    async sendQuickMessage(userData) {
      console.log(userData);
      const response = await getOneOnOneMessages({
        loggedInUser: store.state.auth.user.id,
        profileUserId: userData.id,
        limit: 15,
        offset: 0,
      });

      const messages = response.data.personal_chat_message;

      const user = {
        name: `${userData.firstname} ${userData.lastname}`,
        id: userData.id,
        img: userData.profilePhoto,
        isChannel: false,
        OnlineStatus: userData.OnlineStatus,
        messageTotal:
          messages.length > 0
            ? messages[0].relation.messages_aggregate.aggregate.count
            : 0,
      };
      this.addQuickChat(user);
    },
  },
};
