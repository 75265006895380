import { gql } from '@apollo/client/core';

export const companyAffiliateStatus = gql`
    subscription companyAffiliateStatus($companyId: Int!) {
        company_by_pk(id: $companyId) {
            affiliateStatus
        }
    }
`

export const getProOrPremiumReferrals = gql`
    subscription GetReferralSubscriptionSummary($companyId: Int!, $priceId: String!, $priceId2: String!) {
        company_referral_aggregate(
            where: {referralId: {_eq: $companyId}, Subscription: {priceId: {_eq: $priceId}, _or: {priceId: {_eq: $priceId2}}}}
        ) {
            aggregate {
                count
            }
        }
    }
`

export const getFreemiumReferrals = gql`
    subscription GetFreemiumCount($companyId: Int!) {
        company_referral_aggregate(where: {referralId: {_eq: $companyId}, Subscription: {priceId: {_is_null: true}}}) {
            aggregate {
                count
            }
        }
    }
`