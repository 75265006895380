const font = 'Montserrat, sans-serif';
import tailwindConfig from './../../../tailwind.config';

export default {
  fonts: {
    heading: font,
    body: font,
  },
  colors: {
    'vc-green': {
      500: '#0FC694',
    },
    'vc-primary': {
      100: 'rgba(0,43,73,0.35)',
      400: '#002B49',
      500: '#002b49bd',
    },
    'vc-white': {
      20: '#F9FBFD',
    },
    'vc-info': {
      500: '#167FFC',
      700: '#4267B2',
    },
    'vc-blue': {
      100: '#F5FBFF',
      200: '#E5F3FD',
      300: '#C7D6F4',
      400: '#D1E5F4',
      500: '#BDD5E7',
      600: '#AECCE4',
      700: '#9ABDDC'
    },
    'vc-gray': {
      20: '#4267B211',
    },
    'vc-orange': {
      20: '#FBCDBE',
      50: '#f5977a',
      100: '#f48867',
      200: '#f27954',
      300: '#f16a41',
      400: '#ef5323',
      500: '#ee4c1b',
      600: '#e44211',
      700: '#d13d10',
      800: '#be370e',
      900: '#ab320d',
    },
  },
  fontSizes: { ...tailwindConfig.theme.fontSize },
  space: { ...tailwindConfig.theme.spacing },
  icons: {
    circle: {
      path: `
        <circle cx="50" cy="50" r="50" fill="currentColor"/>
      `,
      viewBox: '0 0 100 100',
    },
  },
};
