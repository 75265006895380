import { gql } from '@apollo/client/core';

export const createRiskAssessmentComponentMutation = gql`
  mutation createRiskAssessmentComponentMutation(
    $object: risk_assessment_insert_input!
  ) {
    insert_risk_assessment_one(object: $object) {
      assumption
      companyId
      createdAt
      description
      id
      priority
      progress
      type
      updatedAt
      risk_assessment_assignments {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_visibilities {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_values(order_by: { createdAt: asc }) {
        createdAt
        description
        id
        riskAssessmentId
        title
        type
        updatedAt
      }
    }
  }
`;

export const updateRiskAssessmentComponentMutation = gql`
  mutation updateRiskAssessmentComponentMutation(
    $id: Int!
    $set: risk_assessment_set_input
  ) {
    update_risk_assessment_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      assumption
      companyId
      createdAt
      description
      priority
      progress
      type
      updatedAt
      risk_assessment_assignments {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_visibilities {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_values(order_by: { createdAt: asc }) {
        createdAt
        description
        id
        riskAssessmentId
        title
        type
        updatedAt
      }
    }
  }
`;

export const addRiskAssessmentComponentAccessMutation = gql`
  mutation addRiskAssessmentComponentAccessMutation(
    $object: risk_assessment_assignment_insert_input!
  ) {
    insert_risk_assessment_assignment_one(object: $object) {
      id
      userId
      riskAssessmentId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeRiskAssessmentComponentAccessMutation = gql`
  mutation removeRiskAssessmentComponentAccessMutation($id: Int!) {
    delete_risk_assessment_assignment_by_pk(id: $id) {
      id
      userId
      riskAssessmentId
    }
  }
`;

export const updateRiskAssessmentComponentVisibilityMutation = gql`
  mutation updateRiskAssessmentComponentVisibilityMutation(
    $addVisibility: [risk_assessment_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_risk_assessment_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        riskAssessmentId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_risk_assessment_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        riskAssessmentId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const addRiskAssessmentValueMutation = gql`
  mutation addRiskAssessmentValueMutation(
    $object: risk_assessment_value_insert_input!
  ) {
    insert_risk_assessment_value_one(object: $object) {
      createdAt
      description
      id
      riskAssessmentId
      title
      type
      updatedAt
    }
  }
`;

export const updateRiskAssessmentValueMutation = gql`
  mutation updateRiskAssessmentValueMutation(
    $id: Int!
    $set: risk_assessment_value_set_input
  ) {
    update_risk_assessment_value_by_pk(pk_columns: { id: $id }, _set: $set) {
      createdAt
      description
      id
      riskAssessmentId
      title
      isDeleted
      type
      updatedAt
    }
  }
`;

export const addRiskAnalysisMutation = gql`
  mutation addRiskAnalysisMutation($object: risk_analysis_insert_input!) {
    insert_risk_analysis_one(object: $object) {
      actionPlan
      analysis
      createdAt
      description
      title
      employeeResponsible
      milestoneId
      team {
        user {
          firstname
          lastname
          id
          email
        }
        position
        department
      }
      riskAssessmentId
      riskAssessmentValueId
      id
      risk_assessment_value {
        title
        type
        risk_assessment {
          type
          priority
          progress
          description
          id
        }
      }
    }
  }
`;

export const updateRiskAnalysisMutation = gql`
  mutation updateRiskAnalysisMutation(
    $id: Int!
    $set: risk_analysis_set_input
  ) {
    update_risk_analysis_by_pk(pk_columns: { id: $id }, _set: $set) {
      actionPlan
      title
      analysis
      createdAt
      description
      employeeResponsible
      milestoneId
      milestone {
        companyId
        createdAt
        date
        categoryId
        scenarioId
        description
        id
        dateAchieved
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          id
          color
          title
        }
      }
      team {
        user {
          firstname
          lastname
          id
          email
        }
        position
        department
      }
      riskAssessmentId
      riskAssessmentValueId
      id
      risk_assessment_value {
        title
        type
        risk_assessment {
          type
          priority
          progress
          description
          id
        }
      }
    }
  }
`;

export const deleteRiskAnalysisMutation = gql`
  mutation deleteRiskAnalysisMutation($id: Int!) {
    delete_risk_analysis_by_pk(id: $id) {
      id
      actionPlan
      analysis
      createdAt
      description
      employeeResponsible
    }
  }
`;
