<template>
  <c-box w="100%" minHeight="300px">
    <c-flex
      align="center"
      :justify="isMarketPlan ? 'space-between' : 'flex-end'"
      mb="4"
    >
      <c-popover :placement="'right-end'" v-slot="{}" v-if="isMarketPlan">
        <c-popover-trigger>
          <c-link display="flex" fontWeight="500" color="#798892" fontSize="xs">
            <c-text as="span" mr="0.3rem">Select Market Goals</c-text>
            <svg
              v-if="!isSavingTraction"
              v-chakra="{
                w: '15px',
                h: '15px',
                fill: '#ef5323',
              }"
            >
              <use href="@/assets/icons/edit-fill.svg#edit" />
            </svg>
            <c-spinner ml="2" v-else color="#ef5323" thickness="2px" />
          </c-link>
        </c-popover-trigger>
        <c-popover-content
          z-index="4"
          w="max-content"
          ref="visibilityOptionsContent"
        >
          <c-popover-body
            paddingTop="5px"
            paddingBottom="5px"
            paddingInline="2"
          >
            <c-checkbox-group
              variant-color="green"
              :value="filteredTractions.map((_traction) => `${_traction.id}`)"
              @change="onCheckListChange"
            >
              <c-checkbox
                v-for="traction of tractions"
                :key="traction.id"
                :value="`${traction.id}`"
              >
                <c-text fontSize="xs" as="span">{{ traction.title }}</c-text>
              </c-checkbox>
            </c-checkbox-group>
          </c-popover-body>
        </c-popover-content>
      </c-popover>

      <c-link
        color="vc-orange.400"
        display="flex"
        alignItems="center"
        @click="isFormDrawerOpen = true"
        v-if="!disabled"
      >
        <c-text fontSize="sm" fontWeight="600" mr="2"
          >Add {{ titlecase(type.split('_').join(' ')) }}</c-text
        >
        <svg
          fill="#ef5323"
          v-chakra="{
            width: '25px',
            height: '25px',
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle" />
        </svg>
      </c-link>
    </c-flex>
    <c-flex flexWrap="wrap">
      <c-box
        v-for="traction in filteredTractions"
        :key="traction.id"
        borderRadius="8px"
        borderWidth="1px"
        w="250px"
        :borderColor="traction.color"
        p="4"
        mr="6"
        mb="3"
        position="relative"
      >
        <c-popover v-if="!disabled" placement="bottom" v-slot="{ onClose }">
          <c-popover-trigger
            position="absolute"
            right="15px"
            top="15px"
            cursor="pointer"
          >
            <c-box>
              <svg
                v-chakra="{
                  width: '10px',
                  height: '16px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/vertical-dots.svg#dots" />
              </svg>
            </c-box>
          </c-popover-trigger>
          <c-popover-content maxWidth="150px" zIndex="modal">
            <c-popover-body p="0">
              <c-list fontSize="sm">
                <c-list-item borderBottomWidth="1px">
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="editTraction(traction, onClose)"
                    >Edit</c-box
                  >
                </c-list-item>
                <c-list-item
                  v-if="
                    type !== 'traction' && traction.status === 'IN_PROGRESS'
                  "
                  borderBottomWidth="1px"
                >
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="setAsAcheived(traction, onClose)"
                    >Mark as acheived</c-box
                  >
                </c-list-item>
                <c-list-item
                  v-if="traction.status !== 'IN_PROGRESS'"
                  borderBottomWidth="1px"
                >
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="setAsInProgress(traction, onClose)"
                    >Mark as In Progress</c-box
                  >
                </c-list-item>
                <c-list-item>
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="deleteTraction(traction, onClose)"
                    >Delete</c-box
                  >
                </c-list-item>
                <c-list-item v-if="isMarketPlan">
                  <c-box
                    cursor="pointer"
                    w="100%"
                    px="2"
                    py="2"
                    @click="removeFromMarketPlan(traction)"
                    >Remove from Market Plan</c-box
                  >
                </c-list-item>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
        <c-text fontWeight="600" mb="3">{{ traction.title }}</c-text>
        <c-text
          whiteSpace="normal"
          overflowWrap="break-word"
          maxWidth="100%"
          fontSize="sm"
          >{{ traction.description }}</c-text
        >
        <c-flex fontSize="sm" mb="1" align="center">
          <c-text fontWeight="500" mr="2">Due Date:</c-text>
          <c-box
            variant="solid"
            :bg="getDateColor(traction.date).bg"
            :color="getDateColor(traction.date).text"
            px="2"
            rounded="md"
            >{{ traction.date | moment('DD MMM. YYYY') }}</c-box
          >
        </c-flex>
        <c-flex v-if="type === 'traction'" fontSize="sm" mb="2" align="center">
          <c-text fontWeight="500" mr="2">Date Achieved:</c-text>
          <c-text>{{ traction.dateAchieved | moment('DD MMM. YYYY') }}</c-text>
        </c-flex>
        <c-text v-if="traction.metricType === 'text'">{{
          traction.milestoneValue
        }}</c-text>
        <c-flex v-else mb="2" align="center">
          <c-text fontWeight="500" mr="2">{{ traction.metricName }}:</c-text>
          <c-text>{{ traction.milestoneValue }}</c-text>
          <c-text v-if="traction.metricType === 'percentage'">%</c-text>
        </c-flex>
        <template v-if="traction.status">
          <c-link
            color="vc-orange.500"
            @click="
              traction.status === 'IN_PROGRESS'
                ? setAsAcheived(traction, () => {})
                : setAsInProgress(traction, () => {})
            "
          >
            <c-flex align="center">
              <c-text
                mr="2"
                fontSize="xs"
                :color="
                  traction.status === 'IN_PROGRESS'
                    ? 'vc-orange.400'
                    : '#0FC694'
                "
                fontWeight="600"
                >{{ traction.status.split('_').join(' ') }}</c-text
              >
              <svg
                v-chakra="{
                  width: '12px',
                  height: '12px',
                }"
                v-if="traction.status !== 'IN_PROGRESS'"
              >
                <use href="@/assets/icons/check-circle.svg#check" />
              </svg>
              <svg
                v-else
                v-chakra="{
                  width: '12px',
                  height: '12px',
                }"
              >
                <use href="@/assets/icons/circle.svg#circle" />
              </svg>
            </c-flex>
          </c-link>
        </template>
      </c-box>
    </c-flex>
    <c-drawer
      :isOpen="isFormDrawerOpen"
      placement="right"
      :on-close="closeFormDrawer"
      :initialFocusRef="() => $refs.inputInsideModal"
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-close-button />
        <c-drawer-header>
          {{ isEditMode ? 'Edit' : 'Add' }}
          {{ titlecase(type.split('_').join(' ')) }}
        </c-drawer-header>

        <c-drawer-body overflowY="scroll">
          <c-stack :spacing="4" w="100%">
            <c-box>
              <label for>Title</label>
              <c-input
                ref="inputInsideModal"
                v-model="$v.tractionForm.title.$model"
                placeholder="Title"
              />
            </c-box>
            <c-box>
              <label for>Type</label>
              <c-select
                v-model="$v.tractionForm.metricType.$model"
                placeholder="Select Metric Type"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="percentage">Percentage</option>
              </c-select>
            </c-box>
            <c-box v-if="['text', 'number'].includes(tractionForm.metricType)">
              <label for
                >{{ titlecase(type.split('_').join(' ')) }} Value</label
              >
              <c-textarea
                v-if="tractionForm.metricType === 'text'"
                :value="$v.tractionForm.value.$model"
                v-model="$v.tractionForm.value.$model"
                resize="none"
                placeholder="Enter Value"
              ></c-textarea>
              <c-input
                v-else
                type="number"
                v-model="$v.tractionForm.value.$model"
                placeholder="Enter Value"
              />
            </c-box>
            <c-box
              v-if="['percentage', 'number'].includes(tractionForm.metricType)"
            >
              <label for>Name of Value</label>

              <c-input
                v-model="$v.tractionForm.metricName.$model"
                type="text"
                placeholder="Enter Name for Value"
              />
            </c-box>
            <c-box v-if="tractionForm.metricType === 'percentage'">
              <label for
                >{{ titlecase(type.split('_').join(' ')) }} Value</label
              >
              <c-input-group>
                <c-input
                  type="number"
                  v-model="$v.tractionForm.value.$model"
                  placeholder="Enter Value"
                />
                <c-input-right-element>%</c-input-right-element>
              </c-input-group>
            </c-box>
            <c-box>
              <label for>Due Date</label>
              <AppDatePicker
                format="YYYY-MM-DD"
                type="date"
                v-model="$v.tractionForm.date.$model"
              >
                <template v-slot:input>
                  <c-input
                    size="lg"
                    readonly
                    placeholder="Select Date"
                    :value="dateDisplay"
                  ></c-input>
                </template>
              </AppDatePicker>
            </c-box>
            <c-box v-if="type === 'traction'">
              <label for>Date Achieved</label>
              <AppDatePicker
                format="YYYY-MM-DD"
                type="date"
                v-model="$v.tractionForm.dateAchieved.$model"
              >
                <template v-slot:input>
                  <c-input
                    size="lg"
                    readonly
                    placeholder="Select Date"
                    :value="dateAchievedDisplay"
                  ></c-input>
                </template>
              </AppDatePicker>
            </c-box>
            <c-accordion :allow-toggle="true" :default-index="[1]">
              <c-accordion-item>
                <c-accordion-header>
                  <c-box flex="1" text-align="right" py="2"
                    >Advanced options</c-box
                  >
                  <c-accordion-icon />
                </c-accordion-header>
                <c-accordion-panel pb px="0">
                  <c-box pb="4">
                    <label for>Scenario (optional)</label>
                    <AppSelect
                      label="title"
                      :reduce="(scenario) => scenario.id"
                      :options="scenarios"
                      :reset="true"
                      :fullWidth="true"
                      :bordered="true"
                      indicator-fill="orange"
                      color="dark"
                      :borderColor="'light'"
                      size="large"
                      v-model="$v.tractionForm.scenarioId.$model"
                      placeholder="Add Scenario"
                    />
                  </c-box>
                  <c-box pb="4">
                    <label for>Category</label>
                    <CategorySelect
                      v-model="$v.tractionForm.categoryId.$model"
                    />
                    <!-- <AppSelect
                label="title"
                :reduce="(category) => category.id"
                :options="categories"
                :reset="true"
                :fullWidth="true"
                :bordered="true"
                indicator-fill="orange"
                color="dark"
                v-model="$v.tractionForm.categoryId.$model"
                :borderColor="'light'"
                size="large"
                placeholder="Add Category"
                    />-->
                  </c-box>
                  <c-box pb="4">
                    <label for>Related Goal</label>
                    <AppSelect
                      label="title"
                      :reduce="(traction) => traction.id"
                      :options="
                        tractions.filter(
                          (_traction) => _traction.id !== tractionForm.id
                        )
                      "
                      :reset="true"
                      :fullWidth="true"
                      :bordered="true"
                      indicator-fill="orange"
                      color="dark"
                      v-model="$v.tractionForm.relatedGoalId.$model"
                      :borderColor="'light'"
                      size="large"
                      placeholder="Add Related Goal"
                    />
                  </c-box>
                  <c-box pb="4">
                    <c-flex align="center">
                      <c-text mr="3">Color</c-text>
                      <v-swatches
                        v-model="$v.tractionForm.color.$model"
                        :disabled="
                          isColorSwatchDisabled &&
                          $v.tractionForm.relatedGoalId.$model !==
                            tractionForm.id
                        "
                      />
                    </c-flex>
                  </c-box>

                  <c-box pb="4">
                    <label for>Description</label>
                    <c-textarea
                      :value="$v.tractionForm.description.$model"
                      v-model="$v.tractionForm.description.$model"
                      resize="none"
                      placeholder="Description"
                    ></c-textarea>
                  </c-box>

                  <c-box pb="4">
                    <c-checkbox
                      :value="$v.tractionForm.showOnMarketPlan.$model"
                      v-model="$v.tractionForm.showOnMarketPlan.$model"
                      >Show on Market Plan?</c-checkbox
                    >
                  </c-box>
                </c-accordion-panel>
              </c-accordion-item>
            </c-accordion>

            <c-flex align="center" justify="flex-end">
              <c-button
                @click="onTractionSubmit"
                variant="solid"
                :disabled="$v.tractionForm.$invalid || isSavingTraction"
                variant-color="vc-orange"
              >
                {{ isEditMode ? 'Update' : 'Add' }}
                {{ titlecase(type.split('_').join(' ')) }}
                <c-spinner
                  v-if="isSavingTraction"
                  color="#fff"
                  thickness="3px"
                />
              </c-button>
            </c-flex>
          </c-stack>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold"
          >Achieved Milestone</c-alert-dialog-header
        >
        <c-alert-dialog-body>
          Set Date Milestone was acheived
          <AppDatePicker
            format="YYYY-MM-DD"
            type="date"
            v-model="tempAchievedMilestoneDate"
          >
            <template v-slot:input>
              <c-input
                size="lg"
                readonly
                placeholder="Select Date"
                :value="achievedMilestoneDateDisplay"
              ></c-input>
            </template>
          </AppDatePicker>
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeAchievedDialog"
            >Cancel</c-button
          >
          <c-button
            :disabled="!tempAchievedMilestoneDate"
            variantColor="vue"
            @click="saveMilestoneAsAchieved"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsAchieved"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <c-alert-dialog
      :is-open="isInProgressDialogOpen"
      :on-close="closeInProgressDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold"
          >Ongoing Milestone</c-alert-dialog-header
        >
        <c-alert-dialog-body>
          Set Milestone status to 'IN PROGRESS'
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeInProgressDialog"
            >Cancel</c-button
          >
          <c-button
            variantColor="vue"
            @click="saveMilestoneAsInProgress"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsInProgress"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import AppDatePicker from '../../../components/AppDatePicker.vue';
import { titlecase } from '@/helpers/utils.js';
import { getGoals } from '@/services/foundation';
import {
  addMilestone,
  getTimelineData,
  updateMilestone,
} from '@/services/timeline';
import AppSelect from '@/views/App/components/AppSelect.vue';
import CategorySelect from '@/views/App/Timeline/components/CategorySelect.vue';
import VSwatches from 'vue-swatches';

export default {
  props: {
    type: {
      type: String,
      default: 'traction',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMarketPlan: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppDatePicker,
    AppSelect,
    CategorySelect,
    VSwatches,
  },
  validations: {
    tractionForm: {
      title: {
        required,
      },
      description: {},
      metricType: {
        required,
      },
      date: {
        required,
      },
      dateAchieved: {
        required: requiredIf(function () {
          return this.type === 'traction';
        }),
      },
      value: {
        required,
      },
      metricName: {
        required: requiredIf(function () {
          return this.metricType === 'number';
        }),
      },
      showOnMarketPlan: {},
      color: {},
      categoryId: {},
      scenarioId: {},
      relatedGoalId: {},
    },
  },
  data() {
    return {
      isFormDrawerOpen: false,
      isColorSwatchDisabled: false,
      tractionForm: {
        id: null,
        title: null,
        description: null,
        metricType: 'text',
        date: null,
        dateAchieved: null,
        value: null,
        metricName: null,
        categoryId: null,
        scenarioId: null,
        color: '',
        showOnMarketPlan: true,
        relatedGoalId: null,
      },
      tractions: [],
      isFetchingTractions: false,
      isSavingTraction: false,
      isEditMode: false,
      isAchievedDialogOpen: false,
      isInProgressDialogOpen: false,
      tempAchievedMilestone: null,
      tempInProgressMilestone: null,
      tempAchievedMilestoneDate: null,
      isMarkingAsAchieved: false,
      isMarkingAsInProgress: false,
    };
  },
  computed: {
    filteredTractions() {
      return this.tractions.filter((_traction) =>
        this.isMarketPlan ? _traction.showOnMarketPlan : true
      );
    },
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
    }),
    dateDisplay() {
      return this.formatDate(this.tractionForm.date);
    },
    dateAchievedDisplay() {
      return this.formatDate(this.tractionForm.dateAchieved);
    },
    achievedMilestoneDateDisplay() {
      return this.formatDate(this.tempAchievedMilestoneDate);
    },
  },
  watch: {
    'tractionForm.metricType': function () {
      this.tractionForm.value = null;
    },
    'tractionForm.relatedGoalId': function (v) {
      this.tractionForm.color = this.tractions.find(
        (traction) => traction.id == v
      )?.color;

      if (this.tractionForm.color) {
        this.isColorSwatchDisabled = true;
      } else {
        this.isColorSwatchDisabled = false;
      }
    },
  },
  created() {
    this.getTractions();
  },
  beforeDestroy() {
    this.tractions = [];
  },
  methods: {
    ...mapMutations({
      setCategories: 'timeline/setCategories',
      setScenarios: 'timeline/setScenarios',
      setPhases: 'timeline/setPhases',
      setMilestones: 'timeline/setMilestones',
    }),
    titlecase,
    formatDate(date) {
      return date && this.$moment(date).isValid()
        ? this.$moment(date).format('YYYY-MM-DD')
        : null;
    },
    getDateColor(date) {
      if (this.$moment(date).diff(new Date(), 'days') < 0) {
        return { bg: 'rgba(238, 48, 31, 0.1)', text: '#ee2f1f' };
      } else if (this.$moment(date).diff(new Date(), 'days') > 0) {
        return { bg: 'rgba(55, 213, 170, 0.1)', text: '#37d5aa' };
      } else {
        return { bg: 'rgba(86, 181, 219, 0.1)', text: '#56b5db' };
      }
    },
    setFormDrawer(value) {
      this.isFormDrawerOpen = value;
    },
    closeFormDrawer() {
      this.isFormDrawerOpen = false;
      this.isEditMode = false;
      this.resetForm();
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
      this.tempAchievedMilestone = null;
      this.tempAchievedMilestoneDate = null;
    },
    closeInProgressDialog() {
      this.isInProgressDialogOpen = false;
      this.tempInProgressMilestone = null;
    },
    async getTractions() {
      const status = this.type === 'traction' ? 'ACHIEVED' : 'IN_PROGRESS';
      this.isFetchingTractions = true;
      const res = await getGoals({ companyId: this.activeCompany.id, status });
      this.tractions = res.data.milestone
        ?.filter((val) => !val.isDeleted)
        ?.sort((a, b) => new Date(a.date) - new Date(b.date));
      await this.onGetTimelineData();
      this.isFetchingTractions = false;
    },
    async onGetTimelineData() {
      getTimelineData(this.activeCompany.id).then((res) => {
        this.setCategories(res.data.calender_category);
        this.setPhases(res.data.phase);
        this.setScenarios(res.data.scenario);
        this.setMilestones(res.data.milestone);
      });
    },
    onTractionSubmit() {
      if (this.isEditMode) {
        this.updateTraction();
      } else {
        this.addTraction();
      }
    },
    async addTraction() {
      this.isSavingTraction = true;
      const {
        title,
        description,
        metricType,
        value,
        date,
        color,
        dateAchieved,
        showOnMarketPlan,
        metricName,
        relatedGoalId,
      } = this.tractionForm;
      const data = {
        companyId: this.activeCompany.id,
        title,
        description,
        type: 'GOAL',
        metricType,
        date,
        dateAchieved,
        relatedGoalId,
        showOnMarketPlan,
        metricName,
        color,
        milestoneValue: value,
      };
      if (this.type === 'traction') {
        data.status = 'ACHIEVED';
      }
      try {
        const res = await addMilestone(data);
        this.tractions.push(res.data.insert_milestone_one);
        this.resetForm();
        this.closeFormDrawer();
        await this.onGetTimelineData();
        this.isSavingTraction = false;
      } catch (e) {
        this.isSavingTraction = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while adding ${titlecase(
            this.type.split('_').join(' ')
          )}, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    removeFromMarketPlan(traction) {
      this.setTractionForm({ ...traction, showOnMarketPlan: false });
      this.updateTraction();
    },
    addToMarketPlan(traction) {
      this.setTractionForm({ ...traction, showOnMarketPlan: true });

      this.updateTraction();
    },
    async updateTraction() {
      this.isSavingTraction = true;
      const {
        title,
        description,
        metricType,
        metricName,
        value,
        id,
        date,
        dateAchieved,
        color,
        showOnMarketPlan,
        relatedGoalId,
      } = this.tractionForm;
      const res = await updateMilestone({
        id,
        set: {
          title,
          description,
          metricType,
          metricName,
          relatedGoalId,
          milestoneValue: `${value}`,
          date,
          color,
          dateAchieved,
          showOnMarketPlan,
        },
      });
      const index = this.tractions.findIndex((traction) => traction.id === id);
      this.tractions.splice(index, 1, res.data.update_milestone_by_pk);
      this.isSavingTraction = false;
      this.closeFormDrawer();
    },
    resetForm() {
      this.tractionForm.description = null;
      this.tractionForm.title = null;
      this.tractionForm.metricType = 'text';
      this.tractionForm.metricName = '';
      this.tractionForm.showOnMarketPlan = false;
      this.tractionForm.value = null;
      this.tractionForm.id = null;
      this.tractionForm.date = null;
      this.tractionForm.dateAchieved = null;
      this.tractionForm.color = '';
    },
    setTractionForm(traction) {
      this.$v.tractionForm.$model = {
        ...traction,
        color: traction.color || '',
        value: traction.milestoneValue,
      };
      this.$nextTick(() => {
        this.$v.tractionForm.metricType.$model = traction.metricType;
        setTimeout(() => {
          switch (traction.metricType) {
            case 'percentage':
            case 'number':
              this.$v.tractionForm.value.$model = +traction.milestoneValue;
              break;
            default:
              break;
          }
        }, 2000);
      });
    },
    editTraction(traction, closePopover) {
      this.isEditMode = true;
      this.setTractionForm(traction);
      this.isFormDrawerOpen = true;
      closePopover();
    },
    setAsAcheived(traction, onClose) {
      if (this.type && !this.disabled) {
        this.tempAchievedMilestone = traction;
        this.isAchievedDialogOpen = true;
        onClose();
      }
    },
    setAsInProgress(traction, onClose) {
      if (this.type && !this.disabled) {
        this.tempInProgressMilestone = traction;
        this.isInProgressDialogOpen = true;
        onClose();
      }
    },
    async saveMilestoneAsAchieved() {
      this.isMarkingAsAchieved = true;
      await updateMilestone({
        id: this.tempAchievedMilestone.id,
        set: {
          dateAchieved: this.tempAchievedMilestoneDate,
          status: 'ACHIEVED',
        },
      });
      const index = this.tractions.findIndex(
        (traction) => traction.id === this.tempAchievedMilestone.id
      );
      this.tractions.splice(index, 1);
      this.isMarkingAsAchieved = false;
      this.closeAchievedDialog();
    },
    async saveMilestoneAsInProgress() {
      this.isMarkingAsInProgress = true;
      await updateMilestone({
        id: this.tempInProgressMilestone.id,
        set: {
          dateAchieved: null,
          status: 'IN_PROGRESS',
        },
      });
      const index = this.tractions.findIndex(
        (traction) => traction.id === this.tempInProgressMilestone.id
      );
      this.tractions.splice(index, 1);
      this.isMarkingAsInProgress = false;
      this.closeInProgressDialog();
    },
    async deleteTraction(traction) {
      const index = this.tractions.findIndex(
        (_traction) => _traction.id === traction.id
      );
      this.tractions.splice(index, 1);
      try {
        await updateMilestone({
          id: traction.id,
          set: {
            isDeleted: true,
          },
        });
      } catch (error) {
        this.tractions.splice(index, 0, traction);

        this.$toast({
          title: 'An error occurred.',
          description: `Error while deleting ${titlecase(
            this.type.split('_').join(' ')
          )}, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onCheckListChange(values) {
      // if an item was added
      if (values.length > this.filteredTractions.length) {
        const value = values[values.length - 1];
        const traction = this.tractions.find(
          (_traction) => _traction.id == `${value}`
        );
        this.addToMarketPlan(traction);
      } else {
        const tractionRemoved = this.filteredTractions.filter(
          (_traction) => !values.includes(`${_traction.id}`)
        )[0];
        this.removeFromMarketPlan(tractionRemoved);
      }

      // onClose();
    },
  },
};
</script>

<style lang="scss">
$vs-border-radius: 4px;
</style>
