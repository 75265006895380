const state = () => ({
  event: null,
  selectedEventSpeakers: []
});

const actions = {
  saveEvent({ commit }, eventData) {
    commit('setEvent', eventData);
  },
  removeEvent({commit}){
    commit('removeEvent')
  },
  selectEventSpeaker({ commit }, eventSpeaker) {
    commit('setSelectedEventSpeaker', eventSpeaker);
  },
  removeSelectedEventSpeaker({ commit }, selectedEventSpeaker) {
    commit('setRemovalOfSelectedEventSpeaker', selectedEventSpeaker)
  },
  resetSelectedEventSpeakers({ commit }) {
    commit('resetSelectedEventSpeakers')
  }
};

const mutations = {
  setEvent(state, payload) {
    state.event = payload;
  },
  removeEvent(state) {
    state.event = null
  },
  setSelectedEventSpeaker(state, selectedEventSpeaker) {
    state.selectedEventSpeakers.push(selectedEventSpeaker);
  },
  setRemovalOfSelectedEventSpeaker(state, selectedEventSpeaker) {
    state.selectedEventSpeakers = state.selectedEventSpeakers.filter(
        (usr) => usr.id !== selectedEventSpeaker.id
    );
  },
  resetSelectedEventSpeakers(state) {
    state.selectedEventSpeakers = [];
  },
}

const getters = {
  getSavedEvent: (state) => state.event,
  getSelectedEventSpeakers: (state) => state.selectedEventSpeakers
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
