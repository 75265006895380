import {
  addCompanySectorsMutation,
  addTractionMutation,
  createBusinessMutatation,
  removeCompanySectorsMutation,
  updateCompanyMutation,
  updateTractionMutation,
  updateTeamMemberMutation,
  addActivityLogMutation,
  deleteTeamMemberMutation,
  followCompanyMutation,
  unFollowCompanyMutation,
  recordReferral
} from '../graphql/mutations';
import {
  getCompanyQuery,
  globalBusinessConfigQuery,
  tractionsQuery,
  getComponentActivityQuery,
  subscribeToCompanyQuery,
  getShareCompanyQuery,
  getCompaniesQuery,
  getMatchingCompaniesQuery,
  getPopularCompanyToFollowQuery,
  getCompanyFollowersQuery,
  getCompanyAffiliateCoupons,
  getCompanyByReferralCode,
  getAffiliatesReferrals,
  getAppliedCouponForAffiliates
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

import http from '@/helpers/api';
import {companyAffiliateStatus, getFreemiumReferrals, getProOrPremiumReferrals} from "@/graphql/subscriptions";

export const getGlobalBusinessConfig = () => {
  return apolloInstance.query({ query: globalBusinessConfigQuery });
};

export const createCompany = (data) => {
  return apolloInstance.mutate({
    mutation: createBusinessMutatation,
    variables: { object: data },
  });
};

export const getCurrentAffiliateStatus = ( companyId ) => {
  return apolloInstance.subscribe({
    query: companyAffiliateStatus,
    variables: { companyId }
  })
}

export const getCompany = (id) => {
  return apolloInstance.query({
    query: getCompanyQuery,
    variables: { id },
    fetchPolicy: 'no-cache',
  });
};

export const getCompanyFollowers = (companyId) => {
  return apolloInstance.query({
    query: getCompanyFollowersQuery,
    variables: { companyId }
  })
}

export const getShareCompany = (id) => {
  return apolloInstance.query({
    query: getShareCompanyQuery,
    variables: { id },
    fetchPolicy: 'no-cache',
  });
};

export const getCompanies = (limit) => {
  return apolloInstance.query({
    query: getCompaniesQuery,
    fetchPolicy: 'no-cache',
    variables: { limit },
  });
};

export const getMatchingCompanies = (text) => {
  return apolloInstance.query({
    query: getMatchingCompaniesQuery,
    fetchPolicy: 'no-cache',
    variables: { text },
  });
};

export const subscribeToCompany = (id) => {
  return apolloInstance.subscribe({
    query: subscribeToCompanyQuery,
    variables: { id },
  });
};

export const updateCompany = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCompanyMutation,
    variables: { id, set },
  });
};

export const updateCompanySectors = async ({
  data,
  companySectors,
  companyId,
}) => {
  let company_sectors;
  const newSectors = data.filter((id) => !companySectors.includes(id));
  const removedSectors = companySectors.filter((id) => !data.includes(id));
  const addResponse = await addCompanySectors({
    ids: newSectors,
    companyId,
  });
  const removeResponse = await removeCompanySectors({
    ids: removedSectors,
    companyId,
  });
  if (addResponse) {
    company_sectors =
      addResponse.data.insert_company_sector.returning[0].company
        .company_sectors;
  }
  if (removeResponse) {
    company_sectors =
      removeResponse.data.delete_company_sector.returning[0].company
        .company_sectors;
  }
  return company_sectors;
};

export const addCompanySectors = async ({ ids, companyId }) => {
  if (!ids.length) return;
  const data = ids.map((sectorId) => {
    return {
      sectorId,
      companyId,
    };
  });
  return apolloInstance.mutate({
    mutation: addCompanySectorsMutation,
    variables: { objects: data },
  });
};

export const removeCompanySectors = async ({ ids, companyId }) => {
  if (!ids.length) return;
  return apolloInstance.mutate({
    mutation: removeCompanySectorsMutation,
    variables: { ids, companyId },
  });
};

export const inviteCompanyMember = (data) => {
  return http.post('/team/invite', data);
};

export const updateTeamMember = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateTeamMemberMutation,
    variables: { id, set },
  });
};

export const deleteTeamMember = (id) => {
  return apolloInstance.mutate({
    mutation: deleteTeamMemberMutation,
    variables: { id },
  });
};

export const getTractions = (companyId) => {
  return apolloInstance.query({
    query: tractionsQuery,
    variables: { companyId },
    fetchPolicy: 'network-only',
  });
};

export const addTraction = (data) => {
  return apolloInstance.mutate({
    mutation: addTractionMutation,
    variables: { object: data },
  });
};

export const updateTraction = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateTractionMutation,
    variables: { id, set },
  });
};

export const logActivity = (data) => {
  return apolloInstance.mutate({
    mutation: addActivityLogMutation,
    variables: { object: data },
  });
};

export const getComponentActivity = (filter) => {
  return apolloInstance.query({
    query: getComponentActivityQuery,
    variables: { where: filter },
  });
};

export const getPopularCompanyToFollow = (loggedInUser, limit) => {
  return apolloInstance.query({
    query: getPopularCompanyToFollowQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUser, limit },
  });
};

export const followCompany = (companyId) => {
  return apolloInstance.mutate({
    mutation: followCompanyMutation,
    variables: { companyId },
  });
};

export const unFollowCompany = ({companyId, userId}) => {
  return apolloInstance.mutate({
    mutation: unFollowCompanyMutation,
    variables: { companyId, userId },
  });
};

export const getAffiliateCoupons = (companyId) => {
  return apolloInstance.query({
    query: getCompanyAffiliateCoupons,
    variables: { companyId },
    fetchPolicy: 'network-only'
  })
}

export const getCompanyWithReferralCode = (referralCode) => {
  return apolloInstance.query({
    query: getCompanyByReferralCode,
    variables: { referralCode },
    fetchPolicy: 'network-only'
  })
}

export const recordCompanyReferral = ({companyThatRefers, companyReferred}) => {
  return apolloInstance.mutate({
    mutation: recordReferral,
    variables: { companyThatRefers, companyReferred }
  })
}

export const getAffiliateReferralCompanies = (companyId, limit, offset) => {
  return apolloInstance.query({
    query: getAffiliatesReferrals,
    variables: { companyId, limit, offset },
    fetchPolicy: 'network-only'
  })
}


export const getAppliedCouponForAffiliate = (companyId, limit, offset) => {
  return apolloInstance.query({
    query: getAppliedCouponForAffiliates,
    variables: { companyId, limit, offset },
    fetchPolicy: 'network-only'
  })
}

export const getProOrPremiumReferralBusinesses = (companyId, priceId, priceId2) => {
  return apolloInstance.subscribe({
    query: getProOrPremiumReferrals,
    variables: {companyId, priceId, priceId2}
  })
}

export const getFreemiumReferralBusinesses = (companyId) => {
  return apolloInstance.subscribe({
    query: getFreemiumReferrals,
    variables: {companyId},
  })
}