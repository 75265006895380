import { gql } from '@apollo/client/core';

export const createEventMutation = gql`
  mutation createEventMutation($object: event_insert_input!) {
    insert_event_one(object: $object) {
      id
      name
      description
      end_date
      end_time
      time_zone
      thumb_nail
      start_date
      start_time
      type
      companyProfileId
      event_speakers {
        firstName
        lastName
        position
        companyName
      }
    }
  }
`;

export const createEventPostMutation = gql`
  mutation createEventPostMutation($object: event_insert_input!) {
    insert_event_one(object: $object) {
      id
      name
      description
      end_date
      end_time
      time_zone
      thumb_nail
      start_date
      start_time
      type
      companyProfileId
      event_speakers {
        firstName
        lastName
        position
        companyName
      }
      posts {
        attachments
        companyId
        company {
          id
          name
          country
          createdAt
          createdBy
        }
        post_comments {
          postId
          content
          createdAt
          id
          userId
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
        }
        post_likes {
          userId
          id
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          profilePhoto
          id
          createdAt
        }
        content
        created_at
        id
        eventId
        updated_at
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves {
          userId
          postId
          post {
            id
          }
        }
      }
    }
  }
`;

export const attendEventMutation = gql`
  mutation attendEventMutation($object: event_attendee_insert_input!) {
    insert_event_attendee_one(object: $object) {
      id
      attendeeId
    }
  }
`;

export const leaveEventMutation = gql`
  mutation leaveEventMutation($eventId: Int!, $attendeeId: Int!) {
    delete_event_attendee(
      where: { eventId: { _eq: $eventId }, attendeeId: { _eq: $attendeeId } }
    ) {
      affected_rows
    }
  }
`;

// export const updateEventMutation = gql`
//   mutation EditEvent($eventId: Int!, $set: event_set_input ) {
//     update_event(where: {id: {_eq: $eventId}}, _set: $set) {
//       affected_rows
//     }
//   }
// `

export const updateEventMutation = gql`
  mutation updateEventData($eventId: Int!, $updateEventObj: event_set_input!, $newSpeaker: [event_speaker_insert_input!]!) {
    update_event_by_pk(pk_columns: {id: $eventId}, _set: $updateEventObj){
      id
    }
    delete_event_speaker(where: {eventId: {_eq: $eventId}}) {
      affected_rows
    }
    insert_event_speaker(objects: $newSpeaker) {
      affected_rows
    }
  }
`