<template>
  <button
    v-if="as == 'button'"
    :class="['btn', `btn--${variant}`, `btn--${size}`]"
    v-bind="$attrs"
  >
    <slot />
  </button>
  <router-link
    v-else-if="as == 'nlink'"
    :class="['btn', `btn--${variant}`, `btn--${size}`]"
    :to="to"
  >
    <slot />
  </router-link>
  <a v-else :class="['btn', `btn--${variant}`, `btn--${size}`]" :href="to">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    as: {
      type: String,
      default: 'button',
    },
    to: {
      type: String,
      default: '/',
    },
    variant: {
      type: String,
      default: 'solid',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
};
</script>

<style lang="scss">
.btn {
  @apply flex justify-center items-center rounded font-semibold text-base cursor-pointer transition-opacity;
  min-width: 120px;
  &--solid {
    @apply text-white border-0 bg-primary;
  }
  &--outline {
    @apply text-primary bg-transparent border-2 border-primary;
  }
  &--md {
    @apply h-14;
    min-width: 200px;
  }
  &--sm {
    @apply h-10 text-sm;
  }
  &--full {
    @apply w-full h-14;
  }
  &:hover {
    @apply opacity-80;
  }
}
</style>
