import { gql } from '@apollo/client/core';

export const addCommentToComponentMutation = gql`
  mutation addCommentToComponentMutation($object: comment_insert_input!) {
    insert_comment_one(object: $object) {
      componentId
      createdAt
      id
      message
      updatedAt
      type
      user {
        id
        firstname
        lastname
        profilePhoto
      }
      userId
    }
  }
`;

export const deleteCommentMutation = gql`
  mutation deleteCommentMutation($id: Int!) {
    delete_comment_by_pk(id: $id) {
      id
    }
  }
`;

export const updateCommentMutation = gql`
  mutation updateCommentMutation($id: Int!, $set: comment_set_input) {
    update_comment_by_pk(pk_columns: { id: $id }, _set: $set) {
      componentId
      createdAt
      id
      message
      updatedAt
      type
      user {
        id
        firstname
        lastname
        profilePhoto
      }
      userId
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUserMutation($id: Int!, $set: user_set_input) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $set) {
      bio
      email
      firstname
      createdAt
      coverImage
      lastname
      phoneNumber
      isDeleted
      isInviteFirstTime
      isVerified
      id
      isBlocked
      password
      profilePhoto
      updatedAt
    }
  }
`;

export const logPageVisitMutation = gql`
  mutation logPageVisitMutation($object: page_visited_insert_input!) {
    insert_page_visited_one(object: $object) {
      route
      id
      createdAt
      userId
    }
  }
`;

export const followUserMutation = gql`
    mutation followUser($userId: Int!) {
        insert_user_follower_one(object: {userId: $userId}) {
            userId
            __typename
        }
    }
`;

export const unFollowUserMutation = gql`
  mutation unFollowUser($userId: Int!, $followerId: Int!) {
    delete_user_follower(
      where: { followerId: { _eq: $followerId }, userId: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;
