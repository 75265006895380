import { gql } from '@apollo/client/core';

export const getCompetitionComponentQuery = gql`
  query getCompetitionComponentQuery(
    $type: competition_component_enum
    $companyId: Int!
  ) {
    competition(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      id
      companyId
      hiddenFields
      assumption
      createdAt
      description
      priority
      progress
      type
      updatedAt
      competition_assignments {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      competition_visibilities {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const subscribeToCompetitionComponent = gql`
  subscription subscribeToCompetitionComponentQuery(
    $type: competition_component_enum
    $companyId: Int!
  ) {
    competition(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      id
      companyId
      hiddenFields
      assumption
      createdAt
      description
      priority
      progress
      type
      updatedAt
      competition_assignments {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      competition_visibilities {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const getCompetitorsQuery = gql`
  query getCompetitorsQuery($companyId: Int!) {
    competitor(
      where: { competition: { companyId: { _eq: $companyId } } }
      order_by: { createdAt: asc }
    ) {
      competitor_customers {
        competitorId
        customerId
        id
        customer {
          name
          id
          type
          category
        }
      }
      createdAt
      customerExperience
      details
      id
      importanceLevel
      name
      isSelf
      pointOfDifferentiation
      revenueStreams
      type
      valueProposition
      website
      price
      keyTakeaway
      strengths
      socialMediaEngagement
      weaknesses
    }
  }
`;
