import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersist from 'vuex-persist';

const vuexPersist = new VuexPersist({
  key: 'vc-store',
  storage: window.localStorage,
});

import tour from './tour';
import auth from './auth';
import config from './config';
import company from './company';
import timeline from './timeline';
import presentation from './presentation';
import subscription from './subscription';
import explore from '@/store/explore';
import search from '@/store/search';
import messaging from './messaging';
import event from './event';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    tour,
    auth,
    config,
    company,
    timeline,
    presentation,
    subscription,
    explore,
    search,
    messaging,
    event,
  },
  plugins: [vuexPersist.plugin],
});
