import cloneDeep from 'lodash.clonedeep';

const state = () => ({
  quickChats: [],
  selectedChannelChat: null,
  selectedUserChat: null,
  newMessages: [],
  unreadMessageCount: 0,
});
const mutations = {
  addQuickChat(state, chat) {
    if (state.quickChats.length >= 4) {
      state.quickChats.shift();
    }
    const index = state.quickChats.findIndex((c) => c.id === chat.id);
    if (index !== -1) {
      state.quickChats.splice(index, 1);
    }
    state.quickChats.push(chat);
  },
  removeQuickChat(state, id) {
    // delete amd remove the whole object from the array
    const index = state.quickChats.findIndex((chat) => chat.id === id);
    state.quickChats.splice(index, 1);
  },
  updateQuickChat(state, chat) {
    const index = state.quickChats.findIndex((c) => c.id === chat.id);
    state.quickChats[index] = cloneDeep(chat);
  },
  selectedChannelChat(state, user) {
    state.selectedChannelChat = user;
  },
  setSelectedUserChat(state, user) {
    state.selectedUserChat = user;
  },
  addMessageNotification(state, { toast, message }) {
    // check if the message already exist by using the message.id
    // if message exists, remove the message
    // if message does not exist, add the message
    const index = state.newMessages.findIndex((m) => m.id === message.id);
    if (index === -1) {
      state.newMessages.push(message);
      const audio = new Audio(require('@/assets/sounds/tone.mp3'));
      audio.play();

      toast({
        title: 'Message',
        description: "You've got a new message.",
        status: 'success',
        duration: 10000,
        position: 'top-right',
      });

      if (state.newMessages.length > 0) {
        document.title = '(' + state.newMessages.length + ')' + ` New Messages`;
      } else {
        document.title = 'Vibrantcreator App'; // Default title
      }

      document.title = '(' + state.newMessages.length + ')' + ` New Messages`;
    } else {
      state.newMessages.splice(index, 1);
    }
  },
  clearMessageNotification(state, { id, creatorId }) {
    // delete amd remove all messages with the same id from the array
    state.newMessages = state.newMessages.filter((m) => m.id !== id);
    state.newMessages = state.newMessages.filter(
      (m) => m.creatorId !== creatorId
    );

    if (state.newMessages.length > 0) {
      document.title = '(' + state.newMessages.length + ')' + ` New Messages`;
    } else {
      document.title = 'Vibrantcreator App'; // Default title
    }
  },
  clearAllMessageNotification(state) {
    state.newMessages = [];
  },
  setUnreadMessageCount(state, count) {
    state.unreadMessageCount = count;
  },
};

const actions = {
  addQuickChat({ commit }, chat) {
    commit('addQuickChat', chat);
  },
  removeQuickChat({ commit }, id) {
    commit('removeQuickChat', id);
  },
  updateQuickChat({ commit }, chat) {
    commit('updateQuickChat', chat);
  },
  selectedChannelChat({ commit }, user) {
    commit('selectedChannelChat', user);
  },
  setSelectedUserChat({ commit }, user) {
    commit('setSelectedUserChat', user);
  },
  addMessageNotification({ commit }, { toast, message }) {
    commit('addMessageNotification', { toast, message });
  },
  clearMessageNotification({ commit }, { id, creatorId }) {
    commit('clearMessageNotification', { id, creatorId });
  },
  clearAllMessageNotification({ commit }) {
    commit('clearAllMessageNotification');
  },
  resetMessaging({ commit }) {
    commit('clearAllMessageNotification');
    commit('selectedChannelChat', null);
    commit('setSelectedUserChat', null);
  },
  updateMessageCount({ commit }, count) {
    commit('setUnreadMessageCount', count);
  },
};

const getters = {
  getQuickChats: (state) => state.quickChats,
  getQuickChatById: (state) => (id) =>
    state.quickChats.find((chat) => chat.id === id),
  getSelectedChannelChat: (state) => state.selectedChannelChat,
  getSelectedUserChat: (state) => state.selectedUserChat,
  getNewMessages: (state) => state.newMessages,
  getUnreadMessageCount: (state) => state.unreadMessageCount,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
