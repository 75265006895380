import { subscriptionAccess } from '@/helpers/data';

const state = () => ({
  plans: [],
  showTrialMessage: false,
});

const mutations = {
  setPlans(state, plans) {
    state.plans = plans.map((plan) => {
      return {
        access: subscriptionAccess[plan.name.toLowerCase()],
        ...plan,
      };
    });
  },
  setShowTrialMessage(state, showTrialMessage) {
    state.showTrialMessage = showTrialMessage;
  },
};

const actions = {
  setPlans({ commit }, plans) {
    // const trialPlan = {
    //   updatedAt: '2023-05-08T21:50:04.985224+00:00',
    //   name: 'Trial',
    //   id: 3,
    //   createdAt: '2022-07-01T14:45:14.606938+00:00',
    //   plan_prices: [
    //     {
    //       updatedAt: '2023-05-15T16:30:11.506631+00:00',
    //       price: 15,
    //       stripeId: 'price_1LLKGbDpNBuvEcjKkZwtlY5H',
    //       frequency: 'MONTHLY',
    //       id: 2,
    //       createdAt: '2022-07-01T14:47:32.684368+00:00',
    //       aiCreditUnit: 75000,
    //       __typename: 'plan_price',
    //     },
    //     {
    //       updatedAt: '2023-05-15T16:31:33.324953+00:00',
    //       price: 156,
    //       stripeId: 'price_1LLKGbDpNBuvEcjKbOREgz7v',
    //       frequency: 'YEARLY',
    //       id: 3,
    //       createdAt: '2022-07-01T14:47:57.467237+00:00',
    //       aiCreditUnit: 75000,
    //       __typename: 'plan_price',
    //     },
    //   ],
    //   __typename: 'plan',
    // };
    // plans.push(trialPlan);
    commit('setPlans', plans);
  },
  setShowTrialMessage({ commit }, showTrialMessage) {
    commit('setShowTrialMessage', showTrialMessage);
  },
};

const getters = {
  getCurrentPlan: (state, getters, rootState) => {
    if (!rootState.company.activeCompany) {
      return state.plans[0];
    }

    // check if plan_price.plan.id == 'free', it should return the first plan in the list
    if (
      rootState.company?.activeCompany?.activeSubscription?.priceId === 'free'
    ) {
      return state.plans[0];
      // // let startDate = a random date
      // const startDate = new Date('2023-06-01T14:45:14.606938+00:00');
      // const userCreatedDate = rootState.auth.user.createdAt;
      // // check if the user is created after the startDate
      // if (userCreatedDate > startDate) {
      //   return state.plans[0];
      // } else {
      //   // check if there a localstorage item called hasTrial
      //   if (localStorage.getItem('hasTrial')) {
      //     state.showTrialMessage = false;
      //   } else {
      //     // if not, set it to true and show the trial message
      //     localStorage.setItem('hasTrial', true);
      //     state.showTrialMessage = true;
      //   }

      //   return state.plans[3];
      // }
    } else {
      return state?.plans.find(
        (_plan) =>
          rootState?.company?.activeCompany?.activeSubscription?.planId ===
          _plan.id
      );
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
