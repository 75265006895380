<template>
  <c-box
    position="absolute"
    bottom="0"
    :right="{ lg: '24', xs: '0' }"
    py="4"
    px="6"
    zIndex="docked"
  >
    <template v-if="isNotesOpen">
      <NoteList
        @changeView="onViewChange"
        v-show="activeView == 'list'"
        @close="close"
        key="list"
        ref="noteListRef"
      />
      <Note
        :note="currentNote"
        v-if="currentNote"
        key="note"
        v-show="activeView !== 'list'"
        @close="close"
        @noteUpdated="onNoteUpdated"
        @changeView="onViewChange"
      />
      <!-- <transition name="fade" mode="out-in">
      </transition> -->
    </template>
    <c-flex
      v-if="false"
      w="max-content"
      align="center"
      justify="flex-end"
      ml="auto"
      mt="4"
    >
      <c-button
        @click="openNotes"
        variant-color="vc-orange"
        color="#fff"
        variant="solid"
        mr="3"
      >
        Notes
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import NoteList from './NoteList.vue';
import Note from './Note.vue';

export default {
  components: { NoteList, Note },
  data() {
    return {
      isNotesOpen: false,
      activeView: 'list',
      currentNote: null,
    };
  },
  methods: {
    openNotes() {
      this.isNotesOpen = true;
    },
    close() {
      this.isNotesOpen = false;
      this.activeView = 'list';
    },
    onViewChange({ view, payload }) {
      this.activeView = view;
      this.currentNote = payload;
    },
    onNoteUpdated() {
      this.$refs.noteListRef.getSections(true);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
