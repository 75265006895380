import moment from 'moment';
/**
 * Used to be called as last invocation. The tryout is made within wait time.
 * If immediate is set then debounced function is called immediately and never again within wait time.
 * @param func A function to call
 * @param wait A wait time to check whether to call the function
 * @param immediate If the function should be called in the beginning or at the end
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const customerColorMap = {
  government: '#3762d5',
  business: '#37d5aa',
  consumer: '#6bc1d1',
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.isSameOrBefore(lastDate)) {
    dates.push(currDate.clone());
    currDate.add(1, 'days');
  }

  return dates;
};

export const sanitizeUpdateObject = (data) => {
  // eslint-disable-next-line no-unused-vars
  const { __typename, createdAt, id, updateAt, ...rest } = data;
  return rest;
};

export const titlecase = (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isObject = (obj) => {
  return obj != null && obj.constructor.name === 'Object';
};

export async function fetchExternalComponent(url) {
  const name = url
    .split('/')
    .reverse()[0]
    .match(/^(.*?)\.umd/)[1];

  if (window[name]) return window[name];

  let promise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.addEventListener('load', () => {
      resolve(window[name]);
    });
    script.addEventListener('error', () => {
      reject(new Error(`Error loading ${url}`));
    });
    script.src = `${url}`;
    document.head.appendChild(script);
  });
  // script.src = `${url}?q=${Date.now()}`;
  return promise;
}

export const getError = (errMessage, type) => {
  if (errMessage.includes('permission')) {
    this.$toast({
      title: 'Access Denied.',
      description: `You don't have permission to add category.`,
      status: 'warning',
      position: 'top',
      duration: 4000,
    });
  } else {
    this.$toast({
      title: 'An error occurred.',
      description: `Error while ${type}, please try again.`,
      status: 'error',
      position: 'top',
      duration: 3000,
    });
  }
};

export function getInitials(fullName) {
  return fullName
    .split(' ')
    .map((name) => name[0].toUpperCase())
    .join('');
}

export function getMomentDates(currentDate) {
  // Ensure currentDate is in ISO format
  const date = moment(currentDate, moment.ISO_8601, true);

  // Check if the date is valid
  if (!date.isValid()) {
    console.error(
      'Invalid date format. Please provide a valid RFC2822 or ISO format date.'
    );
    return 'few seconds';
  }

  // Return formatted date
  return date.fromNow();
}

export function getDateFormat(currentDate) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const d = new Date(currentDate);
  const month = months[d.getMonth()];
  const day = d.getDate();
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
}
export function removeDuplicatesById(array) {
  const seen = new Set();
  return array.filter(item => {
    const isDuplicate = seen.has(item.id);
    seen.add(item.id);
    return !isDuplicate;
  });
}

export function convertTo12HourFormat(timeString) {
  // Create a new Date object with the time string
  const date = new Date(`1970-01-01T${timeString}`);

  // Extract the hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes with leading zero if needed
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  // Return the formatted time string
  return `${hours}:${minutesStr} ${ampm}`;
}

