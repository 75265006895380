<template>
  <c-theme-provider>
    <c-reset />
    <router-view />
  </c-theme-provider>
</template>

<script>
// import { mapActions } from 'vuex';
// import { onLogout } from '@/vue-apollo.js';

export default {
  name: 'App',
  mounted() {
    document.body.classList.add(this.getOS());
  },
  // data() {
  //   return {
  //     isLoggedOut: false,
  //   };
  // },
  // created() {
  //   if (!this.isLoggedOut) {
  //     this.onLogoutClick();
  //   }
  // },
  methods: {
    getOS() {
      const userAgent = window.navigator.userAgent;
      let os = null;
      if (userAgent.indexOf('Win') != -1) os = 'win';
      if (userAgent.indexOf('Mac') != -1) os = 'mac';
      if (userAgent.indexOf('Linux') != -1) os = 'linux';
      if (userAgent.indexOf('Android') != -1) os = 'md';
      if (userAgent.indexOf('like Mac') != -1) os = 'ios';

      return os;
    },
    // ...mapActions({
    //   setUser: 'auth/setUser',
    //   logout: 'auth/logout',
    //   resetCompanyState: 'company/resetCompanyState',
    //   resetTimeline: 'timeline/resetTimeline',
    // }),
    // async onLogoutClick() {
    //   this.logout();
    //   await onLogout(this.$apollo.provider.defaultClient);
    //   this.$router.push('/auth/login');
    //   this.resetCompanyState(null);
    //   this.resetTimeline();
    // },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$vs-border-radius: 8px;
$vs-state-active-bg: rgba($color-orange, 0.05);
$vs-state-active-color: #000;
$vs-border-color: $color-grey;
$vs-dropdown-min-width: 230px;

@import 'vue-select/src/scss/vue-select.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

body .vs__dropdown-menu,
.vc-select .vs__dropdown-menu {
  @apply font-bold text-sm;
  box-shadow: 2px 2px 10px #4b66ab33;
  border: none;
  border-radius: $vs-border-radius;
  top: 110%;
}

.vc-select .vs__dropdown-toggle {
  height: 45px;
}
.vc-select.vs--open .vs__dropdown-toggle {
  border-color: $color-orange;
  border-radius: $vs-border-radius;
}

.vc-select .vs__dropdown-option {
  @apply flex items-center;
  height: 50px;
}

.vc-select.vs--open.vs--single .vs__selected {
  position: relative;
  // margin-top: 8px;
}

.vc-select .vs__open-indicator {
  @apply hidden;
}

.vc-select.vs--unsearchable .vs__search {
  height: 1px;
  margin: 0;
  padding: 0;
}

.vc-select {
  &:not(.vs--single) {
    .vs__selected-options {
      .vs__selected {
        @apply text-sm;
        background-color: rgba($color-grey, 0.1);
        border: none;
        padding: 3px 10px;
      }
      .vs__deselect {
        fill: $color-orange;
      }
    }
  }
}

.vc-select__no-option {
  height: 50px;
  @apply flex items-center justify-center cursor-pointer;
  &:hover {
    background-color: $vs-state-active-bg;
  }
}

.vc-datepicker-main.vc-datepicker-popup {
  z-index: 2000000010 !important;
}

body {
  --primary-color: #002b49;
  height: 100vh;
  font-size: 85%;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;

  &.win {
    * {
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }

  @screen sm {
    font-size: 90%;
  }
  @screen lg {
    font-size: 100%;
  }
  & > div:first-of-type {
    @apply h-full;
  }
  &.v-tour--active {
    .app__content,
    .vc-datepicker-popup,
    [id^='modal-portal'] {
      pointer-events: auto;
    }
  }
  span[aria-hidden='true'] > div > div {
    z-index: 2000000010 !important;
  }
}

section[data-chakra-component='CPopper'] {
  &:focus {
    box-shadow: 0px 3px 6px #bababa81;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// NProgress loading bar

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
