const state = () => ({
  postAddonType: '',
  selectedFeeling: null,
  selectedTaggedUsers: [],
  selectedTaggedCompanies: [],
  search_param: '',
  search_results_type: '',
});

const mutations = {
  setPostAddonType(state, payload) {
    state.postAddonType = payload;
  },
  setSelectedFeeling(state, payload) {
    state.selectedFeeling = payload;
  },
  setSelectedTaggedUser(state, taggedUser) {
    state.selectedTaggedUsers.push(taggedUser);
  },
  resetSelectedTaggedUser(state) {
    state.selectedTaggedUsers = [];
  },
  setSelectedTaggedCompany(state, taggedCompany) {
    state.selectedTaggedCompanies.push(taggedCompany);
  },
  resetSelectedTaggedCompany(state) {
    state.selectedTaggedCompanies = [];
  },
  setRemovalOfTaggedUser(state, taggedUser) {
    state.selectedTaggedUsers = state.selectedTaggedUsers.filter(
      (usr) => usr.id !== taggedUser.id
    );
  },
  setRemovalOfTaggedCompany(state, taggedCompany) {
    state.selectedTaggedCompanies = state.selectedTaggedCompanies.filter(
      (comp) => comp.id !== taggedCompany.id
    );
  },

  setSearchParam(state, searchParam) {
    state.search_param = searchParam
  },

  setSearchResultType(state, resultType) {
    state.search_results_type = resultType
  }
};

const getters = {
  getPostAddonType: (state) => state.postAddonType,
  getSelectedFeeling: (state) => state.selectedFeeling,
  getSelectedTaggedUsers: (state) => state.selectedTaggedUsers,
  getSelectedTaggedCompanies: (state) => state.selectedTaggedCompanies,
  getSearchParams: (state) => state.search_param,
  getSearchResultsType: (state) => state.search_results_type
};

const actions = {
  selectPostAddonType({ commit }, postAddonType) {
    commit('setPostAddonType', postAddonType);
  },
  selectFeeling({ commit }, feeling) {
    commit('setSelectedFeeling', feeling);
  },
  selectTaggedUser({ commit }, taggedUser) {
    commit('setSelectedTaggedUser', taggedUser);
  },
  resetTaggedUser({ commit }) {
    commit('resetSelectedTaggedUser');
  },
  removeTaggedUser({ commit }, taggedUser) {
    commit('setRemovalOfTaggedUser', taggedUser);
  },
  selectTaggedCompany({ commit }, taggedCompany) {
    commit('setSelectedTaggedCompany', taggedCompany);
  },
  resetTaggedCompany({ commit }) {
    commit('resetSelectedTaggedCompany');
  },
  removeTaggedCompany({ commit }, taggedCompany) {
    commit('setRemovalOfTaggedCompany', taggedCompany);
  },
  setSearchParam({ commit }, searchparam) {
    commit('setSearchParam', searchparam)
  },
  setSearchResutType({ commit }, resultType) {
    commit('setSearchResultType', resultType)
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
