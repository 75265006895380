import { gql } from '@apollo/client/core';

export const createBusinessModelByTypeMutation = gql`
  mutation createBusinessModelByTypeMutation(
    $object: business_model_insert_input!
  ) {
    insert_business_model_one(object: $object) {
      assumption
      companyId
      createdAt
      description
      id
      priority
      progress
      type
      updatedAt
      business_model_assignments {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      business_model_visibilities {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const updateBusinessModelMutation = gql`
  mutation updateBusinessModelMutation(
    $type: business_model_type_enum!
    $companyId: Int!
    $set: business_model_set_input
  ) {
    update_business_model(
      where: { _and: { type: { _eq: $type }, companyId: { _eq: $companyId } } }
      _set: $set
    ) {
      returning {
        assumption
        assumptionData
        companyId
        createdAt
        description
        id
        priority
        progress
        type
        updatedAt
        business_model_assignments {
          id
          businessModelId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
        business_model_visibilities {
          id
          businessModelId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
      }
    }
  }
`;

export const addBusinessModelAccessMutation = gql`
  mutation addBusinessModelAccessMutation(
    $object: business_model_assignment_insert_input!
  ) {
    insert_business_model_assignment_one(object: $object) {
      id
      businessModelId
      userId
      user {
        email
        firstname
        id
        createdAt
        lastname
        profilePhoto
      }
    }
  }
`;

export const removeBusinessModelAccessMutation = gql`
  mutation removeBusinessModelAccessMutation($id: Int!) {
    delete_business_model_assignment_by_pk(id: $id) {
      businessModelId
      id
      userId
    }
  }
`;

export const updateBusinessModelVisibilityMutation = gql`
  mutation updateBusinessModelVisibilityMutation(
    $addVisibility: [business_model_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_business_model_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        businessModelId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_business_model_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        businessModelId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const addCustomerMutation = gql`
  mutation addCustomerMutation($object: customer_insert_input!) {
    insert_customer_one(object: $object) {
      valueProposition
      willingnessToPay
      updatedAt
      type
      solution
      problem
      priceSensitivity
      name
      description
      id
      createdAt
      category
      businessModelId
      customer_details(order_by: { createdAt: asc }) {
        title
        value
        id
        createdAt
      }
    }
  }
`;

export const updateCustomerMutation = gql`
  mutation updateCustomerMutation($id: Int!, $set: customer_set_input) {
    update_customer_by_pk(pk_columns: { id: $id }, _set: $set) {
      valueProposition
      willingnessToPay
      updatedAt
      type
      solution
      problem
      priceSensitivity
      name
      description
      id
      isDeleted
      createdAt
      category
      businessModelId
      customer_details(order_by: { createdAt: asc }) {
        title
        value
        id
        createdAt
      }
    }
  }
`;

export const addCustomerDetailMutation = gql`
  mutation addCustomerDetailMutation($object: customer_detail_insert_input!) {
    insert_customer_detail_one(object: $object) {
      id
      title
      value
      createdAt
      customerId
    }
  }
`;

export const updateCustomerDetailMutation = gql`
  mutation updateCustomerDetailMutation(
    $id: Int!
    $set: customer_detail_set_input
  ) {
    update_customer_detail_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      value
      title
      isDeleted
      createdAt
      customerId
    }
  }
`;

export const deleteCustomerDetailMutation = gql`
  mutation deleteCustomerDetailMutation($id: Int!) {
    delete_customer_detail_by_pk(idt: $id) {
      id
    }
  }
`;

export const addChannelMutation = gql`
  mutation addChannelMutation($object: channel_insert_input!) {
    insert_channel_one(object: $object) {
      id
      name
      type
      topic
      createdAt
      updatedAt
      customerId
      efficiency
      description
      businessModelId
      employeeResponsible
    }
  }
`;

export const updateChannelMutation = gql`
  mutation updateChannelMutation($id: Int!, $set: channel_set_input) {
    update_channel_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
      type
      topic
      createdAt
      updatedAt
      efficiency
      customerId
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteChannelMutation = gql`
  mutation deleteChannelMutation($id: Int!) {
    delete_channel_by_pk(id: $id) {
      id
    }
  }
`;

export const addKeyResourceMutation = gql`
  mutation addKeyResourceMutation($object: key_resource_insert_input!) {
    insert_key_resource_one(object: $object) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyResourceMutation = gql`
  mutation updateKeyResourceMutation($id: Int!, $set: key_resource_set_input) {
    update_key_resource_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteKeyResourceMutation = gql`
  mutation deleteKeyResourceMutation($id: Int!) {
    delete_key_resource_by_pk(id: $id) {
      id
    }
  }
`;

export const addKeyActivityMutation = gql`
  mutation addKeyActivityMutation($object: key_activity_insert_input!) {
    insert_key_activity_one(object: $object) {
      id
      name
      type
      createdAt
      updatedAt
      efficiency
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyActivityMutation = gql`
  mutation updateKeyActivityMutation($id: Int!, $set: key_activity_set_input) {
    update_key_activity_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
      type
      createdAt
      updatedAt
      efficiency
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteKeyActivityMutation = gql`
  mutation deleteKeyActivityMutation($id: Int!) {
    delete_key_activity_by_pk(id: $id) {
      id
    }
  }
`;

export const addKeyPartnerMutation = gql`
  mutation addKeyPartnerMutation($object: key_partner_insert_input!) {
    insert_key_partner_one(object: $object) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyPartnerMutation = gql`
  mutation updateKeyPartnerMutation($id: Int!, $set: key_partner_set_input) {
    update_key_partner_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteKeyPartnerMutation = gql`
  mutation deleteKeyPartnerMutation($id: Int!) {
    delete_key_partner_by_pk(id: $id) {
      id
    }
  }
`;

export const addRevenueMutation = gql`
  mutation addRevenueMutation($object: revenue_insert_input!) {
    insert_revenue_one(object: $object) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      frequency
      description
      actionPlan
      milestoneId
      businessModelId
      employeeResponsible
      revenue_customers {
        id
        customerId
        customer {
          name
          id
          category
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateRevenueMutation = gql`
  mutation updateRevenueMutation($id: Int!, $set: revenue_set_input) {
    update_revenue_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      frequency
      description
      milestoneId
      actionPlan
      businessModelId
      employeeResponsible
      revenue_customers {
        id
        customerId
        customer {
          name
          id
          category
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteRevenueMutation = gql`
  mutation deleteRevenueMutation($id: Int!) {
    delete_revenue_by_pk(id: $id) {
      id
    }
  }
`;

export const addRevenueCustomerMutation = gql`
  mutation addRevenueCustomerMutation($object: revenue_customer_insert_input!) {
    insert_revenue_customer_one(object: $object) {
      revenueId
      customerId
      id
      customer {
        id
        category
        name
        type
      }
    }
  }
`;

export const removeRevenueCustomerMutation = gql`
  mutation removeRevenueCustomerMutation($revenueId: Int!, $customerId: Int!) {
    delete_revenue_customer(
      where: {
        _and: [
          { customerId: { _eq: $customerId } }
          { revenueId: { _eq: $revenueId } }
        ]
      }
    ) {
      affected_rows
      returning {
        id
        customerId
        revenueId
      }
    }
  }
`;

export const addCostMutation = gql`
  mutation addCostMutation($object: cost_insert_input!) {
    insert_cost_one(object: $object) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      valueArray
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateCostMutation = gql`
  mutation updateCostMutation($id: Int!, $set: cost_set_input) {
    update_cost_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      valueArray
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteCostMutation = gql`
  mutation deleteCostMutation($id: Int!) {
    delete_cost_by_pk(id: $id) {
      id
    }
  }
`;

export const updateMultipleCostsMutation = gql`
  mutation updateMultipleCostsMutation($ids: [Int!], $set: cost_set_input) {
    update_cost(where: { id: { _in: $ids } }, _set: $set) {
      returning {
        id
        type
        value
        currency
        createdAt
        updatedAt
        valueArray
        description
        milestoneId
        actionPlan
        businessModelId
        employeeResponsible
        milestone {
          categoryId
          companyId
          createdAt
          date
          description
          id
          isDeleted
          scenarioId
          status
          title
          updatedAt
          calender_category {
            title
          }
          scenario {
            color
            title
          }
          metricType
          metricName
          showOnMarketPlan
          milestoneValue
          relatedGoalId
          color
          dateAchieved
        }
      }
    }
  }
`;
