<template>
  <c-box
    d="flex"
    alignItems="center"
    justify-content="space-between"
    class="user_container"
  >
    <div class="user" @click="sendMessageAndClose">
      <img
        :src="user.profilePhoto ? user.profilePhoto : placeHolderImage"
        alt="profile"
      />
      <div class="details">
        <div class="name_sec">
          <h3>
            {{ user.name }}
          </h3>
          <small v-if="type === 'history'">{{ user.chat_time }}</small>
        </div>

        <p v-if="type === 'history'">
          <small v-if="user.lastMessageIsYou">You:</small>
          {{ user.lastMessage }}
        </p>
        <p v-else>{{ user.company }}</p>
      </div>
    </div>
  </c-box>
</template>

<script>
import messagingMixin from '@/mixins/messaging.js';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import { getDateFormat } from '@/helpers/utils.js';

export default {
  name: 'UserItem',
  mixins: [messagingMixin],
  props: {
    user: Object,
    type: String,
  },
  data() {
    return {
      placeHolderImage,
    };
  },
  methods: {
    getDateFormat,
    sendMessageAndClose() {
      this.sendQuickMessage(this.user);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
#fixedHeight {
  height: 600px;
  overflow-y: scroll;
}

.user_container {
  padding-right: 10px;
  &:hover {
    background: #e4eaf5;
    border-radius: 3px;
  }
  .user {
    display: flex;
    margin-bottom: 3px;
    cursor: pointer;
    padding: 8px 5px;

    img {
      margin-right: 10px;
      width: 45px;
      height: 45px;
      object-fit: fill;
    }

    img.recent {
      border-radius: 50%;
    }
    img {
      border-radius: 8px;
    }

    .details {
      .name_sec {
        display: flex;
        align-items: center;

        h3 {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: var(--color-primary);

          width: 127px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        small {
          font-size: 9px;
        }
      }

      p {
        margin-top: 3px;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: var(--color-primary);

        height: 26px;
        width: 178px;
        overflow: hidden;
        text-overflow: ellipsis;

        small {
          font-weight: bold;
        }
      }
    }
  }
}

.follow_btn {
  display: flex;
  margin-top: 10px;
}
</style>
