import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';

import { getGlobalBusinessConfig } from '@/services/company.js';
import {
  getMarketPotentialComponent,
  getMarketPotentialCustomers,
} from '@/services/market-potential';
import cloneDeep from 'lodash.clonedeep';

const configMixin = {
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner']),
  },
  methods: {
    ...mapActions({
      setBusinessConfig: 'config/setBusinessConfig',
    }),
    ...mapMutations({
      setTam: 'company/setTam',
      setSom: 'company/setSom',
      setSam: 'company/setSam',
    }),
    getGlobalConfig() {
      getGlobalBusinessConfig().then((res) => {
        this.setBusinessConfig(res.data);
      });
    },
    async getCustomers(marketPotentialData, type) {
      const res = await getMarketPotentialCustomers(marketPotentialData.id);

      let customers = cloneDeep(res.data.market_potential_customer);
      let totalCustomersCount = this.totalCustomersCount(customers);
      let calculatedValue = this.calculatedValue(customers);
      if (type === 'tam') {
        this.setTam({
          customersCount: totalCustomersCount,
          value: calculatedValue,
        });
      } else if (type === 'sam') {
        this.setSam({
          customersCount: totalCustomersCount,
          value: calculatedValue,
        });
      } else if (type === 'som') {
        this.setSom({
          customersCount: totalCustomersCount,
          value: calculatedValue,
        });
      }
    },
    totalPrice(customers) {
      return customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.price,
        0
      );
    },
    averagePrice(customers) {
      return this.totalPrice(customers)
        ? this.totalPrice(customers) /
            customers?.filter(
              (customer) => customer.price > 0 && customer.number > 0
            )?.length
        : 0;
    },
    totalCustomersCount(customers) {
      return customers.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.number,
        0
      );
    },
    calculatedValue(customers) {
      return this.averagePrice(customers) * this.totalCustomersCount(customers);
    },
    getMarketPotentialData() {
      getMarketPotentialComponent({
        type: 'sam',
        companyId: this.activeCompany.id,
      }).then((res) => {
        this.setMarketPotentialData(res, 'sam');
      });
      getMarketPotentialComponent({
        type: 'som',
        companyId: this.activeCompany.id,
      }).then((res) => {
        this.setMarketPotentialData(res, 'som');
      });
      getMarketPotentialComponent({
        type: 'tam',
        companyId: this.activeCompany.id,
      }).then((res) => {
        this.setMarketPotentialData(res, 'tam');
      });
    },
    setMarketPotentialData(data, type) {
      if (data.data.market_potential.length) {
        let marketPotentialData = cloneDeep(data.data.market_potential[0]);
        this.prevData = cloneDeep(data.data.market_potential[0]);

        this.getCustomers(marketPotentialData, type);
      } else if (this.isCompanyOwner) {
        // this.setMarketPotentialSeedData();
      }
    },
  },
};

export default configMixin;
