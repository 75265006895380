<template>
  <c-box
    bg="#fff"
    overflow="hidden"
    borderRadius="8px"
    :w="isExpanded ? 'calc(100vw - 10em)' : { lg: '40em', xs: '100%' }"
    :h="isExpanded ? 'calc(100vh - 9em)' : '35em'"
    transition="width 200ms ease, height 200ms ease;"
    boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)"
  >
    <c-flex
      h="70px"
      bg="vc-orange.300"
      align="center"
      px="4"
      justify="space-between"
    >
      <c-link display="flex" @click="onBackClick">
        <svg
          v-chakra="{
            w: '25px',
            h: '25px',
            fill: '#fff',
          }"
        >
          <use href="@/assets/icons/back-arrow.svg#back-arrow"></use>
        </svg>
      </c-link>
      <c-heading color="#fff" fontSize="xl" as="h4">
        <c-input
          borderWidth="0px"
          fontWeight="inherit"
          textAlign="center"
          fontSize="inherit"
          v-model="title"
          @blur="onSaveClick"
          bg="transparent"
          color="#fff"
        ></c-input>
      </c-heading>
      <c-link @click="onClose" display="flex">
        <svg
          v-chakra="{
            w: '20px',
            h: '20px',
            fill: '#fff',
          }"
        >
          <use href="@/assets/icons/icon-close.svg#close"></use>
        </svg>
      </c-link>
    </c-flex>
    <c-flex direction="column" flexGrow="0" h="calc(100% - 75px)">
      <c-box
        flexGrow="1"
        :maxHeight="
          attachments.length ? 'calc(100% - 150px)' : 'calc(100% - 90px)'
        "
        overflow="hidden"
      >
        <quill-editor
          class="editor"
          :class="{ 'editor--expanded': isExpanded }"
          ref="textEditor"
          @input="debounceSave"
          :options="editorOptions"
          v-model="content"
        />
      </c-box>
      <c-flex px="2" overflowX="scroll">
        <c-box
          borderWidth="1px"
          borderRadius="4px"
          mr="2"
          mb="2"
          position="relative"
          v-for="attachment in attachments"
          :key="attachment.id"
          @click="openAttachment(attachment)"
        >
          <c-flex>
            <c-flex align="center" justify="center" w="60px" h="60px" bg="#ddd">
              <svg
                v-chakra="{
                  w: '15px',
                  h: '15px',
                  fill: '#000',
                }"
              >
                <use href="@/assets/icons/icon-attach.svg#attach"></use>
              </svg>
            </c-flex>
            <c-flex p="2" direction="column" justify="center" w="150px">
              <c-text
                whiteSpace="nowrap"
                overflow="hidden"
                mb="1"
                fontSize="sm"
                textOverflow="ellipsis"
              >
                {{ attachment.name }}
              </c-text>
              <c-text fontSize="xs" v-if="attachment.type !== 'application'">
                {{ attachment.type }}
              </c-text>
              <c-text fontSize="xs" v-else> Document </c-text>
              <c-link
                color="blue.300"
                v-if="attachment.failed"
                display="flex"
                fontSize="xs"
                @click="doUpload(attachment)"
              >
                <c-text mr="1" color="red.300">Error</c-text>
                Try Again
              </c-link>
            </c-flex>
          </c-flex>
          <c-progress
            v-if="attachment.isUploading"
            size="sm"
            color="vue"
            :value="100"
            has-stripe
            is-animated
          />
        </c-box>
      </c-flex>
      <c-flex h="80px" p="4" justify="space-between" mt="auto">
        <c-flex align="center">
          <c-link
            p="2"
            borderRadius="4px"
            display="flex"
            :bg="isExpanded ? 'blue.500' : 'transparent'"
            @click="expand"
            mr="4"
          >
            <svg
              :class="{ expanded: isExpanded }"
              v-chakra="{
                w: '19px',
                h: '19px',
                fill: '#aaa',
              }"
            >
              <use href="@/assets/icons/icon-maximize.svg#maximize"></use>
            </svg>
          </c-link>
          <c-link @click="onAttachClick" mr="4" title="attach file">
            <svg
              v-chakra="{
                w: '25px',
                h: '25px',
                fill: '#aaa',
              }"
            >
              <use href="@/assets/icons/icon-attach.svg#attach"></use>
            </svg>
            <input
              @change="onFileInputChange"
              type="file"
              ref="fileInput"
              hidden
            />
          </c-link>
          <AssignMembers
            size="small"
            @memberSelect="onMemberSelect"
            :members="assignedMembers"
            :showSelf="false"
          />
        </c-flex>
        <c-flex justify="flex-end">
          <c-button
            variant-color="vc-orange"
            variant="outline"
            @click="onSaveClick"
            :disabled="!content || !content.trim() || isUpdatingNote"
          >
            Save
            <c-spinner
              ml="2"
              thickness="2px"
              v-if="isUpdatingNote"
              color="vc-orange.400"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import AssignMembers from '@/views/App/GrowthFramework/components/AssignMembers.vue';
import { uploadFile } from '@/services/common';
import { generateID } from '@/helpers/data';
import { updateNote } from '@/services/notes';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

export default {
  props: {
    isCardView: {
      type: Boolean,
      default: false,
    },
    note: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AssignMembers,
  },
  data() {
    return {
      isExpanded: false,
      isUpdatingNote: false,
      noteData: null,
      editorOptions: {
        placeholder: '',
        modules: {
          toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ color: [] }, { background: [] }],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
          ],
        },
      },
      assignedMembers: [],
      attachments: [],
      content: '',
      title: null,
    };
  },
  created() {
    this.noteData = cloneDeep(this.note);
    this.setNoteFields();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onBackClick() {
      this.$emit('changeView', { view: 'list', payload: null });
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    onMemberSelect(member) {
      const memberIndex = this.assignedMembers.findIndex(
        (_member) => _member.id === member.user.id
      );
      if (memberIndex !== -1) {
        this.assignedMembers.splice(memberIndex, 1);
        return;
      }
      this.assignedMembers.push({ ...member.user });
    },
    onAttachClick() {
      this.$refs.fileInput.click();
    },
    onFileInputChange(e) {
      const file = e.target.files[0];
      const type = file.type.split('/')[0];
      const attachment = {
        id: generateID(4),
        name: file.name,
        type,
        url: null,
        isUploading: true,
        failed: false,
        success: false,
        file,
      };
      this.attachments.push(attachment);
      this.doUpload(attachment);
    },
    async doUpload(attachment) {
      attachment.isUploading = true;
      try {
        const res = await uploadFile({
          file: attachment.file,
          type: attachment.type,
        });
        attachment.isUploading = false;
        attachment.url = res.data.url;
        attachment.success = true;
      } catch (e) {
        attachment.isUploading = false;
        attachment.failed = true;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while uploading file, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    getAttachmentData() {
      return this.attachments.map(({ id, name, type, url }) => {
        return {
          id,
          name,
          type,
          url,
        };
      });
    },
    getAccessData() {
      const newAccess = this.assignedMembers.filter(
        (member) =>
          !this.noteData.access.find((access) => access.userId === member.id)
      );
      return newAccess.map((user) => {
        return {
          noteId: this.noteData.id,
          userId: user.id,
        };
      });
    },
    getAccessDeleteData() {
      const deletedAccess = this.noteData.access.filter(
        (access) =>
          !this.assignedMembers.find((member) => member.id === access.userId)
      );
      return deletedAccess.map((access) => access.id);
    },
    async onSaveClick() {
      this.isUpdatingNote = true;
      const data = {
        set: {
          title: this.title,
          content: this.content,
          attachments: this.getAttachmentData(),
        },
        noteId: this.noteData.id,
        accessData: this.getAccessData(),
        accessDeleteData: this.getAccessDeleteData(),
      };
      try {
        const res = await updateNote({ ...data });
        this.noteData = {
          ...res.data.update_note_by_pk,
        };
        res.data.delete_note_access.returning.forEach((deletedAccess) => {
          const deletedAccessIndex = this.noteData.access.find(
            (access) => access.id === deletedAccess.id
          );
          this.noteData.access.splice(deletedAccessIndex, 1);
        });
        this.noteData.access.push(...res.data.insert_note_access.returning);
        this.isUpdatingNote = false;
        this.$emit('noteUpdated', { ...this.noteData });
      } catch (e) {
        this.isUpdatingNote = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating note, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    debounceSave: debounce(function () {
      return this.onSaveClick();
    }, 500),

    setNoteFields() {
      this.title = this.noteData.title;
      this.content = this.noteData.content;
      this.attachments = this.noteData.attachments || [];
      this.assignedMembers = this.noteData.access.map((access) => access.user);
    },
    openAttachment(attachment) {
      if (attachment.url) {
        if (attachment.type === 'image') {
          const img = '<img src="' + attachment.url + '">';
          const win = window.open();
          win.document.write(img);
          win.document.close();
        } else if (attachment.type === 'video') {
          const video = `<video controls>
            <source src="${attachment.url}"></source>
              <p>Your browser doesn't support this video format. Here is
                a <a href="${attachment.url}">link to the video</a> instead.</p>
          </video>`;
          const win = window.open();
          win.document.write(video);
          win.document.close();
        } else {
          const iframe = `
                <iframe src="https://docs.google.com/viewerng/viewer?url=${attachment.url}&hl=en&embedded=true" frameborder="0" webkitallowfullscreen mozallowfullscreen 
     allowfullscreen width="100%" height="100%">
                </iframe>
          `;
          const win = window.open();
          win.document.write(iframe);
          win.document.close();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  height: 100%;
  ::v-deep {
    .ql-toolbar {
      @apply mb-3;
      border-width: 1px 0;
      &.ql-snow {
        & + .ql-container {
          &.ql-snow {
            border-top: 0px solid #ccc;
          }
        }
      }
    }
    .ql-container {
      height: calc(100% - 80px);
      max-height: calc(100% - 80px);
      border-radius: 6px;
      border-width: 0px;
      overflow-y: scroll;
      ul {
        &[data-checked] {
          li {
            &::before {
              @apply text-3xl;
            }
          }
        }
        &[data-checked='true'] {
          li {
            @apply line-through;
            &::before {
              @apply text-green-600;
            }
          }
        }
      }
    }
  }
  &--expanded {
    .ql-container {
    }
  }
}
svg.expanded {
  fill: #fff;
}
</style>
