<template>
  <c-box w="100%">
    <AppSelect
      label="title"
      :reduce="(category) => category.id"
      :options="categories"
      :reset="true"
      :fullWidth="true"
      :bordered="true"
      indicator-fill="orange"
      color="dark"
      :value="value"
      @input="
        (e) => {
          $emit('input', e);
        }
      "
      :borderColor="'light'"
      size="large"
      placeholder="Add Category"
    >
      <template v-slot:list-footer>
        <li>
          <c-pseudo-box
            display="flex"
            px="5"
            py="3"
            @click="openNewCategoryModal"
            as="a"
            cursor="pointer"
          >
            Add New category
          </c-pseudo-box>
        </li>
      </template>
    </AppSelect>
    <c-modal
      :is-open="isNewCategoryOpen"
      :on-close="onModalClose"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Category </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <form @submit.prevent="onCategoryFormSubmit">
          <c-modal-body>
            <c-box>
              <c-input
                size="lg"
                v-model="newCategory"
                placeholder="Category Name"
                minlength="3"
                required
              ></c-input>
            </c-box>
            <c-flex my="2" justify="flex-end">
              <c-button
                :disabled="isAddingCategory"
                type="submit"
                variant="solid"
                variant-color="vc-orange"
              >
                Add
                <c-spinner
                  v-if="isAddingCategory"
                  ml="2"
                  color="#fff"
                  thickness="2px"
                />
              </c-button>
            </c-flex>
          </c-modal-body>
        </form>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { addCategory } from '@/services/timeline';
import AppSelect from '../../components/AppSelect.vue';

export default {
  components: {
    AppSelect,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isNewCategoryOpen: false,
      newCategory: null,
      isAddingCategory: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState('timeline', {
      categories: (state) => state.categories,
    }),
  },
  methods: {
    ...mapMutations({
      addNewCategory: 'timeline/addCategory',
    }),
    openNewCategoryModal() {
      this.isNewCategoryOpen = true;
    },
    onModalClose() {
      this.isNewCategoryOpen = false;
    },
    onCategoryFormSubmit() {
      this.addCategory();
    },
    async addCategory() {
      this.isAddingCategory = true;
      const title = this.newCategory;
      const data = {
        title,
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addCategory(data);
        this.addNewCategory(res.data.insert_calender_category_one);
        this.isAddingCategory = false;
        this.newCategory = false;
        this.onModalClose();
      } catch (e) {
        this.isAddingCategory = false;
        this.$toast({
          title: 'An error occurred.',
          description: e[0].message || `Couldn't add category.]`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
