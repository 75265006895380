<template>
  <c-modal
    :is-open="showStartChatList"
    :on-close="closeUsersList"
    is-centered
    size="lg"
    style="border-radius: 10px"
  >
    <c-modal-content ref="content">
      <c-modal-header>New Chat</c-modal-header>
      <c-modal-close-button />
      <c-modal-body>
        <div class="new_chat">
          <div>
            <UsersLists
              :isSendMessage="true"
              @close="closeUsersList"
              type="newUsers"
              :searchable="true"
              heightId="fixedHeight"
              @messageUser="sendQuickMessage"
            />
          </div>
        </div>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import messagingMixin from '@/mixins/messaging.js';
import UsersLists from './UsersLists.vue';

export default {
  name: 'UsersYouCanMessage',
  mixins: [messagingMixin],
  components: {
    UsersLists,
  },
  data() {
    return {
      showStartChatList: true,
      selectedUser: [],
      btnLoading: false,
    };
  },

  methods: {
    closeUsersList() {
      this.showStartChatList = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.new_chat {
  padding: 0 10px;

  .search_input {
    svg {
      color: var(--color-grey);
    }
  }
}
</style>
