import { gql } from '@apollo/client/core';

export const getMarketPotentialComponentQuery = gql`
  query getMarketPotentialComponentQuery(
    $type: market_potential_component_enum
    $companyId: Int!
  ) {
    market_potential(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      assumption
      assumptionData
      companyId
      createdAt
      description
      id
      otherDescription
      priority
      progress
      type
      updatedAt
      market_potential_assignments {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      market_potential_visibilities {
        marketPotentialId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const subscribeToMarketPotentialComponent = gql`
  subscription subscribeToMarketPotentialComponentQuery(
    $type: market_potential_component_enum
    $companyId: Int!
  ) {
    market_potential(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      assumption
      assumptionData
      companyId
      createdAt
      description
      id
      otherDescription
      priority
      progress
      type
      updatedAt
    }
  }
`;

export const getMarketPotentialCustomersQuery = gql`
  query getMarketPotentialCustomersQuery($id: Int!) {
    market_potential_customer(
      where: { marketPotentialId: { _eq: $id } }
      order_by: { createdAt: asc }
    ) {
      createdAt
      currency
      customerId
      description
      id
      links
      customer {
        type
        name
        id
        category
        revenue_customers {
          revenue {
            businessModelId
            createdAt
            currency
            description
            frequency
            type
            id
            updatedAt
            value
          }
        }
      }
      number
      price
      projectedGrowth
      updatedAt
      marketPotentialId
    }
  }
`;
