<template>
  <div class="auth__wrapper">
    <SiteHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue';
export default {
  components: {
    SiteHeader,
  },
};
</script>

<style lang="scss">
.auth__wrapper {
  @apply relative h-full;
}
div {
  .auth {
    @apply h-full flex pt-20;
    background: #fff;
    &__left {
      @apply hidden;
    }
    &__header {
      @apply w-full flex justify-between items-center mb-4;
      height: 80px;
      &__cta {
        &--orange {
          border-color: $color-orange;
          color: $color-orange;
        }
        &--primary {
          border-color: $color-primary;
          color: $color-primary;
        }
      }
    }
    &__content {
      @apply w-full h-full relative flex px-8;
    }
    &__gradient {
      @apply hidden;
      border-radius: 50%;
      &--orange {
        @apply -ml-32 z-0;
        width: 30em;
        height: 30em;
        background-image: linear-gradient(225deg, #ef8923 0%, #ef5223 100%);
      }
      &--blue {
        @apply -mr-32 z-0 order-1 items-center justify-center pr-28 pl-10;
        width: 35em;
        height: 35em;
        background-image: linear-gradient(225deg, #1a86d0 0%, #103eba 100%);
      }
      &__message {
        font-size: 1.5em;
        max-width: 60%;
      }
    }
    &__form {
      @apply w-full pt-12 pb-14 z-10;
      height: max-content;
      &__title {
        @apply text-3xl mb-2 text-secondary;
        font-weight: 600;
      }
      &__button {
        @apply mt-8 font-semibold;
        color: #fff;
        &--primary {
          background: $color-primary;
          &:hover {
            background: lighten($color-primary, 10%);
          }
        }
        &--orange {
          background: $color-orange;
          &:hover {
            background: lighten($color-orange, 10%);
          }
        }
      }
      &__input {
        border-color: #798892;
        &--success {
          border-color: $color-green;
        }
        &-icon {
          @apply h-full;
          svg {
            width: 1em;
            height: 1em;
          }
        }
        &-toggle {
          @apply text-sm h-full;
          color: $color-orange;
          margin-right: 15px;
        }
      }
      &__social {
        @apply block text-center mt-10;
      }
    }
    @screen lg {
      background: url('~@/assets/img/bg-circle.png') no-repeat, #fff;
      background-size: 90%;
      background-position: -200% 50%;
      &__left {
        @apply flex flex-col items-center justify-center;
        width: 50%;
        background-size: cover;
        background-position: center;
        img {
          width: 80%;
        }
      }
      &__header {
        @apply justify-end;
        & > * {
          &:not(:last-child) {
            @apply mx-6;
          }
        }
      }
      &__content {
        @apply items-center justify-between bg-no-repeat;
        &--left {
          @apply pl-24;
        }
        &--right {
          .auth__form {
            @apply order-2;
          }
        }
      }
      &__form {
        @apply px-10 self-center w-1/2 mt-12;
      }
      &__gradient {
        @apply flex;
      }
    }
    @screen xl {
      background-size: 80%;
      background-position: -60% 50%;
      &__left {
        @apply w-2/5;
      }
      &__form {
        @apply w-1/2;
      }
    }
  }
}
</style>
