const state = () => ({
  businessSectors: [],
  businessObjectives: [],
  businessStages: [],
  departments: [],
  sdgs: [],
  skills: [],
});

const mutations = {
  setBusinessSectors(state, businessSectors) {
    state.businessSectors = businessSectors;
  },
  setBusinessStages(state, businessStages) {
    state.businessStages = businessStages;
  },
  setBusinessObjectives(state, businessObjectives) {
    state.businessObjectives = businessObjectives;
  },
  setDepartments(state, departments) {
    state.departments = departments;
  },
  setSDGs(state, sdgs) {
    state.sdgs = sdgs.sort((a, b) => a.position - b.position);
  },
  setSkills(state, skills) {
    state.skills = skills;
  },
};

const actions = {
  setBusinessConfig(
    { commit },
    {
      business_objective,
      business_sector,
      business_stage,
      team_department,
      sustainable_development_goals,
      skill,
    }
  ) {
    commit('setBusinessSectors', business_sector);
    commit('setBusinessStages', business_stage);
    commit('setBusinessObjectives', business_objective);
    commit('setDepartments', team_department);
    commit('setSDGs', sustainable_development_goals);
    commit('setSkills', skill);
  },
};

const getters = {
  positions: (state) => {
    let positions = [];
    state.departments.forEach((department) => {
      positions = [...positions, ...department.team_positions];
    });
    return positions;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
