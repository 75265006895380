import {
  addNoteMutation,
  addNoteSectionMutation,
  deleteNoteMutation,
  updateNoteMutation,
  deleteNoteSectionMutation,
} from '../graphql/mutations';
import {
  getNoteSectionsQuery,
  subscribeToNoteSectionsQuery,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const addNoteSection = (data) => {
  return apolloInstance.mutate({
    mutation: addNoteSectionMutation,
    variables: { object: data },
  });
};

export const getNoteSections = ({ userId }) => {
  return apolloInstance.query({
    query: getNoteSectionsQuery,
    variables: { userId },
    fetchPolicy: 'network-only',
  });
};

export const subscribeNoteSections = ({ userId }) => {
  return apolloInstance.subscribe({
    query: subscribeToNoteSectionsQuery,
    variables: { userId },
  });
};

export const deleteNoteSection = (id) => {
  return apolloInstance.mutate({
    mutation: deleteNoteSectionMutation,
    variables: { id },
  });
};

export const addNote = (data) => {
  return apolloInstance.mutate({
    mutation: addNoteMutation,
    variables: { object: data },
  });
};

export const updateNote = ({ set, noteId, accessData, accessDeleteData }) => {
  return apolloInstance.mutate({
    mutation: updateNoteMutation,
    variables: { noteId, set, accessData, accessDeleteData },
  });
};

export const deleteNote = (id) => {
  return apolloInstance.mutate({
    mutation: deleteNoteMutation,
    variables: { id },
  });
};
