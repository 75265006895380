<template>
  <c-modal>
    <c-modal-content
        :is-open="isFeelingModalOpen"
        :on-close="closeCreateFeelingModal"
        :is-centered="true"
        size="3xl"
    >
      <c-modal-header>Select Feeling here</c-modal-header>
      <c-modal-body h="600px">
        <div class="modal-contents">
          This is very interesting
          <feeling-emoji-view />
        </div>
      </c-modal-body>
    </c-modal-content>
  </c-modal>
</template>

<script>
import FeelingEmojiView from "@/views/App/Explore/components/FeelingEmojiView.vue";
import {EventBus} from "@/eventBus";

export default {
  components: {FeelingEmojiView},
  props: [],
  data() {
    return {
      feeling: "",
      isFeelingModalOpen: false,
    };
  },
  methods: {
    openCreateFeelingModal(){
      this.isFeelingModalOpen = true
      console.log('Is the diialog beinng triggered ?? ==>');
    },
    closeCreateFeelingModal(){
      this.isFeelingModalOpen = false
      EventBus.$emit('open-post-modal');
    }
  },
  created() {
    EventBus.$on('open-feeling-modal', this.openCreateFeelingModal);
    EventBus.$on('close-feeling-modal', this.closeCreateFeelingModal);
  },

  beforeDestroy() {
    EventBus.$off('open-feeling-modal', this.openCreateFeelingModal);
    EventBus.$off('close-feeling-modal', this.closeCreateFeelingModal);
  },
};
</script>

<style lang="scss" scoped>
.modal-contents {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}
/* Add custom styles for FeelingModal here (optional) */
</style>