import { gql } from '@apollo/client/core';

export const getTourDataQuery = gql`
  query getTourDataQuery {
    tour(order_by: { stage: asc }) {
      createdAt
      descriptions
      id
      stage
      time
      title
      tour_steps(order_by: { position: asc }) {
        createdAt
        description
        descriptions
        id
        offset
        params
        parent
        route
        target
        key
        time
        title
        tourId
        type
        tour_steps(order_by: { position: asc }) {
          createdAt
          description
          descriptions
          id
          offset
          params
          parent
          key
          route
          target
          time
          title
          position
          tourId
          type
          updatedAt
        }
      }
    }
  }
`;
