import http from '@/helpers/api';
import { apolloInstance } from '../vue-apollo';
import { gql } from '@apollo/client/core';
import {
  getCompanyCompletionLevelQuery,
  subscribeToCompanyCompletionLevelQuery,
  getTourDataQuery,
} from '../graphql/queries';

export const uploadFile = ({ file, type }) => {
  const upload = new FormData();
  upload.append('fileType', type);
  upload.append('file', file);
  return http.post(`/helpers/uploadFile`, upload);
};

export const getExplanation = (type) => {
  return apolloInstance.query({
    query: gql`
      query ExplanationQuery($type: String) {
        explanation(where: { type: { _eq: $type } }) {
          content
          createdAt
          id
          mediaUrl
          type
          updatedAt
        }
      }
    `,
    variables: { type },
    fetchpolicy: 'network-only',
  });
};

export const getUrlMetadata = (url) => {
  return http.post(`/helpers/get-metadata`, { url });
};

export const getTourData = () => {
  return apolloInstance.query({
    query: getTourDataQuery,
    // variables: { type },
    fetchpolicy: 'network-only',
  });
};

export const getCompanyCompletionLevel = (companyId) => {
  return apolloInstance.query({
    query: getCompanyCompletionLevelQuery,
    variables: { companyId },
    fetchpolicy: 'no-cache',
  });
};

export const subscribeCompanyCompletionLevel = (companyId) => {
  return apolloInstance.subscribe({
    query: subscribeToCompanyCompletionLevelQuery,
    variables: { companyId },
  });
};
