<template>
  <c-modal
    :is-open="isEventModalOpen"
    :on-close="closeCreateEventModal"
    :is-centered="true"
    size="3xl"
  >
    <c-modal-content ref="content">
      <form @submit.prevent="onEventFormSubmit">
        <c-modal-header>{{ editing ? 'Edit Event' : 'Create Event' }}</c-modal-header>
        <c-modal-close-button @click.prevent="closeCreateEventModal" />
        <c-box class="modal-contents">
          <c-modal-body h="600px">
            <div style="position: relative" v-if="imageUrl || (editing && eventForm.thumb_nail)">
              <c-image
                :src="editing === true ? eventForm.thumb_nail : imageUrl"
                h="350px"
                w="full"
                borderRadius="8px"
                alt="event-thumbnail"
                objectFit="cover"
              >
              </c-image>
              <c-button
                @click="openFileInput"
                style="position: absolute; top: 10px; right: 10px"
                variant-color="vc-orange"
                aria-label="Send email"
              >
                <c-icon name="pen" />
              </c-button>
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                style="display: none"
              />
            </div>
            <c-box
              v-else
              @click="openFileInput"
              style="position: relative"
              h="350px"
              w="100%"
              rounded="lg"
              objectFit="cover"
              alt="back"
              ref="event-banner"
              v-chakra="{
                backgroundColor: '#C7D6F466',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            >
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                style="display: none"
              />
              <c-spinner
                v-if="isUploadingImage"
                color="#fff"
                size="xl"
                thickness="4px"
              />
              <c-flex
                v-else
                flexDirection="column"
                align="center"
                justify="center"
              >
                <c-icon color="gray.500" name="images" size="24" />
                <c-text fontSize="lg" fontWeight="700"
                  >Upload Cover Image</c-text
                >
                <c-text fontSize="md"
                  >Prefered Aspect Ratio is 16:9, minimum width is 500
                  pixels</c-text
                >
              </c-flex>
            </c-box>
            <c-form-control class="form-labels" mt="4">
              <c-form-label color="#002B49">Event Type</c-form-label>
              <c-radio-group
                variant-color="orange"
                value="online"
                v-model="$v.eventForm.type.$model"
              >
                <c-radio value="VIRTUAL">Online</c-radio>
                <c-radio value="PHYSICAL">In-Person</c-radio>
              </c-radio-group>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Event Name</c-form-label>
              <c-input
                v-model="$v.eventForm.name.$model"
                variant="filled"
                placeholder="Event Name"
              />
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                type="text"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.required"
              >
                Event name is required
              </c-text>
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.minLength"
              >
                Event name should be a minimum of 3 characters
              </c-text>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Timezone</c-form-label>
              <c-box h="50px">
                <app-select
                  :reduce="(timez) => timez.value"
                  :full-width="true"
                  :searchable="false"
                  :filled="true"
                  placeholder="Select Timezone for Event"
                  :options="timeZones"
                  v-model="$v.eventForm.time_zone.$model"
                  e
                />
              </c-box>
            </c-form-control>

            <div class="grid-container mt-4">
              <div class="grid-item-event">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.eventForm.start_date.$model"
                >
                  <template v-slot:input>
                    <c-form-control>
                      <c-form-label class="form-labels"
                        >Start date</c-form-label
                      >
                      <c-input
                        style="z-index: 999"
                        size="lg"
                        h="40px"
                        borderRadius="5px"
                        variant="filled"
                        color="gray.400"
                        placeholder="Select start date"
                        :value="startDateFormatted"
                      ></c-input>
                    </c-form-control>
                  </template>
                </AppDatePicker>
              </div>
              <div class="grid-item-event">
                <c-form-control>
                  <c-form-label class="form-labels">Start time</c-form-label>
                  <div style="width: 100%">
                    <v-timepicker
                      input-width="100%"
                      :input-class="['time-picker-style']"
                      autocomplete="on"
                      manual-input
                      format="hh:mm A"
                      v-model="startTimeFormatted"
                    />
                  </div>
                </c-form-control>
              </div>
            </div>
            <div class="grid-container mt-4">
              <div class="grid-item-event">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.eventForm.end_date.$model"
                >
                  <template v-slot:input>
                    <c-form-control>
                      <c-form-label class="form-labels">End date</c-form-label>
                      <c-input
                        size="lg"
                        h="40px"
                        borderRadius="5px"
                        variant="filled"
                        color="gray.400"
                        placeholder="Select start date"
                        v-model="endDateFormatted"
                      ></c-input>
                    </c-form-control>
                  </template>
                </AppDatePicker>
              </div>
              <div class="grid-item-event">
                <c-form-control>
                  <c-form-label class="form-labels">End time</c-form-label>
                  <div style="width: 100%">
                    <v-timepicker
                      input-width="100%"
                      autocomplete="on"
                      manual-input
                      format="hh:mm A"
                      v-model="endTimeFormatted"
                    />
                  </div>
                </c-form-control>
              </div>
            </div>
            <c-form-control v-if="eventForm.type === 'VIRTUAL'" mt="4">
              <c-form-label class="form-labels">Meeting Link</c-form-label>
              <c-input
                v-model="$v.eventForm.meeting_link.$model"
                variant="filled"
                placeholder="Meeting Link"
              />
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="
                  $v.eventForm.meeting_link.$dirty &&
                  !$v.eventForm.meeting_link.minLength
                "
              >
                Event name is required
              </c-text>
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.minLength"
              >
                Event name should be a minimum of 3 characters
              </c-text>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Description</c-form-label>
              <c-textarea
                type="tel"
                h="120px"
                borderRadius="8px"
                variant="filled"
                ref="initialRef"
                v-model="$v.eventForm.description.$model"
                :value="$v.eventForm.description.$model"
                placeholder="Address"
              />
            </c-form-control>

            <c-form-control mt="4">
              <c-form-label class="form-labels">Speakers</c-form-label>
              <!--              <c-button @click.p.prevent="openSpeakerModal" size="xs">Add connections</c-button>-->
              <p style="font-size: 12px; background-color: rgba(128,128,128,0.46); margin-bottom: 10px">For speakers not on this platform</p>
              <c-flex
                  p="3"
                  borderRadius="8px"
                  v-if="eventForm.event_speakers.length > 0"
                  mt="4"
                  mb="4"
                  h="100px"
                  bg="#C7D6F466"
              >
                <c-flex
                    :key="item.id"
                    v-for="item in eventSpeakerNotOnVB"
                    style="margin-right: 10px; background-color: rgba(255,69,0,0.38); height: 25px; border-radius: 3px"
                >
                  <c-text p="3px" fontSize="15px" fontWeight="500" color="vc-orange.600"
                  >{{ item.firstName }} {{ item.lastName }}</c-text
                  >
                  <c-button
                      @click="removeSpeaker(item)"
                      variant="ghost"
                      color="vc-orange.600"
                      right-icon="close"
                      size="xs"
                  />
                </c-flex>
              </c-flex>
              <form @submit.prevent="addSpeaker" class="grid-container">
                <div class="grid-item-event">
                  <c-input
                      v-model="$v.speakerForm.firstName.$model"
                      size="lg"
                      h="40px"
                      type="text"
                      borderRadius="5px"
                      variant="filled"
                      color="gray.400"
                      placeholder="Enter speaker first name"
                  ></c-input>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="
                                      $v.speakerForm.firstName.$dirty &&
                                      !$v.speakerForm.firstName.required
                                    "
                  >
                    First name is required
                  </c-text>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="
                                      $v.speakerForm.firstName.$dirty &&
                                      !$v.speakerForm.firstName.minLength
                                    "
                  >
                    First name should be a minimum of 3 characters
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                      v-model="$v.speakerForm.lastName.$model"
                      size="lg"
                      h="40px"
                      type="text"
                      borderRadius="5px"
                      variant="filled"
                      color="gray.400"
                      placeholder="Enter speaker last name"
                  ></c-input>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="
                                      $v.speakerForm.lastName.$dirty &&
                                      !$v.speakerForm.lastName.required
                                    "
                  >
                    Last name is required
                  </c-text>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="
                                      $v.speakerForm.lastName.$dirty &&
                                      !$v.speakerForm.lastName.minLength
                                    "
                  >
                    Last name should be a minimum of 3 characters
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                      v-model="$v.speakerForm.companyName.$model"
                      size="lg"
                      h="40px"
                      borderRadius="5px"
                      variant="filled"
                      color="gray.400"
                      placeholder="Enter Company Name"
                  ></c-input>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="$v.speakerForm.companyName.$dirty &&!$v.speakerForm.companyName.required"
                  >
                    Speaker Company is required
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                      v-model="$v.speakerForm.position.$model"
                      size="lg"
                      h="40px"
                      borderRadius="5px"
                      variant="filled"
                      color="gray.400"
                      placeholder="Enter Position"
                  ></c-input>
                  <c-text
                      color="vc-orange.400"
                      fontSize="xs"
                      my="1"
                      v-if="$v.speakerForm.position.$dirty &&!$v.speakerForm.position.required"
                  >
                    Position is required
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-button
                      variant-color="vc-orange"
                      type="submit"
                  >
                    Add Speaker
                  </c-button>
                </div>
              </form>
              <p style="font-size: 12px; background-color: rgba(128,128,128,0.46); margin-bottom: 10px; margin-top: 20px">For speakers on this platform</p>
              <div
                style="margin-bottom: 10px"
                v-if="selectedEventSpeakers.length"
              >
                <c-flex
                  justify="space-between"
                  style="
                    margin-top: 10px;
                    background: rgba(255, 77, 0, 0.1);
                    display: flex;
                    color: orangered;
                    padding: 5px;
                    border-radius: 5px;
                    width: 100%;
                  "
                  :key="person.id"
                  v-for="person in selectedEventSpeakers"
                >
                  <div style="display: flex">
                    <img
                      v-if="person.profilePhoto"
                      alt="user-avatar"
                      width="45px"
                      height="45px"
                      :src="person.profilePhoto"
                    />
                    <img
                      v-else
                      alt="user-avatar"
                      width="45px"
                      height="45px"
                      src="@/assets/img/user.jpg"
                    />
                    <div style="display: block; margin-left: 10px">
                      <c-text fontWeight="600" fontSize="17px"
                        >{{ person.firstname }} {{ person.lastname }}</c-text
                      >
                      <span
                        :key="comp.id"
                        v-for="(comp, index) in person.companies"
                        >{{ comp.name
                        }}{{
                          index !== person.companies.length - 1 ? ' | ' : ''
                        }}
                      </span>
                    </div>
                  </div>
                  <c-icon-button
                    ml="5px"
                    aria-label="Remove tagged user"
                    variant="ghost"
                    variant-color="orange"
                    size="xs"
                    icon="close"
                    @click="removeSelectedEventSpeaker(person)"
                  />
                </c-flex>
              </div>
              <div class="search_input">
                <c-input-group>
                  <c-input-left-element>
                    <i class="fas fa-search"></i>
                  </c-input-left-element>
                  <c-input
                    :value="query"
                    @input.native="handleInput"
                    placeholder="Search speakers on vibrant creator..."
                    borderWidth="0"
                    borderRadius="20"
                    size="lg"
                    variant="filled"
                    type="text"
                  />
                </c-input-group>
              </div>
              <c-box class="modal-contents">
                <div style="text-align: center" v-if="loading">
                  <c-spinner ml="2" variant-color="orange" thickness="2px" />
                </div>
                <div v-else>
                  <c-box
                    @click="selected(item)"
                    v-for="item in allUsers"
                    :key="item.id"
                  >
                    <tagged-person-card :person="item" />
                  </c-box>
                </div>
              </c-box>
            </c-form-control>
          </c-modal-body>
        </c-box>
        <c-modal-footer>
          <c-button
            v-if="!editing"
            type="submit"
            variant-color="vc-orange"
            w="100%"
            h="50px"
            :disabled="$v.eventForm.$invalid || isCreatingEvent"
          >
            <c-spinner v-if="isCreatingEvent" color="#fff" thickness="3px" />
            <c-text v-else color="#fff">Save</c-text>
          </c-button>
          <c-button
            v-if="editing"
            @click="updateEventData"
            variant-color="vc-orange"
            w="100%"
            h="50px"
            :disabled="$v.eventForm.$invalid || loading"
            :loading="loading"
          >
            <c-spinner v-if="isCreatingEvent" color="#fff" thickness="3px" />
            <c-text v-else color="#fff">Update</c-text>
          </c-button>
        </c-modal-footer>
      </form>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import AppSelect from '@/views/App/components/AppSelect.vue';
import { EventBus } from '@/eventBus';
import TaggedPersonCard from '@/views/App/Explore/components/tagged/TaggedPersonCard.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { backgroundImage } from 'tailwindcss/lib/plugins';
import { uploadFile } from '@/services/common';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { getMatchingUsers } from '@/services/insight';
import { getUsers } from '@/services/user';
import {updateEvent} from "@/services/event";

const eventTypeLink = {
  'VIRTUAL': 'online',
  'PHYSICAL': 'in-person'
}
export default {
  name: 'EventModal',
  components: {
    AppDatePicker,
    AppSelect,
    TaggedPersonCard,
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),

    ...mapGetters({
      selectedEventSpeakers: 'event/getSelectedEventSpeakers',
    }),

    eventSpeakerNotOnVB() {
      console.log(
        'Spexsing',
        this.eventForm.event_speakers,
        this.eventForm.event_speakers.filter((es) => es.user === null)
      );
      return this.eventForm.event_speakers.filter((es) => !es.user);
    },

    startDateFormatted() {
      return this.$moment(this.eventForm.start_date ?? new Date()).format(
        'YYYY-MM-DD'
      );
    },
    endDateFormatted() {
      return this.$moment(this.eventForm.end_date ?? new Date()).format(
        'YYYY-MM-DD'
      );
    },
    startTimeFormatted() {
      return this.formatTimeString(this.eventForm.start_time)
    },
    endTimeFormatted() {
      return this.formatTimeString(this.eventForm.end_time)
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allUsers: [],
      query: '',
      eventDate: '',
      editing: false,
      loading: false,
      isCreatingEvent: false,
      imageUrl: null,
      isUploadingImage: false,
      eventForm: {
        name: '',
        start_time: '00:00 AM',
        description: '',
        type: 'PHYSICAL',
        created: {},
        end_time: '00:00 PM',
        start_date: this.$moment(new Date()).format('YYYY-MM-DD'),
        end_date: this.$moment(new Date()).format('YYYY-MM-DD'),
        event_speakers: [],
        meeting_link: '',
        time_zone: '',
      },
      speakerForm: {
        firstName: null,
        lastName: null,
        companyName: null,
        position: null,
      },
      showSpeakers: false,
      isEventModalOpen: false,
      timeZones: [
        { label: 'UTC (COORDINATED UNIVERSAL TIME) [GMT+0]', value: 'UTC+0' },
        { label: 'GMT (GREENWICH MEAN TIME) [GMT+0]', value: 'GMT+0' },
        { label: 'CET (CENTRAL EUROPEAN TIME) [GMT+1]', value: 'UTC+1' },
        { label: 'EET (EASTERN EUROPEAN TIME) [GMT+2]', value: 'UTC+2' },
        { label: 'MSK (MOSCOW STANDARD TIME) [GMT+3]', value: 'UTC+3' },
        { label: 'IST (INDIAN STANDARD TIME) [GMT+5:30]', value: 'UTC+5:30' },
        { label: 'WIB (WESTERN INDONESIAN TIME) [GMT+7]', value: 'UTC+7' },
        { label: 'CST (CHINA STANDARD TIME) [GMT+8]', value: 'UTC+8' },
        { label: 'JST (JAPAN STANDARD TIME) [GMT+9]', value: 'UTC+9' },
        {
          label: 'ACST (AUSTRALIAN CENTRAL STANDARD TIME) [GMT+9:30]',
          value: 'UTC+9:30',
        },
        {
          label: 'AEST (AUSTRALIAN EASTERN STANDARD TIME) [GMT+10]',
          value: 'UTC+10',
        },
        { label: 'NZST (NEW ZEALAND STANDARD TIME) [GMT+12]', value: 'UTC+12' },
        { label: 'NST (NOME STANDARD TIME) [GMT-9]', value: 'UTC-9' },
        { label: 'PST (PACIFIC STANDARD TIME) [GMT-8]', value: 'UTC-8' },
        { label: 'MST (MOUNTAIN STANDARD TIME) [GMT-7]', value: 'UTC-7' },
        { label: 'CST (CENTRAL STANDARD TIME) [GMT-6]', value: 'UTC-6' },
        { label: 'EST (EASTERN STANDARD TIME) [GMT-5]', value: 'UTC-5' },
        { label: 'AST (ATLANTIC STANDARD TIME) [GMT-4]', value: 'UTC-4' },
        {
          label: 'NST (NEWFOUNDLAND STANDARD TIME) [GMT-3:30]',
          value: 'UTC-3:30',
        },
        { label: 'BRST (BRASÍLIA SUMMER TIME) [GMT-2]', value: 'UTC-2' },
        { label: 'AZOST (AZORES SUMMER TIME) [GMT-1]', value: 'UTC-1' },
      ],
    };
  },
  validations: {
    eventForm: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      meeting_link: {
        minLength: minLength(3),
      },
      start_time: {
        required,
      },
      end_time: {
        required,
      },
      type: {
        required,
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      time_zone: {
        required,
      },
    },

    speakerForm: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(3),
      },
      companyName: {
        required,
      },
      position: {
        required,
      },
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    formatTimeString(tmString){
      const [hourString, minuteString] = tmString.split(':');
      let hour = parseInt(hourString, 10);
      const minute = minuteString.split(' ')[0];
      const period = hour >= 12 ? 'PM' : 'AM';

      // Convert 24-hour time to 12-hour time, handling the case for '12 PM' and '12 AM'
      hour = hour % 12;
      hour = hour ? hour : 12; // The hour '0' should be '12'

      // Ensure hour is formatted as two digits (if necessary)
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;

      return {
        hh: formattedHour,
        mm: minute,
        A: period
      };
    },
    getEventType(typ){
      return eventTypeLink[typ]
    },
    cleanTime(tmp_time){
      return tmp_time.split(':')
    },
    selected(person) {
      if (!this.selectedEventSpeakers.includes(person)) {
        this.$store.dispatch('event/selectEventSpeaker', {
          ...person,
          firstName: person.firstname,
          lastName: person.lastname
        });
        console.log('selex==>', person)
        this.allUsers = this.allUsers.filter((usr) => usr.id !== person.id);
        console.log('already selected');
      }
    },
    removeSelectedEventSpeaker(person) {
      if(this.editing){
        this.eventForm.event_speakers = this.eventForm.event_speakers.filter(
            (speaker) => speaker.id !== person.id
        );
      }
      if (this.selectedEventSpeakers.includes(person)) {
        this.$store.dispatch('event/removeSelectedEventSpeaker', person);
        this.allUsers.splice(0, 0, person);
      }
    },
    backgroundImage() {
      return backgroundImage;
    },
    async updateEventData() {
      this.loading = true
      this.eventForm.event_speakers.map((e) => {
        delete e.id;
      });

      console.log('Fragerium ==>',this.eventForm)
      const formData = {
        ...this.eventForm,
        companyProfileId: this.user.lastAccessedCompanyId,
        thumb_nail: this.imageUrl,
        eventId: this.eventForm.eventId,
        start_date: this.$moment(this.eventForm.start_date).format(
            'YYYY-MM-DD'
        ),
        end_date: this.$moment(this.eventForm.end_date).format('YYYY-MM-DD'),
      };
      delete(formData.event_speakers)
      delete(formData.posts)
      delete(formData.id)
      delete(formData.creator)
      delete(formData.__typename)

      if (this.eventForm.type === 'VIRTUAL') {
        formData.meeting_link = this.eventForm.meeting_link;
      }

      this.getSelectedEventSpeakers().map((spk) => {
        spk.eventId = this.eventForm.id
      })

      this.eventForm.event_speakers.map((spk) => {
        delete(spk.__typename)
        spk.eventId = this.eventForm.id
      })
      console.log('going==>', this.getSelectedEventSpeakers())
      console.log('goingx==>', this.eventForm.event_speakers)

        updateEvent({
          eventId: this.eventForm.id,
          updateEventObj: formData,
          newSpeaker: [...this.getSelectedEventSpeakers(), ...this.eventSpeakerNotOnVB]
        }).then(() => {
          this.loading = false
          this.isEventModalOpen = false
          this.$toast({
            title: 'You have successfully Updated this event',
            description: `Successfully left an event`,
            status: 'success',
            position: 'top',
            duration: 3000,
          })
        }).catch((e) => {
          this.$toast({
            title: 'There was an error trying to update this event',
            description: `${e}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          })
          this.loading = false
        })
    },

    async onEventFormSubmit() {
      this.eventForm.event_speakers.map((e) => {
        delete e.id;
      });

      this.isCreatingEvent = true;
      const formData = {
        ...this.eventForm,
        event_speakers: {
          data: [...this.getSelectedEventSpeakers(), ...this.eventForm.event_speakers],
        },
        companyProfileId: this.user.lastAccessedCompanyId,
        thumb_nail: this.imageUrl,
        start_date: this.$moment(this.eventForm.start_date).format(
          'YYYY-MM-DD'
        ),
        end_date: this.$moment(this.eventForm.end_date).format('YYYY-MM-DD'),
      };

      if (this.eventForm.type === 'VIRTUAL') {
        formData.meeting_link = this.eventForm.meeting_link;
      }

      try {
        // await createEvent( formData )
        await this.$store.dispatch('event/saveEvent', formData);

        this.isCreatingEvent = false;
        this.closeCreateEventModal();
        await this.$store.dispatch('event/resetSelectedEventSpeakers');

        EventBus.$emit('open-post-modal');
        // this.$toast({
        //   title: 'Event successfully created',
        //   description: `Event has been successfully created`,
        //   status: 'success',
        //   position: 'top',
        //   duration: 3000,
        // });
      } catch (e) {
        console.log(e);
        this.isCreatingEvent = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while saving event`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async getUsersMatchingQuery() {
      const response = await getMatchingUsers('%' + this.query + '%');

      const users = response.data.user;
      //  get only usrs who have firstname and lastname and companies array has data
      // limit to 5 users
      this.allUsers = users.filter(
        (user) => user.firstname && user.lastname && user.companies.length > 0
      );
      this.loading = false;
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        await this.getUsers();
        return;
      }

      // Replace with your actual search logic/API call
      await this.getUsersMatchingQuery();
    }, 400), // 300ms
    handleInput(event) {
      if (event.target) {
        this.query = event.target.value;
        this.search(this.query);
      }
    },
    addSpeaker() {
      if (this.editing) {
        console.log(this.speakerForm);
        this.eventForm.event_speakers.push({
          id: this.generateRandomId(),
          firstName: this.speakerForm.firstName,
          lastName: this.speakerForm.lastName,
          companyName: this.speakerForm.companyName,
          position: this.speakerForm.position,
          eventId: this.eventForm.id
        });
        this.speakerForm = {};
      } else {
        this.eventForm.event_speakers.push({
          id: this.generateRandomId(),
          firstName: this.speakerForm.firstName,
          lastName: this.speakerForm.lastName,
          companyName: this.speakerForm.companyName,
          position: this.speakerForm.position,
        });
        this.speakerForm = {};
      }
    },

    getSelectedEventSpeakers() {
      if(this.editing){
        return this.selectedEventSpeakers.map((speaker) => {
          return {
            userId: speaker.id,
            firstName: speaker.firstname,
            lastName: speaker.lastname,
            eventId: this.eventForm.id
          };
        });
      }
      else{
        return this.selectedEventSpeakers.map((speaker) => {
          return {
            userId: speaker.id,
            firstName: speaker.firstname,
            lastName: speaker.lastname,
          };
        });
      }
    },
    removeSpeaker(item) {
      this.eventForm.event_speakers = this.eventForm.event_speakers.filter(
        (speaker) => speaker.id !== item.id
      );
    },
    async getUsers() {
      try {
        const response = await getUsers(30);

        const users = response.data.user;

        //  get only usrs who have firstname and lastname and companies array has data
        // limit to 5 users
        this.allUsers = users.filter(
          (user) => user.firstname && user.lastname && user.companies.length > 0
        );

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    generateRandomId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    close() {
      this.isEventModalOpen = false;
    },
    openFileInput() {
      // Trigger click event of file input when div is clicked
      this.$refs.fileInput.click();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.isUploadingImage = true;
        this.imageUrl = null;
        try {
          const res = await uploadFile({ file, type: 'image' });
          console.log('image url showing here =>', res);
          this.imageUrl = res.data.url;
          this.isUploadingImage = false;
        } catch (e) {
          console.log(e);
          this.$toast({
            title: 'An error occurred.',
            description: `Error while uploading event banner`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   this.backgroundImage = e.target.result;
        // };
        // reader.readAsDataURL(file);
      }
    },
    // openSpeakerModal(){
    //   EventBus.$emit('open-speakers-modal')
    // },
    openCreateEventModal() {
      this.isEventModalOpen = true;
    },
    closeCreateEventModal() {
      this.isEventModalOpen = false;
      EventBus.$emit('open-post-modal');
    },
  },
  created() {
    EventBus.$on('open-modal', this.openCreateEventModal);
    EventBus.$on('open-event-edit-modal', (data) => {
      this.isEventModalOpen = true
      this.$store.dispatch('event/resetSelectedEventSpeakers')
      this.eventForm = data
      console.log('Spewing==>', this.eventForm)
      this.eventForm.event_speakers.map((ev) => {
        if(ev.user){
          this.selectedEventSpeakers.push(ev.user)
          this.selected(ev.user)
        }
      })
      // this.eventForm.event_speakers = this.eventForm.event_speakers.filter((ev) => !ev.user)
      this.editing = true
    })
    EventBus.$on('close-modal', this.closeCreateEventModal);
  },

  beforeDestroy() {
    EventBus.$off('open-modal', this.openCreateEventModal);
    EventBus.$off('close-modal', this.closeCreateEventModal);
  },
};
</script>

<style lang="scss">
.modal-contents {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.vue__time-picker input.display-time {
  border: 0;
  background-color: #c7d6f466;
  border-radius: 5px;
  height: 46px;
  font-family: Montserrat, serif;
  font-size: 20px;
  width: 100%;
}

.time-picker-style {
  height: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between grid items as needed */
}

.grid-item-event {
  width: 100%; /* Ensure grid items take up full width of their grid cell */
}

.form-labels {
  font-weight: 500;
  color: #002b49;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: orangered;
}


.vc-icon-calendar {
  margin-top: 12px;
}
.vc-icon-clear {
  margin-top: 12px;
}
</style>
