import { gql } from '@apollo/client/core';

export const createPresentationMutation = gql`
  mutation createPresentationMutation($object: presentation_insert_input!) {
    insert_presentation_one(object: $object) {
      createdAt
      data
      id
      themeId
      type
      updatedAt
    }
  }
`;

export const updatePresentationMutation = gql`
  mutation updatePresentationMutation(
    $id: Int!
    $set: presentation_set_input
    $append: presentation_append_input
  ) {
    update_presentation_by_pk(
      pk_columns: { id: $id }
      _set: $set
      _append: $append
    ) {
      id
      type
      data
      title
      themeId
      updatedAt
      createdAt
      companyId
      theme {
        createdAt
        hash
        id
        image
        name
        slug
        type
        updatedAt
      }
    }
  }
`;
