import {
  addCommentToComponentMutation,
  updateUserMutation,
  logPageVisitMutation,
  deleteCommentMutation,
  updateCommentMutation,
  followUserMutation,
  unFollowUserMutation,
} from '../graphql/mutations';
import {
  userQuery,
  getUsersQuery,
  getNotificationsQuery,
  getComponentCommentsQuery,
  subscribeToComponentCommentsQuery,
  getUsersFollowersQuery,
  getUserFollowingQuery,
  getUserFollowersAggregateQuery,
  getPopularUserSuggestionQuery,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const getUser = (id) => {
  return apolloInstance.query({
    query: userQuery,
    fetchPolicy: 'network-only',
    variables: { id },
  });
};

export const getUserFollowersAggregate = (userId) => {
  return apolloInstance.query({
    query: getUserFollowersAggregateQuery,
    fetchPolicy: 'network-only',
    variables: { userId },
  });
};

export const getUsers = (limit) => {
  return apolloInstance.query({
    query: getUsersQuery,
    fetchPolicy: 'network-only',
    variables: { limit },
  });
};

export const updateUser = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateUserMutation,
    variables: { id, set },
  });
};

export const followUser = (userId) => {
  return apolloInstance.mutate({
    mutation: followUserMutation,
    variables: { userId },
  });
};
export const unFollowUser = (userId, followerId) => {
  return apolloInstance.mutate({
    mutation: unFollowUserMutation,
    variables: { userId, followerId },
  });
};

export const getUsersFollowers = ({userId, limit, offset}) => {
  return apolloInstance.query({
    query: getUsersFollowersQuery,
    fetchPolicy: 'network-only',
    variables: { userId, limit, offset },
  });
};

export const getUserFollowing = ({userId, limit, offset}) => {
  return apolloInstance.query({
    query: getUserFollowingQuery,
    fetchPolicy: 'network-only',
    variables: { userId, limit, offset },
  });
};

export const getPopularUserSuggestion = (loggedInUser, limit) => {
  return apolloInstance.query({
    query: getPopularUserSuggestionQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUser, limit },
  });
};

export const getComponentComments = ({ componentId, type }) => {
  return apolloInstance.query({
    query: getComponentCommentsQuery,
    fetchPolicy: 'network-only',
    variables: { componentId, type },
  });
};

export const subscribeToComments = ({ componentId, userId, type }) => {
  return apolloInstance.subscribe({
    query: subscribeToComponentCommentsQuery,
    variables: { componentId, userId, type },
  });
};

export const addCommentToComponent = (data) => {
  return apolloInstance.mutate({
    mutation: addCommentToComponentMutation,
    variables: { object: data },
  });
};

export const deleteComment = (id) => {
  return apolloInstance.mutate({
    mutation: deleteCommentMutation,
    variables: { id },
  });
};

export const updateComment = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCommentMutation,
    variables: { id, set },
  });
};

export const getNotifications = () => {
  return apolloInstance.query({
    query: getNotificationsQuery,
    fetchPolicy: 'network-only',
  });
};

export const logPageVisit = (data) => {
  return apolloInstance.mutate({
    mutation: logPageVisitMutation,
    variables: { object: data },
  });
};
