import {
  addCategoryMutation,
  addMilestoneMutation,
  addPhaseMutation,
  addScenarioMutation,
  updateMilestoneMutation,
  updateMultipleMilestoneMutation,
  updateScenarioMutation,
  updatePhasesMutation,
} from '../graphql/mutations';
import {
  getCategoriesQuery,
  getTimelineDataQuery,
  getMilestoneDataQuery,
  subscribeToMilestoneDataQuery,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const addCategory = (data) => {
  return apolloInstance.mutate({
    mutation: addCategoryMutation,
    variables: { object: data },
  });
};

export const getCategories = (companyId) => {
  return apolloInstance.query({
    query: getCategoriesQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const getTimelineData = (companyId) => {
  return apolloInstance.query({
    query: getTimelineDataQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const getMilestoneData = (companyId) => {
  return apolloInstance.query({
    query: getMilestoneDataQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToMilestoneData = (companyId) => {
  return apolloInstance.subscribe({
    query: subscribeToMilestoneDataQuery,
    variables: { companyId },
  });
};

export const addPhase = (data) => {
  return apolloInstance.mutate({
    mutation: addPhaseMutation,
    variables: { object: data },
  });
};

export const updatePhase = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updatePhasesMutation,
    variables: { id, set },
  });
};

export const addScenario = (data) => {
  return apolloInstance.mutate({
    mutation: addScenarioMutation,
    variables: { object: data },
  });
};

export const updateScenario = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateScenarioMutation,
    variables: { id, set },
  });
};

export const addMilestone = (data) => {
  return apolloInstance.mutate({
    mutation: addMilestoneMutation,
    variables: { object: data },
  });
};

export const updateMilestone = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateMilestoneMutation,
    variables: { id, set },
  });
};

export const updateMultipleMilestone = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateMultipleMilestoneMutation,
    variables: { id, set },
  });
};
