import { gql } from '@apollo/client/core';

export const getRiskAssessmentComponentQuery = gql`
  query getRiskAssessmentComponentQuery($type: String, $companyId: Int!) {
    risk_assessment(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      assumption
      companyId
      createdAt
      description
      id
      priority
      progress
      type
      updatedAt
      risk_assessment_assignments {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_visibilities {
        riskAssessmentId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      risk_assessment_values(order_by: { createdAt: asc }) {
        createdAt
        description
        id
        riskAssessmentId
        title
        type
        updatedAt
      }
      risk_analyses(order_by: { createdAt: asc }) {
        risk_assessment_value {
          id
          riskAssessmentId
          title
          type
          description
          risk_assessment {
            type
            id
          }
        }
        actionPlan
        analysis
        title
        createdAt
        description
        employeeResponsible
        milestoneId
        milestone {
          companyId
          createdAt
          date
          categoryId
          scenarioId
          description
          id
          dateAchieved
          status
          title
          updatedAt
          calender_category {
            title
          }
          scenario {
            id
            color
            title
          }
        }
        id
        riskAssessmentValueId
        team {
          position
          role
          user {
            email
            firstname
            id
            lastname
            isVerified
          }
          userId
          department
        }
      }
    }
  }
`;
