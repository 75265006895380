import { render, staticRenderFns } from "./ChannelDetailsDrawer.vue?vue&type=template&id=db25ca54&scoped=true"
import script from "./ChannelDetailsDrawer.vue?vue&type=script&lang=js"
export * from "./ChannelDetailsDrawer.vue?vue&type=script&lang=js"
import style0 from "./ChannelDetailsDrawer.vue?vue&type=style&index=0&id=db25ca54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db25ca54",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CButton: require('@chakra-ui/vue').CButton, CSpinner: require('@chakra-ui/vue').CSpinner, CBadge: require('@chakra-ui/vue').CBadge, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer, CBox: require('@chakra-ui/vue').CBox})
