<template>
  <div class="select" :class="{ 'select--w-full': fullWidth }">
    <v-select
      :clearable="false"
      :searchable="searchable"
      class="vc-select"
      :value="currentValue"
      :options="options"
      :placeholder="placeholder || 'select'"
      :appendToBody="appendToBody"
      :class="[
        `vc-select--${indicatorFill}-indicator`,
        `vc-select--${color}`,
        `vc-select--${size}`,
        `vc-select--border-${borderColor}`,
        {
          'vc-select--bordered': bordered,
          'vc-select--filled': filled,
          'vc-select--shadow': hasShadow,
          'vc-select--default': reset,
          'vc-select--w-full': fullWidth,
          'vc-select--has-placeholder': placeholder,
          'vc-select--has-value': currentValue,
        },
      ]"
      :disabled="disabled"
      :multiple="multiple"
      :label="label"
      :reduce="reduce"
      v-on="{
        ...$listeners,
        input: onSelected,
      }"
      @open="onSelectOpen"
      v-bind="$attrs"
    >
      <template #option="option">
        <slot name="option" v-bind:option="option"></slot>
      </template>
      <template #selected-option="option">
        <slot name="selected-option" v-bind:option="option"></slot>
      </template>
      <template #no-options="noOptions">
        <slot name="no-options" v-bind:option="noOptions"></slot>
      </template>
      <template #list-footer>
        <slot name="list-footer"></slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: [
    'value',
    'options',
    'size',
    'indicatorFill',
    'color',
    'bordered',
    'filled',
    'reset',
    'fullWidth',
    'hasShadow',
    'disabled',
    'placeholder',
    'borderColor',
    'multiple',
    'label',
    'reduce',
    'appendToBody',
    'searchable',
  ],
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    hasOptionSlot() {
      return !!this.$slots['option'];
    },
  },
  created() {
    this.currentValue = this.value;
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
  },
  methods: {
    onSelected(e) {
      this.$emit('input', e);
    },
    onSelectOpen() {
      this.$nextTick(() => {
        this.$el.querySelector('.vs__search').focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &--w-full {
    @apply w-full;
  }
  .vc-select {
    ::v-deep {
      .vs__open-indicator {
        @apply block;
        fill: $color-orange;
        width: 10px;
        transform: scale(1) translateY(0);
        path {
          d: path(
            'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
          );
          fill: $color-grey;
          transform: translate(-7.757px, -9.343px);
        }
      }
      .vs__dropdown-toggle {
        height: 25px;
        width: max-content;
        font-size: smaller;
        border: none;
        padding: 0;
      }
      .vs__selected {
        color: $color-grey;
        font-weight: bold;
        padding-left: 0px;
        margin-left: 0px;
        margin-top: 0px;
      }
      .vs__selected-options {
        padding-left: 0px;
      }
      .vs__actions {
        padding: 8px 6px 0 3px;
      }
    }
    &.vs--searchable {
      ::v-deep {
        .vs__selected-options {
          flex-wrap: nowrap;
        }
      }
    }
    &:not(.vs--single) {
      ::v-deep {
        .vs__selected {
          padding-left: 10px;
        }
      }
    }
    &--orange-indicator {
      ::v-deep {
        .vs__open-indicator {
          path {
            fill: $color-orange;
          }
        }
      }
    }
    &--default {
      ::v-deep {
        .vs__dropdown-toggle {
          height: 45px;
          padding-inline: 10px;
          input {
            width: 150px;
          }
        }
        .vs__selected {
          font-weight: normal;
        }
        .vs__dropdown-menu {
          width: max-content;
        }
      }
    }
    &--w-full {
      @apply w-full;
      ::v-deep {
        .vs__dropdown-toggle,
        .vs__dropdown-menu {
          @apply w-full;
        }
      }
    }
    &--bordered {
      ::v-deep {
        .vs__dropdown-toggle {
          border: 1px solid #798892;
        }
      }
    }
    &--filled {
      ::v-deep {
        .vs__dropdown-toggle {
          background-color: #C7D6F466;
          height: 40px;
        }
      }
    }
    &--border-light {
      ::v-deep {
        .vs__dropdown-toggle {
          border: 1px solid #e2e8f0;
        }
      }
    }
    &--shadow {
      ::v-deep {
        .vs__dropdown-toggle {
          box-shadow: 1px 1px 6px rgba(186, 186, 186, 0.36);
        }
      }
    }
    &--orange {
      ::v-deep {
        .vs__selected {
          color: $color-orange;
        }
      }
    }
    &--dark {
      ::v-deep {
        .vs__selected {
          color: #2d2d2d;
        }
      }
    }
    &--grey {
      ::v-deep {
        .vs__selected {
          color: $color-grey;
        }
      }
    }
    &--large {
      ::v-deep {
        .vs__selected,
        .vs__search {
          @apply text-xl;
          z-index: 0;
        }
        .vs__dropdown-toggle {
          @apply h-14;
        }
      }
    }
    &--has-placeholder {
      &.vs--unsearchable {
        ::v-deep {
          .vs__selected-options {
            padding-left: 16px;
          }
          .vs__search {
            height: 100%;
            min-width: 100px;
            z-index: 0;
            &::placeholder {
              opacity: 0.5;
            }
          }
        }
      }
    }
    &--has-value {
      &:not(.vs--open) {
        ::v-deep {
          .vs__search {
            display: none;
            z-index: 0;
          }
        }
      }
    }
  }
}
</style>
