import { gql } from '@apollo/client/core';

export const createBusinessMutatation = gql`
  mutation CreateBusinessMutatation($object: company_insert_input!) {
    insert_company_one(object: $object) {
      city
      country
      id
      createdAt
      investmentEta
      name
      objective
      size
      stageId
      updatedAt
      createdBy
      company_sectors {
        sectorId
        companyId
        id
        business_sector {
          name
          id
        }
      }
      business_objective {
        value
        description
      }
      business_stage {
        id
        name
      }
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation updateCompanyMutation($id: Int!, $set: company_set_input) {
    update_company_by_pk(pk_columns: { id: $id }, _set: $set) {
      city
      country
      id
      createdAt
      investmentEtaMetric
      investmentEtaValue
      name
      objective
      size
      stageId
      elevatorPitch
      updatedAt
      logo
      coverImage
      createdBy
      customerSegments
      company_sectors {
        sectorId
        companyId
        id
        business_sector {
          name
          id
        }
      }
      business_objective {
        value
        description
      }
      business_stage {
        id
        name
      }
    }
  }
`;

export const addCompanySectorsMutation = gql`
  mutation addCompanySectorsMutation(
    $objects: [company_sector_insert_input!]!
  ) {
    insert_company_sector(objects: $objects) {
      returning {
        company {
          customerSegments
          company_sectors {
            sectorId
            companyId
            id
            business_sector {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const removeCompanySectorsMutation = gql`
  mutation removeCompanySectorsMutation($ids: [Int!]!, $companyId: Int!) {
    delete_company_sector(
      where: {
        _and: { companyId: { _eq: $companyId }, sectorId: { _in: $ids } }
      }
    ) {
      returning {
        company {
          customerSegments
          company_sectors {
            sectorId
            companyId
            id
            business_sector {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const addTractionMutation = gql`
  mutation addTractionMutation($object: traction_insert_input!) {
    insert_traction_one(object: $object) {
      companyId
      createdAt
      description
      id
      metricType
      title
      tractionType
      tractionValue
      updatedAt
    }
  }
`;

export const updateTractionMutation = gql`
  mutation updateTractionMutation($id: Int!, $set: traction_set_input) {
    update_traction_by_pk(pk_columns: { id: $id }, _set: $set) {
      companyId
      createdAt
      description
      id
      metricType
      title
      tractionType
      tractionValue
      updatedAt
    }
  }
`;

export const updateTeamMemberMutation = gql`
  mutation updateTeamMemberMutation($id: Int!, $set: team_set_input) {
    update_team_by_pk(pk_columns: { id: $id }, _set: $set) {
      isAccepted
      isDeclined
      id
      companyId
      role
      department
      userId
      accessType
      user {
        id
        email
      }
    }
  }
`;

export const deleteTeamMemberMutation = gql`
  mutation deleteTeamMemberMutation($id: Int!) {
    delete_team_by_pk(id: $id) {
      id
    }
  }
`;

export const addActivityLogMutation = gql`
  mutation addActivityLogMutation($object: activity_logs_insert_input!) {
    insert_activity_logs_one(object: $object) {
      userId
      updatedAt
      type
      id
      data
      createdAt
      component
      companyId
      company {
        logo
        name
        customerSegments
        id
        createdBy
        createdAt
        coverImage
        updatedAt
      }
      user {
        firstname
        email
        id
        lastname
        profilePhoto
      }
    }
  }
`;

export const followCompanyMutation = gql`
  mutation followCompany($companyId: Int!) {
    insert_company_follower_one(object: { companyId: $companyId }) {
      companyId
      id
      userId
    }
  }
`;

export const unFollowCompanyMutation = gql`
  mutation unFollowCompany($companyId: Int!, $userId: Int!) {
    delete_company_follower(
      where: { companyId: { _eq: $companyId }, userId: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;


export const recordReferral = gql`
  mutation RecordReferral($companyThatRefers: Int!, $companyReferred: Int!) {
    insert_company_referral(objects: {referralId: $companyThatRefers, referredId: $companyReferred, referredId2: $companyReferred}) {
      affected_rows
    }
  }
`