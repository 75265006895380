import { gql } from '@apollo/client/core';

export const createFoundationByTypeMutation = gql`
  mutation createFoundationTypeMutation($object: foundation_insert_input!) {
    insert_foundation_one(object: $object) {
      assumption
      createdAt
      description
      id
      priority
      progress
      type
      updatedAt
    }
  }
`;

export const updateFoundationTypeMutation = gql`
  mutation updateFoundationTypeMutation(
    $type: String
    $companyId: Int
    $set: foundation_set_input
  ) {
    update_foundation(
      where: {
        foundation_type: { value: { _eq: $type } }
        companyId: { _eq: $companyId }
      }
      _set: $set
    ) {
      affected_rows
      returning {
        assumption
        createdAt
        description
        id
        priority
        progress
        type
        updatedAt
      }
    }
  }
`;

export const addFoundationItem = gql`
  mutation addFoundationItem($object: foundation_item_insert_input!) {
    insert_foundation_item_one(object: $object) {
      id
      label
      description
    }
  }
`;

export const updateFoundationItemMutation = gql`
  mutation updateFoundationItemMutation(
    $id: Int!
    $set: foundation_item_set_input
  ) {
    update_foundation_item_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      label
      description
    }
  }
`;

export const deleteFoundationItemMutation = gql`
  mutation deleteFoundationItemMutation($id: Int!) {
    delete_foundation_item_by_pk(id: $id) {
      id
    }
  }
`;

export const createCompanySdgMutation = gql`
  mutation createCompanySdgMutation($object: company_sdg_insert_input!) {
    insert_company_sdg_one(object: $object) {
      assumption
      companyId
      contribution
      createdAt
      evaluation
      id
      priority
      progress
      company_sdg_values {
        SDGId
        companySDGId
        id
      }
      company_sdg_assignments {
        id
        companySDGId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_sdg_visibilities {
        companySDGId
        id
        createdAt
        userId
        user {
          createdAt
          email
          firstname
          id
          isBlocked
          isDeleted
          isVerified
          lastname
          profilePhoto
          updatedAt
        }
      }
    }
  }
`;

export const updateCompanySdgMutation = gql`
  mutation updateCompanySdgMutation($id: Int!, $set: company_sdg_set_input) {
    update_company_sdg_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      assumption
      companyId
      contribution
      createdAt
      evaluation
      priority
      progress
      updatedAt
      company_sdg_values {
        SDGId
        companySDGId
        id
      }
      company_sdg_assignments {
        id
        companySDGId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_sdg_visibilities {
        companySDGId
        id
        createdAt
        userId
        user {
          createdAt
          email
          firstname
          id
          isBlocked
          isDeleted
          isVerified
          lastname
          profilePhoto
          updatedAt
        }
      }
    }
  }
`;

export const AddCompanySDGValuesMutation = gql`
  mutation AddCompanySDGValuesMutation(
    $objects: [company_sdg_value_insert_input!]!
  ) {
    insert_company_sdg_value(objects: $objects) {
      returning {
        SDGId
        companySDGId
        id
      }
    }
  }
`;

export const removeCompanySDGValuesMutation = gql`
  mutation AddCompanySDGValuesMutation($ids: [Int!]!) {
    delete_company_sdg_value(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;

export const createSkillMutation = gql`
  mutation createSkillMutation($object: skill_insert_input!) {
    insert_skill_one(object: $object) {
      id
      isUserGenerated
      name
      createdAt
      updatedAt
    }
  }
`;

export const createCompanySkillMutation = gql`
  mutation createCompanySkillMutation($object: company_skill_insert_input!) {
    insert_company_skill_one(object: $object) {
      id
      progress
      priority
      createdAt
      updatedAt
      companyId
      assumption
      company_skill_values {
        id
        team_skills {
          id
          companySKVId
        }
        skillBySkill {
          id
          name
          isUserGenerated
        }
      }
      company_skill_assignments {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_skill_visibilities {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const updateCompanySkillMutation = gql`
  mutation updateCompanySkillMutation(
    $id: Int!
    $set: company_skill_set_input
  ) {
    update_company_skill_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      progress
      priority
      createdAt
      updatedAt
      companyId
      assumption
      company_skill_values {
        id
        team_skills {
          id
          teamId
          companySKVId
        }
        skillBySkill {
          id
          name
          isUserGenerated
        }
      }
      company_skill_assignments {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_skill_visibilities {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const addCompanySkillValuesMutation = gql`
  mutation addCompanySkillValuesMutation(
    $objects: [company_skill_values_insert_input!]!
  ) {
    insert_company_skill_values(objects: $objects) {
      returning {
        id
        team_skills {
          id
          teamId
          companySKVId
        }
        skillBySkill {
          id
          name
          isUserGenerated
        }
      }
    }
  }
`;

export const removeCompanySkillValuesMutation = gql`
  mutation removeCompanySkillValuesMutation($ids: [Int!]!) {
    delete_company_skill_values(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;

export const addSkillToMemberMutation = gql`
  mutation addSkillToMemberMutation($object: team_skill_insert_input!) {
    insert_team_skill_one(object: $object) {
      id
      teamId
      companySKVId
      company_skill_value {
        id
        skillBySkill {
          id
          name
          isUserGenerated
        }
      }
    }
  }
`;

export const removeSkillFromMemberMutation = gql`
  mutation removeSkillFromMemberMutation($id: Int!) {
    delete_team_skill_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteCompanyMemberMutation = gql`
  mutation deleteCompanyMemberMutation($id: Int!) {
    delete_team_by_pk(id: $id) {
      id
    }
  }
`;

export const addFoundationAccessMutation = gql`
  mutation addFoundationAccessMutation(
    $object: foundation_assignment_insert_input!
  ) {
    insert_foundation_assignment_one(object: $object) {
      id
      userId
      foundationId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeFoundationAccessMutation = gql`
  mutation removeFoundationAccessMutation($id: Int!) {
    delete_foundation_assignment_by_pk(id: $id) {
      userId
      id
      foundationId
    }
  }
`;

export const addCompanySDGAccessMutation = gql`
  mutation addCompanySDGAccessMutation(
    $object: company_sdg_assignment_insert_input!
  ) {
    insert_company_sdg_assignment_one(object: $object) {
      id
      companySDGId
      userId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeCompanySDGAccessMutation = gql`
  mutation removeCompanySDGAccessMutation($id: Int!) {
    delete_company_sdg_assignment_by_pk(id: $id) {
      companySDGId
      id
      userId
    }
  }
`;

export const addCompanySkillsAccessMutation = gql`
  mutation addCompanySkillsAccessMutation(
    $object: company_skill_assignment_insert_input!
  ) {
    insert_company_skill_assignment_one(object: $object) {
      id
      companySkillId
      userId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeCompanySkillAccessMutation = gql`
  mutation removeCompanySkillAccessMutation($id: Int!) {
    delete_company_skill_assignment_by_pk(id: $id) {
      companySkillId
      id
      userId
    }
  }
`;

export const updateFoundationVisibilityMutation = gql`
  mutation updateFoundationVisibilityMutation(
    $addVisibility: [foundation_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_foundation_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        foundationId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_foundation_visibility(where: { id: { _in: $removedVisibilities } }) {
      returning {
        id
        foundationId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const updateSDGVisibilityMutation = gql`
  mutation updateSDGVisibilityMutation(
    $addVisibility: [company_sdg_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_company_sdg_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        companySDGId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_company_sdg_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        companySDGId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const updateSkillsVisibilityMutation = gql`
  mutation updateSkillsVisibilityMutation(
    $addVisibility: [company_skill_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_company_skill_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        companySkillId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_company_skill_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        companySkillId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;
