import {removeDuplicatesById} from "@/helpers/utils";
import uniq from 'lodash/uniq'

const state = () => ({
    search_keyword: '',
    searchResults: [],
    recentSearches: [],
    recentTriedSearch: []
})


const actions = {
    saveSearchResult( { commit }, searchResultData ){
        commit( 'setSearchResults', searchResultData )
    },

    saveRecentSearches( { commit }, searchedData ){
        commit( 'setRecentSearches', searchedData )
    },

    saveRecentTriedSearches( {commit}, triedSearch ){
        commit( 'setRecentTriedSearch', triedSearch )
    },

    clearRecentSearches( { commit } ){
        commit( 'setClearRecentSearches')
    }
}

const mutations = {
    setSearchResults( state, payload ){
        state.searchResults = payload
    },

    setClearRecentSearches( state ){
        state.recentSearches = []
    },

    setRecentSearches( state, payload ){
        state.recentSearches.push( payload )
        if (state.recentSearches.length > 20) {
            state.recentSearches.shift(); // Remove the oldest item
        }
    },

    setRecentTriedSearch( state, payload ){
        state.recentTriedSearch.push( payload )
        if (state.recentTriedSearch.length > 10) {
            state.recentTriedSearch.shift(); // Remove the oldest item
        }
    }
}

const getters = {
    getSearchResults: (state) => state.searchResults,

    getRecentSearches: (state) => removeDuplicatesById(state.recentSearches),

    getRecentTriedSearches: (state) => uniq(state.recentTriedSearch)
}


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}