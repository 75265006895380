<template>
  <div>
    <div style="text-align: center" v-if="loading">
      <c-spinner ml="2" color="orange" thickness="2px" />
    </div>
    <div v-if="hasChannels" class="channel_container">
      <div
        :class="
          currentChannelID === chan.id
            ? 'activeChannel channelLists'
            : 'channelLists'
        "
        v-for="(chan, index) in userJoinedChannels"
        :key="index"
        @click="onSelectedChannel(chan)"
      >
        <img :src="chan.logoUrl" alt="" v-if="chan.logoUrl" width="40px" />
        <span class="first_letter" v-else>{{ getFirstLetter(chan.name) }}</span>
        <c-box ml="2">
          <c-text font-weight="bold" class="cha_name">
            {{ chan.name }}
          </c-text>
          <div class="chan_details">
            <p>
              <c-badge mx="2" variant-color="green">{{
                chan.participants.length
              }}</c-badge
              >participants
            </p>
            <span class="cus_badge" v-if="chan.unReadMessage > 0">
              {{ chan.unReadMessage }}
            </span>
          </div>
        </c-box>
        <img
          src="@/assets/img/shield.png"
          alt=""
          width="20px"
          v-if="chan.type == 'CLOSE'"
        />
      </div>
      <!-- <infinite-loading @infinite="getMoreChannels">
        <span slot="no-more">
          <b></b>
        </span>
        <span slot="no-results">
          <b></b>
        </span>
      </infinite-loading> -->
    </div>

    <div v-else class="mt-5" style="text-align: center">
      <c-text style="font-size: 11px">No channels joined or created</c-text>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';
import {
  getJoinedChannelList,
  markChannelMessageAsRead,
  getUnreadMessagesInChannel,
  subscribeToJoinedChannelList,
} from '@/services/messages.js';

export default {
  name: 'JoinedChannelsList',
  data() {
    return {
      loading: true,
      userJoinedChannels: [],
      currentChannelID: 0,
      messageOffset: 0,
      messagesLimit: 50,
    };
  },
  // created() {
  //   this.openUserChannelsList();
  // },
  mounted() {
    this.subscribeToChannelMessagesList();
  },
  computed: {
    hasChannels() {
      return this.userJoinedChannels.length > 0;
    },
  },
  methods: {
    ...mapActions({
      selectedChannelChat: 'messaging/selectedChannelChat',
      addMessageNotification: 'messaging/addMessageNotification',
      clearMessageNotification: 'messaging/clearMessageNotification',
    }),
    subscribeToChannelMessagesList() {
      const loggedInUserId = store.state.auth.user.id;
      subscribeToJoinedChannelList(loggedInUserId).subscribe({
        next: (res) => {
          const userChannels = res.data.chat_channel;
          this.formatChannelList(userChannels, 'subscribe');
        },
        error(error) {
          console.log('error with sub');
          this.loading = false;
          console.error(error);
        },
      });

      setTimeout(async () => {
        if (this.loading === true) {
          this.openUserChannelsList();
        }
      }, 10000);
    },
    async openUserChannelsList() {
      const loggedInUser = store.state.auth.user.id;

      try {
        const response = await getJoinedChannelList(
          loggedInUser,
          this.messagesLimit,
          this.messageOffset
        );
        const userChannels = response.data.chat_channel;

        if (Object.keys(userChannels).length === 0) {
          this.loading = false;
        } else {
          this.formatChannelList(userChannels, 'history');
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    // async getMoreChannels() {
    //   const loggedInUser = store.state.auth.user.id;
    //   this.messageOffset = this.messageOffset + this.messagesLimit;

    //   try {
    //     const response = await getJoinedChannelList(
    //       loggedInUser,
    //       this.messagesLimit,
    //       this.messageOffset
    //     );
    //     const userChannels = response.data.chat_channel;

    //     this.formatChannelList(userChannels, 'history');
    //   } catch (error) {
    //     this.loading = false;
    //     console.log(error);
    //   }
    // },
    async formatChannelList(userChannels, type) {
      const loggedInUser = store.state.auth.user.id;
      const response = await getUnreadMessagesInChannel(loggedInUser);
      const channelUnreadMessages = response.data.channel_unread_message;
      if (userChannels.length > 0) {
        const formattedChannels = userChannels.map((channel) => {
          return {
            ...channel,
            lastMessageObject: channel.messages[0],
            unReadMessage: channelUnreadMessages.find(
              (unread) => unread.channel_id === channel.id
            ).noOfUnread,
            messageTotal: channel.messages_aggregate.aggregate.count,
          };
        });

        this.loading = false;
        this.userJoinedChannels = formattedChannels;
        this.$emit('getTotal', formattedChannels.length);

        if (type === 'history') {
          this.onSelectedChannel(formattedChannels[0]);
        }
      } else {
        this.loading = false;
      }
    },
    async onSelectedChannel(chan) {
      const user = {
        name: chan.name,
        id: chan.id,
        img: chan.logoUrl,
        isChannel: true,
        messageTotal: chan.messageTotal,
      };
      console.log(chan.type);
      const noMessageYet = chan.lastMessageObject === undefined ? true : false;

      const channelId = chan.id;
      const loggedInUserId = store.state.auth.user.id;

      if (chan.unReadMessage > 0 && !noMessageYet) {
        const lastMessageReadId = chan.lastMessageObject.id;

        await markChannelMessageAsRead({
          channelId,
          loggedInUserId,
          lastMessageReadId,
        });
      }

      if (this.currentChannelID !== chan.id) {
        this.currentChannelID = chan.id;
        this.selectedChannelChat(user);

        if (
          this.$route.name == 'UserMessaging' ||
          this.$route.name == 'ChannelMessaging' ||
          this.$route.name == 'messaging' ||
          this.$route.name == 'CreateNewChannel'
        ) {
          this.goToMessageRoute(user);
        } else {
          this.$emit('sendFooterMessage', user);
        }
      }
    },
    goToMessageRoute(chan) {
      const targetRoute = {
        name: 'ChannelMessaging',
        query: { channel: chan.name + chan.id },
      };

      if (
        this.$route.path !== targetRoute.path ||
        JSON.stringify(this.$route.query) !== JSON.stringify(targetRoute.query)
      ) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    getFirstLetter(name) {
      if (name) {
        return name.charAt(0).toUpperCase();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.channel_container {
  width: 100%;
  .channelLists {
    display: flex;
    align-items: center;
    margin-left: 2px;
    margin-top: 5px;
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid rgb(226, 224, 224);

    &:hover {
      background: #e4eaf5;
      border-radius: 3px;
    }
    .first_letter {
      font-size: 20px;
      font-weight: 600;
      background-color: #e7592e;
      min-width: 45px;
      min-height: 40px;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .chan_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;
    }

    .cha_name {
      font-weight: 600;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      display: block;
    }
  }
  .activeChannel {
    background: #e4eaf5;
    border-radius: 3px;
  }
}
</style>
