import {
  addMarketPotentialAccessMutation,
  addMarketPotentialCustomerMutation,
  createMarketPotentialComponentMutation,
  deleteMarketPotentialCustomerMutation,
  removeMarketPotentialAccessMutation,
  updateMarketPotentialComponentMutation,
  updateMarketPotentialCustomerMutation,
  updateMarketPotentialVisibilityMutation,
} from '../graphql/mutations';
import {
  getMarketPotentialComponentQuery,
  getMarketPotentialCustomersQuery,
  subscribeToMarketPotentialComponent,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const createMarketPotentialComponent = (data) => {
  return apolloInstance.mutate({
    mutation: createMarketPotentialComponentMutation,
    variables: { object: data },
  });
};

export const getMarketPotentialComponent = ({ type, companyId }) => {
  return apolloInstance.query({
    query: getMarketPotentialComponentQuery,
    variables: { type, companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToMarketPotential = ({ type, companyId }) => {
  return apolloInstance.subscribe({
    query: subscribeToMarketPotentialComponent,
    variables: { type, companyId },
  });
};

export const updateMarketPotentialComponent = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateMarketPotentialComponentMutation,
    variables: { id, set },
  });
};

export const addMarketPotentialComponentAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addMarketPotentialAccessMutation,
    variables: { object: data },
  });
};

export const removeMarketPotentialComponentAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeMarketPotentialAccessMutation,
    variables: { id },
  });
};

export const updateMarketPotentialComponentVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateMarketPotentialVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const addMarketPotentialCustomer = (data) => {
  return apolloInstance.mutate({
    mutation: addMarketPotentialCustomerMutation,
    variables: { object: data },
  });
};

export const deleteMarketPotentialCustomer = (id) => {
  return apolloInstance.mutate({
    mutation: deleteMarketPotentialCustomerMutation,
    variables: { id },
  });
};

export const getMarketPotentialCustomers = (id) => {
  return apolloInstance.query({
    query: getMarketPotentialCustomersQuery,
    variables: { id },
    fetchPolicy: 'no-cache',
  });
};

export const updateMarketPotentialCustomer = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateMarketPotentialCustomerMutation,
    variables: { id, set },
  });
};
