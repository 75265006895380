import { apolloInstance } from '../vue-apollo';
import {
  createChannelMutation,
  joinChannelMutation,
  writeChatMutation,
  sendChannelMessageMutation,
  leaveChannelMutation,
  markMessageAsReadMutation,
  markMessageAsReadInChannelMutation,
  deleteMessageChannelMutation,
} from '../graphql/mutations';
import {
  getAvailableOpenChannelsQuery,
  getJoinedChannelListQuery,
  getChannelMessagesQuery,
  subscribeToChannelMessagesQuery,
  getUserChatListQuery,
  getOneOnOneMessagesQuery,
  subscribeToOneOnOneMessagesQuery,
  subscribeUserChatListQuery,
  getChannelDetailsQuery,
  getUnreadMessageCountInChannel,
  subscribeToJoinedChannelListQuery,
  subscribeToUnreadMessages,
} from '../graphql/queries';

export const getChannelDetails = (channelId) => {
  return apolloInstance.query({
    query: getChannelDetailsQuery,
    fetchPolicy: 'network-only',
    variables: { channelId },
  });
};
export const createChannel = (data) => {
  return apolloInstance.mutate({
    mutation: createChannelMutation,
    variables: { objects: data },
  });
};

export const deleteAChatChannel = (channel_id) => {
  return apolloInstance.mutate({
    mutation: deleteMessageChannelMutation,
    variables: { channel_id },
  });
};

export const joinAChannel = (data) => {
  return apolloInstance.mutate({
    mutation: joinChannelMutation,
    variables: { object: data },
  });
};

export const leaveAChannel = (loggedInUserId, channelId) => {
  return apolloInstance.mutate({
    mutation: leaveChannelMutation,
    variables: { loggedInUserId, channelId },
  });
};

export const sendChat = (data) => {
  return apolloInstance.mutate({
    mutation: writeChatMutation,
    variables: { object: data },
  });
};

export const sendChannelMessage = (data) => {
  return apolloInstance.mutate({
    mutation: sendChannelMessageMutation,
    variables: { object: data },
  });
};

export const getOpenChannels = (loggedInUser, limit) => {
  return apolloInstance.query({
    query: getAvailableOpenChannelsQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUser, limit },
  });
};

export const getJoinedChannelList = (loggedInUserId, limit, offset) => {
  return apolloInstance.query({
    query: getJoinedChannelListQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUserId, limit, offset },
  });
};

export const subscribeToJoinedChannelList = (loggedInUserId) => {
  return apolloInstance.subscribe({
    query: subscribeToJoinedChannelListQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUserId },
  });
};

export const getChannelMessages = (channelId, limit, offset) => {
  return apolloInstance.query({
    query: getChannelMessagesQuery,
    fetchPolicy: 'network-only',
    variables: { channelId, limit, offset },
  });
};

export const subscribeToChannelMessages = (channelId) => {
  return apolloInstance.subscribe({
    query: subscribeToChannelMessagesQuery,
    variables: { channelId },
  });
};

export const subscribeToChatMessageList = (loggedInUserId) => {
  return apolloInstance.subscribe({
    query: subscribeUserChatListQuery,
    variables: { loggedInUserId },
  });
};

export const subscribeToChatMessages = ({
  loggedInUser,
  profileUserId,
  limit,
  offset,
}) => {
  return apolloInstance.subscribe({
    query: subscribeToOneOnOneMessagesQuery,
    variables: { loggedInUser, profileUserId, limit, offset },
  });
};

export const getUserChatList = (loggedInUserId) => {
  return apolloInstance.query({
    query: getUserChatListQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUserId },
  });
};

export const getOneOnOneMessages = ({
  loggedInUser,
  profileUserId,
  limit,
  offset,
}) => {
  return apolloInstance.query({
    query: getOneOnOneMessagesQuery,
    fetchPolicy: 'network-only',
    variables: { loggedInUser, profileUserId, limit, offset },
  });
};

export const markMessageAsRead = ({ lastMessageId, senderId, recieverId }) => {
  return apolloInstance.mutate({
    mutation: markMessageAsReadMutation,
    variables: { lastMessageId, senderId, recieverId },
  });
};

export const markChannelMessageAsRead = ({
  channelId,
  loggedInUserId,
  lastMessageReadId,
}) => {
  return apolloInstance.mutate({
    mutation: markMessageAsReadInChannelMutation,
    variables: { channelId, loggedInUserId, lastMessageReadId },
  });
};

export const getUnreadMessagesInChannel = (loggedInUserId) => {
  return apolloInstance.query({
    query: getUnreadMessageCountInChannel,
    fetchPolicy: 'network-only',
    variables: { loggedInUserId },
  });
};

export const subscribeUnreadMessages = (userId) => {
  return apolloInstance.subscribe({
    query: subscribeToUnreadMessages,
    variables: { userId },
  });
};
