import { gql } from '@apollo/client/core';

export const createCompetitionComponentMutation = gql`
  mutation createCompetitionComponentMutation(
    $object: competition_insert_input!
  ) {
    insert_competition_one(object: $object) {
      id
      type
      priority
      progress
      createdAt
      updatedAt
      companyId
      assumption
      description
      hiddenFields
      competition_assignments {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      competition_visibilities {
        id
        competitionId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const updateCompetitionComponentMutation = gql`
  mutation updateCompetitionComponentMutation(
    $type: competition_component_enum!
    $companyId: Int!
    $set: competition_set_input
  ) {
    update_competition(
      where: { _and: { companyId: { _eq: $companyId }, type: { _eq: $type } } }
      _set: $set
    ) {
      returning {
        id
        type
        priority
        progress
        createdAt
        updatedAt
        companyId
        assumption
        assumptionData
        description
        hiddenFields
        competition_assignments {
          id
          competitionId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
        competition_visibilities {
          id
          competitionId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
      }
    }
  }
`;

export const addCompetitionComponentAccessMutation = gql`
  mutation addCompetitionComponentAccessMutation(
    $object: competition_assignment_insert_input!
  ) {
    insert_competition_assignment_one(object: $object) {
      id
      userId
      competitionId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const removeCompetitionComponentAccessMutation = gql`
  mutation removeCompetitionComponentAccessMutation($id: Int!) {
    delete_competition_assignment_by_pk(id: $id) {
      userId
      id
      competitionId
      user {
        firstname
        id
        email
        createdAt
        isVerified
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const updateCompetitionComponentVisibilityMutation = gql`
  mutation updateCompetitionComponentVisibilityMutation(
    $addVisibility: [competition_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_competition_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        competitionId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_competition_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        competitionId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const addCompetitorMutation = gql`
  mutation addCompetitorMutation($object: competitor_insert_input!) {
    insert_competitor_one(object: $object) {
      competitor_customers {
        competitorId
        customerId
        id
        customer {
          name
          id
          type
          category
        }
      }
      createdAt
      customerExperience
      details
      id
      importanceLevel
      name
      pointOfDifferentiation
      isSelf
      revenueStreams
      type
      valueProposition
      website
      price
      keyTakeaway
      strengths
      socialMediaEngagement
      weaknesses
    }
  }
`;

export const updateCompetitorMutation = gql`
  mutation updateCompetitorMutation($id: Int!, $set: competitor_set_input) {
    update_competitor_by_pk(pk_columns: { id: $id }, _set: $set) {
      competitor_customers {
        competitorId
        customerId
        id
        customer {
          name
          id
          type
          category
        }
      }
      createdAt
      customerExperience
      details
      id
      importanceLevel
      name
      pointOfDifferentiation
      revenueStreams
      type
      isSelf
      valueProposition
      website
      price
      keyTakeaway
      strengths
      socialMediaEngagement
      weaknesses
    }
  }
`;

export const addCompetitorCustomersMutation = gql`
  mutation addCompetitorCustomersMutation(
    $objects: [competitor_customer_insert_input!]!
  ) {
    insert_competitor_customer(objects: $objects) {
      returning {
        id
        customerId
        competitorId
        customer {
          name
          id
          type
          category
        }
      }
    }
  }
`;

export const removeCompetitorCustomersMutation = gql`
  mutation removeCompetitorCustomersMutation($ids: [Int!]) {
    delete_competitor_customer(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;
