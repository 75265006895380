export const sdgs = [
  { title: 'No Poverty', fileName: 'no-poverty.svg' },
  {
    title: 'Zero Hunger',
    fileName: 'zero-hunger.svg',
  },
  {
    title: 'Good Health and Wellbeing',
    fileName: 'good-health.svg',
  },
  {
    title: 'Quality Education',
    fileName: 'quality-education.svg',
  },
  {
    title: 'Gender Equality',
    fileName: 'gender-equality.svg',
  },
  {
    title: 'Clean Water and Sanitation',
    fileName: 'clean-water.svg',
  },
  {
    title: 'Affordable and Clean Energy',
    fileName: 'clean-energy.svg',
  },
  {
    title: 'Decent Work and Economic Growth',
    fileName: 'decent-work.svg',
  },
  {
    title: 'Industry, Innovation and Infrastructure',
    fileName: 'industry-innovation.svg',
  },
  {
    title: 'Reduced Inequalities',
    fileName: 'reduced-inequalities.svg',
  },
  {
    title: 'Sustainable Cities and Communites',
    fileName: 'sustainable-cities.svg',
  },
  {
    title: 'Responsible Consumption and Production',
    fileName: 'responsible-consumption.svg',
  },
  {
    title: 'Climate Action',
    fileName: 'climate-action.svg',
  },
  {
    title: 'Life Below Water',
    fileName: 'life-below-water.svg',
  },
  {
    title: 'Life On Land',
    fileName: 'life-on-land.svg',
  },
  {
    title: 'Peace, Justice and Strong Institutions',
    fileName: 'peace-justice.svg',
  },
  {
    title: 'Partnerships for The Goals',
    fileName: 'partnership.svg',
  },
];

export const growthFrameworkTourData = {
  stage1: {
    title: 'Idea',
    time: '10 mins',
    descriptions: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
    ],
    steps: [
      {
        target: '#overall-problem',
        type: 'ELEMENT',
        route: '/business-model',
        title: 'Problem',
        time: '5 mins',
        descriptions: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
        ],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        params: {
          placement: 'right-start',
          scrollTop: 'end',
        },
      },
      {
        target: '#overall-solution',
        type: 'ELEMENT',
        route: '/business-model',
        title: 'Solution',
        time: '5 mins',
        descriptions: ['Your idea', 'How is you idea solving the problem'],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        params: {
          placement: 'right-start',
          scrollTop: 'end',
        },
      },
      {
        target: '#target-audience',
        type: 'ELEMENT',
        route: '/business-model',
        title: 'Target Audience',
        time: '5 mins',
        descriptions: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
        ],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        offset: -350,
        params: {
          placement: 'top',
          scrollTop: 'end',
        },
      },
      {
        target: '#company_skill',
        type: 'ELEMENT',
        route: '/foundation',
        title: 'Skills and Competence you need',
        time: '5 mins',
        descriptions: [
          'What skills and competency do you need to achieve your vision and purpose?',
        ],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        offset: -250,
        params: {
          placement: 'top',
          scrollTop: 'end',
        },
      },
      {
        target: '#team',
        type: 'ELEMENT',
        route: '/foundation',
        title: 'Team',
        time: '5 mins',
        descriptions: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
        ],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        offset: -250,
        params: {
          placement: 'top',
          scrollTop: 'end',
        },
        hideProgress: true,
      },
      {
        target: '#elevator-pitch',
        type: 'ELEMENT',
        route: '/overview',
        title: 'Elevator Pitch',
        time: '5 mins',
        descriptions: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
        ],
        tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
        params: {
          placement: 'top',
          scrollTop: 'end',
        },
        hideProgress: true,
      },
    ],
  },
  stage2: {
    title: 'Business Ready',
    time: '10mins',
    descriptions: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
      'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
    ],
    steps: [
      {
        target: '#foundation',
        type: 'PAGE',
        route: '/foundation',
        title: 'Foundation',
        time: '5 mins',
        descriptions: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
          'Lorem ipsum dolor sit amet, consectetur adipiscing consectetur adipiscing',
        ],
        steps: [
          {
            target: '#vision',
            type: 'ELEMENT',
            title: 'Vision',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            params: {
              placement: 'right-start',
              scrollTop: 'end',
            },
          },
          {
            target: '#belief',
            type: 'ELEMENT',
            title: 'Belief',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            params: {
              placement: 'right-start',
              scrollTop: 'end',
            },
          },
          {
            target: '#values',
            type: 'ELEMENT',
            title: 'Values',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
          {
            target: '#culture',
            type: 'ELEMENT',
            title: 'Culture',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
          {
            target: '#company_sdg',
            type: 'ELEMENT',
            title: 'Sustainable Development Goals',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            offset: -350,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
          {
            target: '#company_skill',
            type: 'ELEMENT',
            title: 'Skills and Competence You need',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            offset: -350,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
          {
            target: '#team',
            type: 'ELEMENT',
            title: 'Team',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            offset: -250,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
          {
            target: '#advisory-board',
            type: 'ELEMENT',
            title: 'Advisory Board',
            route: '/foundation',
            time: '00:35mins',
            tourDescription: `Example: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`,
            offset: -300,
            params: {
              placement: 'top',
              scrollTop: 'end',
            },
          },
        ],
      },
    ],
  },
};

let eventGuid = 0;
const today = new Date();
const todayStr = today.toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'Meetings with the Client',
    type: 'event',
    start: todayStr + 'T12:00:00',
    end: todayStr + 'T17:00:00',
  },
  {
    id: createEventId(),
    title: 'Another Meeting with the Client',
    type: 'event',
    start: todayStr + 'T12:00:00',
    end: todayStr + 'T18:00:00',
  },
  {
    id: createEventId(),
    title: 'More Meetings with the Client',
    type: 'event',
    start: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString(),
    end: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
      .toISOString()
      .replace(/T.*$/, 'T17:00:00'),
  },
  {
    id: createEventId(),
    title: 'Find People to fit in the team',
    type: 'milestone',
    milestoneData: {
      scenario: 'Success Scenario',
      isAcheived: false,
    },
    date: new Date(Date.now() + 8 * 24 * 60 * 60 * 1000).toISOString(),
  },
  {
    id: createEventId(),
    title: 'Find People to fit in another the team',
    type: 'milestone',
    milestoneData: {
      scenario: 'Success Scenario',
      isAcheived: true,
    },
    start: new Date(Date.now() + 9 * 24 * 60 * 60 * 1000).toISOString(),
  },
  {
    id: createEventId(),
    title: 'First Phase',
    start: todayStr,
    type: 'phase',
    end: new Date(Date.now() + 4 * 24 * 60 * 60 * 1000),
  },
];

export function createEventId() {
  return String(eventGuid++);
}

export const generateID = (length) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const revenueTypes = [
  { label: 'Asset Sale', value: 'ASSET_SALE' },
  { label: 'Usage Fee', value: 'USAGE_FEE' },
  { label: 'Subscription Fee', value: 'SUBSCRIPTION_FEE' },
  {
    label: 'Lending - Renting - Leasing',
    value: 'LENDING_RENTING_LEASING',
  },
  { label: 'Licensing', value: 'LICENSING' },
  { label: 'Brokerage Fee', value: 'BROKERAGE_FEE' },
  { label: 'Advertising', value: 'ADVERTISING' },
];

export const subscriptionAccess = {
  free: {
    vision: true,
    businessSectors: true,
    belief: true,
    businessStage: true,
    customerSegment: true,
    values: true,
    culture: true,
    marketGoals: true,
    sdgs: true,
    traction: true,
    skills: true,
    team: true,
    overallProblem: true,
    overallSolution: true,
    overallValueProposition: true,
    bmCustomerSegment: true,
    channels: false,
    keyResources: false,
    keyPartners: false,
    keyActivities: false,
    revenue: true,
    costs: true,
    pointOfDifferentiation: false,
    competition: false,
    comparison: false,
    marketStrategy: false,
    marketPotentialOverview: true,
    sam: false,
    tam: false,
    som: false,
    swot: false,
    riskAnalysis: false,
    scenarios: false,
    presentation: false,
    maxTeam: 2,
    maxAdvisory: 5,
  },
  pro: {
    vision: true,
    businessSectors: true,
    belief: true,
    businessStage: true,
    customerSegment: true,
    values: true,
    culture: true,
    marketGoals: true,
    sdgs: true,
    traction: true,
    skills: true,
    team: true,
    overallProblem: true,
    overallSolution: true,
    overallValueProposition: true,
    bmCustomerSegment: true,
    channels: true,
    keyResources: true,
    keyPartners: true,
    keyActivities: true,
    revenue: true,
    costs: true,
    pointOfDifferentiation: true,
    competition: true,
    comparison: true,
    marketStrategy: true,
    marketPotentialOverview: true,
    sam: true,
    tam: true,
    som: true,
    swot: true,
    riskAnalysis: true,
    scenarios: true,
    presentation: true,
    maxTeam: 5,
    maxAdvisory: 5,
  },
  premium: {
    vision: true,
    businessSectors: true,
    belief: true,
    businessStage: true,
    customerSegment: true,
    values: true,
    culture: true,
    marketGoals: true,
    sdgs: true,
    traction: true,
    skills: true,
    team: true,
    overallProblem: true,
    overallSolution: true,
    overallValueProposition: true,
    bmCustomerSegment: true,
    channels: true,
    keyResources: true,
    keyPartners: true,
    keyActivities: true,
    revenue: true,
    costs: true,
    pointOfDifferentiation: true,
    competition: true,
    comparison: true,
    marketStrategy: true,
    marketPotentialOverview: true,
    sam: true,
    tam: true,
    som: true,
    swot: true,
    riskAnalysis: true,
    scenarios: true,
    presentation: true,
    maxTeam: 10,
    maxAdvisory: 10,
  },
  trial: {
    vision: true,
    businessSectors: true,
    belief: true,
    businessStage: true,
    customerSegment: true,
    values: true,
    culture: true,
    marketGoals: true,
    sdgs: true,
    traction: true,
    skills: true,
    team: true,
    overallProblem: true,
    overallSolution: true,
    overallValueProposition: true,
    bmCustomerSegment: true,
    channels: true,
    keyResources: true,
    keyPartners: true,
    keyActivities: true,
    revenue: true,
    costs: true,
    pointOfDifferentiation: true,
    competition: true,
    comparison: true,
    marketStrategy: true,
    marketPotentialOverview: true,
    sam: true,
    tam: true,
    som: true,
    swot: true,
    riskAnalysis: true,
    scenarios: true,
    presentation: true,
    maxTeam: 5,
    maxAdvisory: 5,
  },
};
