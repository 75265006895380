import { apolloInstance } from '../vue-apollo';
import http from '@/helpers/api';

import {
  getSubscriptionPlansQuery,
  getPlanPriceQuery,
  getActiveSubscriptionQuery,
  getBillingHistoryQuery,
  getCreditChargesQuery,
} from '@/graphql/queries/subscription';
import {
  addCardMutation,
  // deleteCardMutation,
} from '@/graphql/mutations/subscription';

export const getSubscriptionPlans = () => {
  return apolloInstance.query({
    query: getSubscriptionPlansQuery,
    variables: {},
    fetchPolicy: 'no-cache',
  });
};

export const getActiveSubscription = (companyId) => {
  return apolloInstance.query({
    query: getActiveSubscriptionQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const getPlanPrice = (stripeId) => {
  return apolloInstance.query({
    query: getPlanPriceQuery,
    variables: { stripeId },
    fetchPolicy: 'no-cache',
  });
};

export const addCard = (data) => {
  return apolloInstance.mutate({
    mutation: addCardMutation,
    variables: { object: data },
  });
};

export const getBillingHistory = (companyId) => {
  return apolloInstance.query({
    query: getBillingHistoryQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const getCreditCharges = (chargeType) => {
  return apolloInstance.query({
    query: getCreditChargesQuery,
    variables: { chargeType },
    fetchPolicy: 'no-cache',
  });
};

export const createCustomer = (data) => {
  return http.post('/billing/customer', data);
};

export const createSubscription = (data) => {
  return http.post('/billing/create', data);
};

export const getClientSecret = () => {
  return http.post('/billing/card/token');
};

export const getCards = () => {
  return http.get('/billing/cards');
};

export const deleteCard = (data) => {
  return http.post('/billing/card/remove', data);
};
export const cancelSubscription = (data) => {
  return http.post('/billing/cancel', data);
};

export const defaultCard = (data) => {
  return http.post('/billing/card/default', data);
};

export const buyCredits = (companyId, data) => {
  return http.post(`/billing/${companyId}/buyCredits`, data);
};
