import { gql } from '@apollo/client/core';

export const foundationByTypeQuery = gql`
  query FoundationByTypeQuery($type: String, $companyId: Int!) {
    foundation(
      where: {
        companyId: { _eq: $companyId }
        foundation_type: { value: { _eq: $type } }
      }
    ) {
      assumption
      createdAt
      description
      id
      type
      progress
      priority
      updatedAt
      foundation_type {
        value
        description
      }
      foundation_items {
        id
        label
        description
      }
      foundation_assignments {
        id
        userId
        foundationId
        user {
          firstname
          id
          email
          createdAt
          isVerified
          lastname
          profilePhoto
          updatedAt
        }
      }
      foundation_visibilities {
        id
        userId
        foundationId
        user {
          id
          email
          lastname
          firstname
          createdAt
          updatedAt
          profilePhoto
        }
      }
    }
  }
`;

export const subscribeToFoundationTypeQuery = gql`
  subscription subscribeToFoundationByTypeQuery(
    $type: String
    $companyId: Int!
  ) {
    foundation(
      where: {
        companyId: { _eq: $companyId }
        foundation_type: { value: { _eq: $type } }
      }
    ) {
      assumption
      createdAt
      description
      id
      type
      progress
      priority
      updatedAt
      foundation_type {
        value
        description
      }
      foundation_items {
        id
        label
        description
      }
      foundation_assignments {
        id
        userId
        foundationId
        user {
          firstname
          id
          email
          createdAt
          isVerified
          lastname
          profilePhoto
          updatedAt
        }
      }
      foundation_visibilities {
        id
        userId
        foundationId
        user {
          id
          email
          lastname
          firstname
          createdAt
          updatedAt
          profilePhoto
        }
      }
    }
  }
`;

export const companySdgQuery = gql`
  query companySdgQuery($companyId: Int!) {
    company_sdg(where: { companyId: { _eq: $companyId } }) {
      assumption
      companyId
      company_sdg_values {
        SDGId
        companySDGId
        id
      }
      contribution
      createdAt
      evaluation
      id
      priority
      progress
      updatedAt
      company_sdg_assignments {
        id
        companySDGId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_sdg_visibilities {
        companySDGId
        id
        createdAt
        userId
        user {
          createdAt
          email
          firstname
          id
          isBlocked
          isDeleted
          isVerified
          lastname
          profilePhoto
          updatedAt
        }
      }
    }
  }
`;

export const subscribeToCompanySdgQuery = gql`
  subscription subscribeToCompanySdgQuery($companyId: Int!) {
    company_sdg(where: { companyId: { _eq: $companyId } }) {
      assumption
      companyId
      company_sdg_values {
        SDGId
        companySDGId
        id
      }
      contribution
      createdAt
      evaluation
      id
      priority
      progress
      updatedAt
    }
  }
`;

export const companySkillQuery = gql`
  query companySkillQuery($companyId: Int!) {
    company_skill(where: { companyId: { _eq: $companyId } }) {
      id
      progress
      priority
      createdAt
      updatedAt
      companyId
      assumption
      company_skill_values {
        id
        team_skills {
          id
          teamId
          companySKVId
        }
        skillBySkill {
          id
          name
          isUserGenerated
        }
      }
      company_skill_assignments {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      company_skill_visibilities {
        companySkillId
        id
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const getCompanySkillValuesQuery = gql`
  query getCompanySkillValuesQuery($companyId: Int!) {
    company_skill_values(
      where: { company_skill: { companyId: { _eq: $companyId } } }
    ) {
      id
      team_skills {
        id
        teamId
        companySKVId
      }
      skillBySkill {
        id
        name
      }
    }
  }
`;

export const getCompanyMembersQuery = gql`
  query getCompanyMembersQuery($companyId: Int!, $isAdvisory: Boolean) {
    team(
      where: {
        isAdvisory: { _eq: $isAdvisory }
        companyId: { _eq: $companyId }
      }
      order_by: { createdAt: asc }
    ) {
      user {
        id
        profilePhoto
        lastname
        isVerified
        isInviteFirstTime
        firstname
        email
        OnlineStatus
        companies {
          name
          id
        }
      }
      position
      id
      role
      department
      isDeclined
      isAccepted
      accessType
      team_skills {
        id
        teamId
        companySKVId
        company_skill_value {
          id
          skillBySkill {
            id
            name
          }
        }
      }
    }
  }
`;

export const subscribeToCompanyMembersQuery = gql`
  subscription subscribeToCompanyMembersQuery(
    $companyId: Int!
    $isAdvisory: Boolean
  ) {
    team(
      where: {
        isAdvisory: { _eq: $isAdvisory }
        companyId: { _eq: $companyId }
      }
      order_by: { createdAt: asc }
    ) {
      user {
        id
        profilePhoto
        lastname
        isVerified
        isInviteFirstTime
        firstname
        email
      }
      position
      id
      role
      department
      isDeclined
      isAccepted
      accessType
      team_skills {
        id
        teamId
        companySKVId
        company_skill_value {
          id
          skillBySkill {
            id
            name
          }
        }
      }
    }
  }
`;

export const getGoalsQuery = gql`
  query getGoalsQuery($status: String!, $companyId: Int!) {
    milestone(
      where: {
        type: { _eq: "GOAL" }
        status: { _eq: $status }
        companyId: { _eq: $companyId }
      }
    ) {
      categoryId
      calender_category {
        companyId
        createdAt
        id
        title
        updatedAt
      }
      companyId
      date
      createdAt
      dateAchieved
      title
      description
      id
      status
      metricType
      isDeleted
      milestoneValue
      showOnMarketPlan
      relatedGoalId
      color
      metricName
      related_goal {
        categoryId
        companyId
        createdAt
        date
        dateAchieved
        description
        id
        metricType
        milestoneValue
        status
        title
        type
      }
    }
  }
`;
