import { render, staticRenderFns } from "./FeelingModal.vue?vue&type=template&id=596572f5&scoped=true"
import script from "./FeelingModal.vue?vue&type=script&lang=js"
export * from "./FeelingModal.vue?vue&type=script&lang=js"
import style0 from "./FeelingModal.vue?vue&type=style&index=0&id=596572f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596572f5",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal})
