import { gql } from '@apollo/client/core';

export const getNoteSectionsQuery = gql`
  query getNoteSectionsQuery($userId: Int!) {
    segment {
      userId
      notes(order_by: { createdAt: asc }) {
        title
        attachments
        access {
          userId
          noteId
          id
          createdAt
          user {
            email
            firstname
            lastname
            id
            profilePhoto
          }
        }
        content
        createdAt
        checkpoints
        id
        segmentId
        tags
        userId
      }
      name
      id
      createdAt
    }
    note_access(where: { userId: { _eq: $userId } }) {
      createdAt
      id
      note {
        attachments
        access {
          createdAt
          id
          noteId
          updatedAt
          userId
          user {
            email
            firstname
            id
            lastname
            profilePhoto
          }
        }
        segmentId
        id
        createdAt
        content
        tags
        title
        userId
        updatedAt
        checkpoints
        user {
          email
          firstname
          lastname
          id
          profilePhoto
        }
      }
      noteId
      updatedAt
      userId
    }
  }
`;

export const subscribeToNoteSectionsQuery = gql`
  subscription subscribeToNoteSectionsQuery($userId: Int!) {
    segment {
      userId
      notes(order_by: { createdAt: asc }) {
        title
        attachments
        access {
          userId
          noteId
          id
          createdAt
          user {
            email
            firstname
            lastname
            id
            profilePhoto
          }
        }
        content
        createdAt
        checkpoints
        id
        segmentId
        tags
        userId
      }
      name
      id
      createdAt
    }
    note_access(where: { userId: { _eq: $userId } }) {
      createdAt
      id
      note {
        attachments
        access {
          createdAt
          id
          noteId
          updatedAt
          userId
          user {
            email
            firstname
            id
            lastname
            profilePhoto
          }
        }
        segmentId
        id
        createdAt
        content
        tags
        title
        userId
        updatedAt
        checkpoints
        user {
          email
          firstname
          lastname
          id
          profilePhoto
        }
      }
      noteId
      updatedAt
      userId
    }
  }
`;
