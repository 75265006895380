<template>
  <DatePicker
    @input="onInput"
    v-bind="{ ...$props, ...$attrs }"
    prefix-class="vc"
  >
    <template v-slot:input>
      <slot name="input" />
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
$namespace: 'vc';
@import 'vue2-datepicker/scss/index.scss';
.vc-datepicker {
  width: 100%;
}
</style>
