import {
  addCompanySDGAccessMutation,
  AddCompanySDGValuesMutation,
  addCompanySkillsAccessMutation,
  addCompanySkillValuesMutation,
  addFoundationAccessMutation,
  addFoundationItem,
  addSkillToMemberMutation,
  createCompanySdgMutation,
  createCompanySkillMutation,
  createFoundationByTypeMutation,
  deleteFoundationItemMutation,
  removeCompanySDGAccessMutation,
  removeCompanySDGValuesMutation,
  removeCompanySkillAccessMutation,
  removeCompanySkillValuesMutation,
  removeFoundationAccessMutation,
  removeSkillFromMemberMutation,
  updateCompanySdgMutation,
  updateCompanySkillMutation,
  updateFoundationItemMutation,
  updateFoundationTypeMutation,
  updateFoundationVisibilityMutation,
  updateSDGVisibilityMutation,
  updateSkillsVisibilityMutation,
  createSkillMutation,
  deleteCompanyMemberMutation,
} from '../graphql/mutations';
import {
  companySdgQuery,
  companySkillQuery,
  foundationByTypeQuery,
  getCompanyMembersQuery,
  getCompanySkillValuesQuery,
  getGoalsQuery,
  subscribeToCompanyMembersQuery,
  subscribeToFoundationTypeQuery,
  subscribeToCompanySdgQuery,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const getFoundationByType = ({ type, companyId }) => {
  return apolloInstance.query({
    query: foundationByTypeQuery,
    variables: { type, companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToFoundationType = ({ type, companyId }) => {
  return apolloInstance.subscribe({
    query: subscribeToFoundationTypeQuery,
    variables: { type, companyId },
  });
};

export const createFoundationByType = (data) => {
  return apolloInstance.mutate({
    mutation: createFoundationByTypeMutation,
    variables: { object: data },
  });
};

export const updateFoundationType = ({ type, companyId, set }) => {
  return apolloInstance.mutate({
    mutation: updateFoundationTypeMutation,
    variables: { type, companyId, set },
  });
};

export const createFoundationItem = (data) => {
  return apolloInstance.mutate({
    mutation: addFoundationItem,
    variables: { object: data },
  });
};

export const updateFoundationItem = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateFoundationItemMutation,
    variables: { id, set },
  });
};

export const deleteFoundationItem = (id) => {
  return apolloInstance.mutate({
    mutation: deleteFoundationItemMutation,
    variables: { id },
  });
};

export const getCompanySdg = (companyId) => {
  return apolloInstance.query({
    query: companySdgQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToCompanySdg = (companyId) => {
  return apolloInstance.subscribe({
    query: subscribeToCompanySdgQuery,
    variables: { companyId },
  });
};

export const createCompanySdg = (data) => {
  return apolloInstance.mutate({
    mutation: createCompanySdgMutation,
    variables: { object: data },
  });
};

export const updateCompanySdg = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCompanySdgMutation,
    variables: { id, set },
  });
};

export const addCompanySdgValues = (data) => {
  return apolloInstance.mutate({
    mutation: AddCompanySDGValuesMutation,
    variables: { objects: data },
  });
};

export const removeCompanySdgValues = (ids) => {
  return apolloInstance.mutate({
    mutation: removeCompanySDGValuesMutation,
    variables: { ids },
  });
};

export const getCompanySkill = (companyId) => {
  return apolloInstance.query({
    query: companySkillQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const createSkill = (data) => {
  return apolloInstance.mutate({
    mutation: createSkillMutation,
    variables: { object: data },
  });
};

export const createCompanySkill = (data) => {
  return apolloInstance.mutate({
    mutation: createCompanySkillMutation,
    variables: { object: data },
  });
};

export const updateCompanySkill = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCompanySkillMutation,
    variables: { id, set },
  });
};

export const getCompanySkillValues = (companyId) => {
  return apolloInstance.query({
    query: getCompanySkillValuesQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const addCompanySkillValues = (data) => {
  return apolloInstance.mutate({
    mutation: addCompanySkillValuesMutation,
    variables: { objects: data },
  });
};

export const removeCompanySkillValues = (ids) => {
  return apolloInstance.mutate({
    mutation: removeCompanySkillValuesMutation,
    variables: { ids },
  });
};

export const getCompanyMembers = ({ companyId, isAdvisory }) => {
  return apolloInstance.query({
    query: getCompanyMembersQuery,
    variables: { companyId, isAdvisory },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToTeamMembers = ({ companyId, isAdvisory }) => {
  return apolloInstance.subscribe({
    query: subscribeToCompanyMembersQuery,
    variables: { companyId, isAdvisory },
  });
};

export const addSkillToMember = (data) => {
  return apolloInstance.mutate({
    mutation: addSkillToMemberMutation,
    variables: { object: data },
  });
};

export const removeSkillFromMember = (id) => {
  return apolloInstance.mutate({
    mutation: removeSkillFromMemberMutation,
    variables: { id },
  });
};

export const removeCompanyMember = (id) => {
  return apolloInstance.mutate({
    mutation: deleteCompanyMemberMutation,
    variables: { id },
  });
};

export const addFoundationAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addFoundationAccessMutation,
    variables: { object: data },
  });
};

export const removeFoundationAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeFoundationAccessMutation,
    variables: { id },
  });
};

export const addCompanySDGAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addCompanySDGAccessMutation,
    variables: { object: data },
  });
};

export const removeCompanySDGAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeCompanySDGAccessMutation,
    variables: { id },
  });
};

export const addCompanySkillsAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addCompanySkillsAccessMutation,
    variables: { object: data },
  });
};

export const removeCompanySkillsAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeCompanySkillAccessMutation,
    variables: { id },
  });
};

export const updateFoundationVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateFoundationVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const updateSDGVisibility = ({ addVisibility, removedVisibilities }) => {
  return apolloInstance.mutate({
    mutation: updateSDGVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const updateSkillsVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateSkillsVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const getGoals = ({ companyId, status }) => {
  return apolloInstance.query({
    query: getGoalsQuery,
    variables: { status, companyId },
    fetchPolicy: 'no-cache',
  });
};
