import { gql } from '@apollo/client/core';

export const addNoteSectionMutation = gql`
  mutation addNoteSectionMutation($object: segment_insert_input!) {
    insert_segment_one(object: $object) {
      name
      notes {
        title
        attachments
        access {
          userId
          noteId
          id
          createdAt
          user {
            email
            firstname
            lastname
            id
            profilePhoto
          }
        }
        content
        createdAt
        checkpoints
        id
        segmentId
        tags
        userId
      }
      id
      createdAt
      userId
    }
  }
`;

export const deleteNoteSectionMutation = gql`
  mutation deleteNoteSectionMutation($id: Int!) {
    delete_segment_by_pk(id: $id) {
      id
    }
  }
`;

export const addNoteMutation = gql`
  mutation addNoteMutation($object: note_insert_input!) {
    insert_note_one(object: $object) {
      title
      attachments
      access {
        userId
        noteId
        id
        createdAt
        user {
          email
          firstname
          lastname
          id
          profilePhoto
        }
      }
      content
      createdAt
      checkpoints
      id
      segmentId
      tags
    }
  }
`;

export const updateNoteMutation = gql`
  mutation updateNoteMutation(
    $noteId: Int!
    $set: note_set_input
    $accessData: [note_access_insert_input!]!
    $accessDeleteData: [Int!]
  ) {
    update_note_by_pk(pk_columns: { id: $noteId }, _set: $set) {
      title
      attachments
      access {
        userId
        noteId
        id
        createdAt
        user {
          email
          firstname
          lastname
          id
          profilePhoto
        }
      }
      content
      createdAt
      checkpoints
      id
      segmentId
      tags
    }
    insert_note_access(objects: $accessData) {
      returning {
        noteId
        userId
        id
        createdAt
        user {
          email
          firstname
          lastname
          id
          profilePhoto
        }
      }
      affected_rows
    }
    delete_note_access(where: { id: { _in: $accessDeleteData } }) {
      affected_rows
      returning {
        createdAt
        id
        noteId
        userId
      }
    }
  }
`;

export const deleteNoteMutation = gql`
  mutation deleteNoteMutation($id: Int!) {
    delete_note_by_pk(id: $id) {
      id
      title
    }
  }
`;
