import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=07a47ef7"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=07a47ef7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAvatar: require('@chakra-ui/vue').CAvatar, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CLink: require('@chakra-ui/vue').CLink, CModalHeader: require('@chakra-ui/vue').CModalHeader, CInput: require('@chakra-ui/vue').CInput, CSpinner: require('@chakra-ui/vue').CSpinner, CStack: require('@chakra-ui/vue').CStack, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
