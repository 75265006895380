import { render, staticRenderFns } from "./NoteList.vue?vue&type=template&id=45e4245e&scoped=true"
import script from "./NoteList.vue?vue&type=script&lang=js"
export * from "./NoteList.vue?vue&type=script&lang=js"
import style0 from "./NoteList.vue?vue&type=style&index=0&id=45e4245e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e4245e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CButton: require('@chakra-ui/vue').CButton, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverHeader: require('@chakra-ui/vue').CPopoverHeader, CPopoverArrow: require('@chakra-ui/vue').CPopoverArrow, CPopoverCloseButton: require('@chakra-ui/vue').CPopoverCloseButton, CInput: require('@chakra-ui/vue').CInput, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CButtonGroup: require('@chakra-ui/vue').CButtonGroup, CPopoverFooter: require('@chakra-ui/vue').CPopoverFooter, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CLink: require('@chakra-ui/vue').CLink, CText: require('@chakra-ui/vue').CText, CSpinner: require('@chakra-ui/vue').CSpinner, CListItem: require('@chakra-ui/vue').CListItem, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CList: require('@chakra-ui/vue').CList, CBox: require('@chakra-ui/vue').CBox})
