<template>
  <div class="assign" :class="[`assign--${size}`]">
    <c-avatar-group mr="-0.75em" max="1" v-if="members.length">
      <c-avatar
        :width="{ base: '35px', lg: '40px' }"
        :height="{ base: '35px', lg: '40px' }"
        size="sm"
        :name="member.firstname + ' ' + member.lastname"
        :src="member.profilePhoto"
        border="none"
        v-for="member in members"
        :key="member.id"
      />
    </c-avatar-group>
    <c-popover :placement="'bottom'" v-slot="{ onClose }">
      <c-popover-trigger>
        <c-box class="assign__toggle">
          <svg>
            <use href="@/assets/icons/icon-assign.svg#assign"></use>
          </svg>
        </c-box>
      </c-popover-trigger>
      <c-popover-content
        z-index="4"
        maxWidth="150px"
        maxHeight="200px"
        overflowY="scroll"
      >
        <c-popover-header fontSize="xs" fontWeight="500">
          Team Members
        </c-popover-header>
        <c-popover-body padding="0">
          <c-list>
            <template v-for="member in teamMembers">
              <c-list-item
                v-if="member.user.id === user.id ? showSelf : true"
                :key="member.id"
              >
                <c-pseudo-box
                  fontSize="xs"
                  paddingTop="5px"
                  paddingBottom="5px"
                  px="3"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  as="a"
                  cursor="pointer"
                  @click="selectMember(member, onClose)"
                >
                  <c-text>
                    <template v-if="member.user.id === user.id">
                      Assign yourself
                    </template>
                    <template v-else>
                      {{ member.user.firstname }} {{ member.user.lastname }}
                    </template>
                  </c-text>
                  <c-icon
                    v-if="isAssigned(member)"
                    name="check"
                    color="vue.400"
                  ></c-icon>
                </c-pseudo-box>
              </c-list-item>
            </template>
          </c-list>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    size: {
      type: String,
    },
    members: {
      type: Array,
      default: () => [],
    },
    showSelf: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState({
      teamMembers: (state) =>
        state.company.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
  },
  methods: {
    selectMember(member, closePopover) {
      closePopover();
      this.$emit('memberSelect', member);
    },
    isAssigned(member) {
      return this.members.find((_member) => _member.id === member.user.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.assign {
  @apply flex;
  &__toggle {
    @apply cursor-pointer;
    svg {
      width: 35px;
      height: 35px;
    }
  }
  ::v-deep {
    div[data-chakra-component='CMoreAvatarLabel'] {
      width: 35px;
      height: 35px;
    }
    section[data-chakra-component='CPopper'] {
      &:focus {
        box-shadow: 0px 3px 6px #bababa81;
      }
    }
  }
  @screen lg {
    &__toggle {
      @apply cursor-pointer;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    ::v-deep {
      div[data-chakra-component='CMoreAvatarLabel'] {
        width: 40px;
        height: 40px;
      }
    }
  }
  &--small {
    .assign {
      &__toggle {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    ::v-deep {
      div[data-chakra-component='CMoreAvatarLabel'] {
        @apply text-sm;
        width: 30px;
        height: 30px;
      }
      div[data-chakra-component='CAvatar'] {
        width: 30px;
        height: 30px;
      }
      div[data-chakra-component='CAvatarName'] {
        @apply text-xs;
      }
    }
  }
}
</style>
